import BaseAPI from 'api/api'

export enum REPORT_GROUP {
  INCOME = 'INCOME',
  PATIENT = 'PATIENT',
  TREATMENT = 'TREATMENT',
  PRODUCT = 'PRODUCT',
  DOCTOR_FEE = 'DOCTOR_FEE',
  APPOINTMENT = 'APPOINTMENT',
  // INVENTORY = 'INVENTORY',
  // COMMISSION = 'COMMISSION',
  ETC = 'ETC'
}

export interface FindAllReportInterface {
  page?: number
  pageLimit?: number
  sortBy?: string
  sortType?: string
  search?: string
  reportGroup?: REPORT_GROUP | string
  branchId?: number
  prefix?: string
  date?: string
  export?: any
  dateStart?: string
  dateEnd?: string
  lab?: number
  df?: number
  doctorId?: any
  billStatus?: any
  patientId?: number
  operativeCode?: number
  operativeType?: any
  productTypeId?: number
  productId?: number
  treatmentRightId?: number
  userId?: any
  group?: string
  groupCode?: string
  labCompanyId?: number
  labGroupId?: number
  optionDf?: number
  accessChannelsId?: number
  status?: string
  operativeId?: any
  operativeTypeId?: any
  courseType?: any
  courseName?: any
  optionPrice?: string
  month?: number
  year?: number
  patientTypeId?: number
  duplicatePatient?: boolean
  paymentChanel?: string
}

const path = 'reports'

export default class ReportApi extends BaseAPI {
  static reportList(params: FindAllReportInterface): Promise<any> {
    return this.api.get(path, { params: { ...params } })
  }
  static reportActive(): Promise<any> {
    return this.api.get(`${path}/active`)
  }

  /** INCOME */
  static reportIncomeSimpleDaily(params: FindAllReportInterface): Promise<any> {
    return this.api.get(`${path}/income-simple-daily`, { params: { ...params } })
  }
  static reportIncomeSimpleDailyOperative(params: FindAllReportInterface): Promise<any> {
    return this.api.get(`${path}/income-simple-daily-operative`, { params: { ...params } })
  }
  static reportIncomeSimpleDailySummery(params: FindAllReportInterface): Promise<any> {
    return this.api.get(`${path}/income-simple-daily-summery`, { params: { ...params } })
  }

  static reportCommission(params: FindAllReportInterface): Promise<any> {
    return this.api.get(`${path}/commission`, { params: { ...params } })
  }

  static reportFollowUp(params: FindAllReportInterface): Promise<any> {
    return this.api.get(`${path}/follow-up`, { params: { ...params } })
  }

  static reportTreatmentRightBalance(params: FindAllReportInterface): Promise<any> {
    return this.api.get(`${path}/treatment-right-balance`, { params: { ...params } })
  }

  static reportStock(params: FindAllReportInterface): Promise<any> {
    return this.api.get(`${path}/stock`, { params: { ...params } })
  }

  static reportIncomeTypeDaily(params: FindAllReportInterface): Promise<any> {
    return this.api.get(`${path}/income-type-daily`, { params: { ...params } })
  }

  static reportIncomeOverdueDaily(params: FindAllReportInterface): Promise<any> {
    return this.api.get(`${path}/income-overdue-daily`, { params: { ...params } })
  }

  static reportIncomeMonthly(params: FindAllReportInterface): Promise<any> {
    return this.api.get(`${path}/income-simple-monthly`, { params: { ...params } })
  }

  /** ITEM SALE */
  static reportProductSale(params: FindAllReportInterface): Promise<any> {
    return this.api.get(`${path}/product-sale`, { params: { ...params } })
  }

  static reportMedicineSale(params: FindAllReportInterface): Promise<any> {
    return this.api.get(`${path}/medicine-sale`, { params: { ...params } })
  }

  static reportMaterialSale(params: FindAllReportInterface): Promise<any> {
    return this.api.get(`${path}/material-sale`, { params: { ...params } })
  }

  /** OPERATIVE */
  static reportOperative(params: FindAllReportInterface): Promise<any> {
    return this.api.get(`${path}/operative`, { params: { ...params } })
  }

  static reportTreatmentRightMonth(params: FindAllReportInterface): Promise<any> {
    return this.api.get(`${path}/treatment-right-month`, { params: { ...params } })
  }

  static reportRemittance(params: FindAllReportInterface): Promise<any> {
    return this.api.get(`${path}/remittance`, { params: { ...params } })
  }

  static reportRemittances(params: FindAllReportInterface): Promise<any> {
    return this.api.get(`${path}/remittances`, { params: { ...params } })
  }

  static reportSchedules(params: FindAllReportInterface): Promise<any> {
    return this.api.get(`${path}/schedules`, { params: { ...params } })
  }

  static reportTransactionRevenue(params: FindAllReportInterface): Promise<any> {
    return this.api.get(`${path}/transaction-revenue`, { params: { ...params } })
  }

  static reportPatientInformation(params: FindAllReportInterface): Promise<any> {
    return this.api.get(`${path}/patient-information`, { params: { ...params } })
  }

  static reportLab(params: FindAllReportInterface): Promise<any> {
    return this.api.get(`${path}/lab`, { params: { ...params } })
  }
  static reportDfLab(params: FindAllReportInterface): Promise<any> {
    return this.api.get(`${path}/df-lab`, { params: { ...params } })
  }
  static reportDf(params: FindAllReportInterface): Promise<any> {
    return this.api.get(`${path}/df`, { params: { ...params } })
  }

  static reportDfLabOperative(params: FindAllReportInterface): Promise<any> {
    return this.api.get(`${path}/df-lab-operative`, { params: { ...params } })
  }

  static reportRemuneration(params: FindAllReportInterface): Promise<any> {
    return this.api.get(`${path}/remuneration`, { params: { ...params } })
  }

  static reportRemittanceClinicFee(params: FindAllReportInterface): Promise<any> {
    return this.api.get(`${path}/remuneration-clinic-fee`, { params: { ...params } })
  }

  static reportRemunerationAllDentist(params: FindAllReportInterface): Promise<any> {
    return this.api.get(`${path}/remuneration-all-dentist`, { params: { ...params } })
  }

  static reportRemittanceClinicFeeAllDentist(params: FindAllReportInterface): Promise<any> {
    return this.api.get(`${path}/remuneration-clinic-fee-all-dentist`, { params: { ...params } })
  }

  static reportNewPatient(params: FindAllReportInterface): Promise<any> {
    return this.api.get(`${path}/new-patient`, { params: { ...params } })
  }

  static reportSchedulesDoctor(params: FindAllReportInterface): Promise<any> {
    return this.api.get(`${path}/schedules-doctor`, { params: { ...params } })
  }

  static reportPatientDateOfBirth(params: FindAllReportInterface): Promise<any> {
    return this.api.get(`${path}/patient-date-of-birth`, { params: { ...params } })
  }

  static reportAccessChannel(params: FindAllReportInterface): Promise<any> {
    return this.api.get(`${path}/access-channel`, { params: { ...params } })
  }

  static reportVisit(params: FindAllReportInterface): Promise<any> {
    return this.api.get(`${path}/visit`, { params: { ...params } })
  }

  static reportTreatmentCourse(params: FindAllReportInterface): Promise<any> {
    return this.api.get(`${path}/treatment-course`, { params: { ...params } })
  }

  static reportPatientReceiveService(params: FindAllReportInterface): Promise<any> {
    return this.api.get(`${path}/patients-receive-service`, { params: { ...params } })
  }

  static reportPatientTreatment(params: FindAllReportInterface): Promise<any> {
    return this.api.get(`${path}/patients-treatment`, { params: { ...params } })
  }

  static reportAppointment(params: FindAllReportInterface): Promise<any> {
    return this.api.get(`${path}/appointments`, { params: { ...params } })
  }

  static reportDoctorFee(params: FindAllReportInterface): Promise<any> {
    return this.api.get(`${path}/doctor-fee`, { params: { ...params } })
  }

  static reportBalance(params: FindAllReportInterface): Promise<any> {
    return this.api.get(`${path}/balance`, { params: { ...params } })
  }
  static reportAdvance(params: FindAllReportInterface): Promise<any> {
    return this.api.get(`${path}/advance`, { params: { ...params } })
  }
  static reportAdvanceBalance(params: FindAllReportInterface): Promise<any> {
    return this.api.get(`${path}/advance-balance`, { params: { ...params } })
  }
  static reportAdvanceDecrease(params: FindAllReportInterface): Promise<any> {
    return this.api.get(`${path}/advance-decrease`, { params: { ...params } })
  }
  static reportAdvanceIncrease(params: FindAllReportInterface): Promise<any> {
    return this.api.get(`${path}/advance-increase`, { params: { ...params } })
  }

  static reportAccounting(params: FindAllReportInterface): Promise<any> {
    return this.api.get(`${path}/accounting`, { params: { ...params } })
  }

  static reportAccountingCode(params: FindAllReportInterface): Promise<any> {
    return this.api.get(`${path}/accounting-code`, { params: { ...params } })
  }
  static reportRevenueTransaction(params: FindAllReportInterface): Promise<any> {
    return this.api.get(`${path}/revenue-transaction`, { params: { ...params } })
  }
  static reportIncomeExpense(params: FindAllReportInterface): Promise<any> {
    return this.api.get(`${path}/income-expense`, { params: { ...params } })
  }

  static reportPatientTypeOption(params: FindAllReportInterface): Promise<any> {
    return this.api.get(`${path}/patient-types`, { params: { ...params } })
  }

  static reportPartialProcedureSummary(params: FindAllReportInterface): Promise<any> {
    return this.api.get(`${path}/partial-procedure-summary`, { params: { ...params } })
  }
}

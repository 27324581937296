import { useState, useEffect } from 'react'
import moment from 'moment'
import _ from 'lodash'
import { Box, IconButton, Typography, Grid, TableRow, TableCell, TextareaAutosize, Tooltip } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import $ from 'jquery'
import { clinicTheme } from 'api/api'

/** TRANSLATION*/
import { useTranslation } from 'react-i18next'

/** API*/
import OperativeTypeApi from 'api/setting/treatments/operative-type.api'
import TreatmentProductApi from 'api/dentists/treatmentProduct.api'

/** UTILS || SLICE */
import {
  treatment,
  isEditTootChart,
  setTreatmentByKey,
  TreatmentStateInterface,
  setSummeryTreatments,
  setToothChartSelected,
  setEditTootChart,
  historyToothChart,
  newStickerProduct,
  ProductStickerType,
  StickerType,
  setEditTootChartType,
  checkApprovalRight,
  promotionsTreatments,
  loadPromotionTreatments,
} from 'app/slice/treatment.slice'
import useWindowSize from 'utils/useWindowSize'
import * as modalSlice from 'app/slice/modal.slice'

/** COMPONENT */
import TableCustom from 'component/Table/TableCustom'
import InputTextField from 'component/Input/InputTextField'
import ButtonCustom from 'component/Button/ButtonCustom'
import PopupManageTreatmentCourse, { CURRENCY_UNIT } from 'component/Treatments/PopupManageTreatmentCourse'
import PopupMessage from 'component/Treatments/PopupMessage'
import PopupNextVisit from 'component/Treatments/PopupNextVisit'
import { swalWarning } from 'component/Alert/Swal'
import AlertProductOutOfStock, { AlertProductOutOfStockType } from 'component/Treatments/AlertProductOutOfStock'
import AutocompleteSelect from 'component/Select/AutocompleteSelect'
import PopupManageProduct from 'component/Treatments/PopupManageProduct'
import { DoctorsType } from 'features/treatments/treatmentsIndex'

/** CONSTANTS */
import { colors } from 'constants/theme'
import { getBranch, getClinicInfo, numberFormat, toBuddhistYear } from 'utils/app.utils'
import { icons, Icons } from 'constants/images'

/** STYLE */
import * as UseStyled from 'features/treatments/SummaryTreatment/UseStyle'
import '../style.css'
import PopupCommission from 'component/Treatments/PopupCommission'
import TreatmentSummaryApi from 'api/dentists/treatment-summary.api'
import { useLocation } from 'react-router-dom'
import OperativeApi from 'api/setting/treatments/operative.api'
import TreatmentApi, { FindDoctorFeeProps } from 'api/dentists/treatment.api'
import { selectMe } from 'app/slice/user.slice'
import { approvalStatusList } from 'features/approvel-list/approvel-list'
import { ApprovalStatus } from 'api/approval/approval.api'
import IconPromotion from 'assets/icon/icon-promotion'
import IconGiftVoucher from 'assets/icon/icon-gift-voucher'

type ResponsiveRenderDataProps = {
  onViewTreatmentsCourse?: () => void
  onViewManageProduct?: () => void
  onViewMessage?: (no: number) => void
  onViewNextVisit?: () => void
  onChange?: (e: any) => void
  no: number
  doctors?: DoctorsType[]
  doctorsSelect?: DoctorsType[]
  data?: any
  price?: number
  discount?: number
  additional?: number
  total?: number
  hasTreatment: 'PERMISS' | 'UNPERMISS'
  hasChangeTreatmentCost: 'PERMISS' | 'UNPERMISS'
  hasChangeTreatmentNote: 'PERMISS' | 'UNPERMISS'
  hasDeleteTreatment: 'PERMISS' | 'UNPERMISS'
  renderTreatmentNextVisit: string
  hasCommission: '1' | '0'
  handleCommission?: (data: any, hasCommission: '1' | '0', index: number) => void
  commission: number
  type: 'Treatment' | 'Payment' | 'HistoryTreatment' | 'RetrospectiveOrder' | 'RetrospectiveEdit'
  disabledItemName: boolean
  hasEditNextVisit?: boolean
  renderIconApproval?: React.ReactNode
}

type ISummaryTreatment = {
  onSave?: (val: 'save') => void
  userId?: number
  patientCaseId?: number
  patientId?: number
  patientName?: string
  patientPhone?: string
  type: 'Treatment' | 'Payment' | 'HistoryTreatment' | 'RetrospectiveOrder' | 'RetrospectiveEdit'
  doctors: DoctorsType[]
  hasTreatment: 'PERMISS' | 'UNPERMISS'
  hasChangeTreatmentCost: 'PERMISS' | 'UNPERMISS'
  hasChangeTreatmentNote: 'PERMISS' | 'UNPERMISS'
  hasChangeProductPrice: 'PERMISS' | 'UNPERMISS'
  hasChangeProductDiscountAdditional: 'PERMISS' | 'UNPERMISS'
  hasDeleteTreatment: 'PERMISS' | 'UNPERMISS'
  hasEditNextVisit?: boolean
}

type ConfigModal = {
  type?: 'TreatmentsCourse' | 'ViewMessage' | 'NextVisit' | 'ManageProduct' | 'RetrospectiveEdit' | 'Commission'
  itemId?: number
  operative?: any
  doctorId?: number
  product?: any
  hasCommission?: '1' | '0'
}

export interface RawDiscountType {
  price: number
  qty: number
  discount: number
  discountUnit: string
  dfUnit: string
  dfAmount: number
  dfDiscountAmount: number
  dfDiscountUnit: string
  cfAmount: number
  cfDiscountAmount: number
  cfDiscountUnit: string
  additionalNet: number
  doctorFeeAmount: number
  clinicFeeAmount: number
}

export interface RawAdditionalType {
  price: number
  qty: number
  additional: number
  additionalUnit: string
}

const initProductOutOfStock: AlertProductOutOfStockType = {
  type: 'medicine',
  name: '',
  code: '',
  qty: 0,
  unit: ''
}

interface UpdateDFIF {
  dfAmount: any;
  cfAmount: any;
  dfUnit: any;
  dfAmountNet: number;
  cfAmountNet: number;
  total: number;
}
export default function SummaryTreatment(props: ISummaryTreatment) {
  const dispatch = useDispatch()
  const { t, i18n } = useTranslation()
  const treatments: TreatmentStateInterface = useSelector(treatment)
  const promotions = useSelector(promotionsTreatments)
  const branchInfo = getClinicInfo()
  const isEditTootCharts: any = useSelector(isEditTootChart)
  const historyToothCharts = useSelector(historyToothChart)
  const newStickerProducts: ProductStickerType[] = useSelector(newStickerProduct)
  const modal: boolean = useSelector(modalSlice.handleShow)
  const location = useLocation()
  const [load, setLoad] = useState(true)
  const user = useSelector(selectMe)

  const [width] = useWindowSize()
  const [configModal, setConfigModal] = useState<ConfigModal>({})
  const [popupProductOutOfStock, setPopupProductOutOfStock] = useState<AlertProductOutOfStockType>(initProductOutOfStock)

  const [dataCommission, setDataCommission] = useState<any>({})

  const approvalRight = useSelector(checkApprovalRight)

  const handleDelRefItemId = (data: any, id: string) => {
    if (typeof data === 'string') {
      const step1 = data.replace(/\\/g, '');
      const step2 = step1.replace(/^"|"$/g, '');
      const dataParse = JSON.parse(step2);
      const newData = dataParse?.filter((item: any) => item !== id)
      return newData
    }
    const newData = data?.filter((item: any) => item !== id)
    return newData
  }

  const handleDelRefItem = (data: any, itemsDel: any) => {
    if (itemsDel[0]?.refItemId && itemsDel[0]?.refItemId !== "") {
      const dataDel = data.map((item: any) => {
        if (item?.refItemId?.includes(itemsDel[0]?.refItemId)) {
          return { ...item, refItemId: [...handleDelRefItemId(item?.refItemId, itemsDel[0]?.refItemId)] }
        } else {
          return item
        }
      })
      const newData = dataDel.filter((item: any) => {
        if (item?.refItemId?.length !== 0) { return item }
      })
      return newData
    } else {
      return data
    }

  }

  const handleDiscard = (id: number) => {
    const itemsDel: any = _.filter(treatments.summeryTreatments, (treatmentSummary, index: number) => index === id)

    const itemExtraDel = itemsDel[0]?.promotion?.length ? itemsDel[0]?.promotion.map((item: any) => {
      return item?.promotionBranchId
    }) : []
    const items = _.filter(treatments.summeryTreatments, (treatmentSummary, index: number) => {
      if (index !== id) {
        return treatmentSummary
      }
    })
    let newItems: any = []
    if (itemExtraDel?.length) {
      items.forEach((item: any) => {
        if (item?.promotion?.length) {
          const checkPromotion = item?.promotion.some((info: any) => itemExtraDel.includes(info?.promotionBranchId) && info?.promotionType && info?.promotionType !== 'EXTRA')
          const check = item?.promotion.every((info: any) => !itemExtraDel.includes(info?.promotionBranchId))
          if (checkPromotion) {
            const newData = { ...item, promotion: item?.promotion?.filter((i: any) => !itemExtraDel.includes(i?.promotionBranchId)) || [] }
            newItems.push(newData)
          } else if (check) {
            newItems.push(item)
          }
        } else {
          newItems.push(item)
        }
      })
    } else {
      newItems = items
    }

    const toothChartSelected: any = []

    if (!_.isEmpty(newItems)) {
      _.map(newItems, (item, index) => {
        if (item.teethJson) toothChartSelected.push(JSON.parse(item.teethJson))
      })
    }

    const itemRecom = handleDelRefItem(treatments.patientRecommends, itemsDel) || []
    const itemDis = handleDelRefItem(treatments.patientDiagnosis, itemsDel) || []

    dispatch(setTreatmentByKey({ key: 'patientDiagnosis', value: itemDis }))
    dispatch(setTreatmentByKey({ key: 'patientRecommends', value: itemRecom }))
    dispatch(setTreatmentByKey({ key: 'summeryTreatments', value: newItems }))
    dispatch(setToothChartSelected([...historyToothCharts, ...toothChartSelected]))
    dispatch(setEditTootChart(null))
    dispatch(setEditTootChartType(''))
  }

  const onViewTreatmentsCourse = (no: number, operative: any, doctorId: number) => {
    if (_.find(props.doctors, { userId: doctorId })?.userId) {
      setConfigModal({ type: 'TreatmentsCourse', itemId: no, operative: operative, doctorId: doctorId })
      dispatch(modalSlice.showModal())
    } else {
      return swalWarning(t('TREATMENT_SUMMARY.MESSAGE.PLS_SELECT_DOCTOR'), '', null)
    }
  }

  const loadOperative = async () => {
    if (load && treatments.summeryTreatments.length > 0) {
      const ids = treatments.summeryTreatments.map((item: any) => item.operativeId)
      const resp: any = await OperativeApi.findAll({ page: 1, limit: 100, ids, sortBy: 'operativeId', sortType: 'ASC' })
      if (resp.status === 200) {
        setLoad(false)
        dispatch(setTreatmentByKey({
          key: 'summeryTreatments', value: treatments.summeryTreatments.map((item: any) => {
            const find = resp.data?.find((cf: any) => cf.operativeId === item.operativeId)
            return {
              ...item,
            }
          })
        }))
      }
    }
  }

  useEffect(() => {
    loadOperative()
  }, [treatments.summeryTreatments])

  const onViewManageProduct = async (itemId: number, item: any, sticker?: any) => {
    const stickerObj: any = JSON.parse(sticker)
    const checkSticker = () => {
      if (!stickerObj.itemId) { return itemProduct?.sticker }
      else { return sticker }
    }

    let itemProduct = _.find(newStickerProducts, { itemId: item.itemId })
    if ((item.tempOrderProductId || item.orderProductId) && props.patientCaseId) {
      await TreatmentProductApi.findStickerById(props.patientCaseId, item.itemId)
        .then(({ data }) => {
          itemProduct = data?.find((info: any) => info?.tempOrderProductId === item?.tempOrderProductId)
        })
        .catch(() => {
          return
        })
    }
    setConfigModal({ type: 'ManageProduct', itemId: itemId, product: { ...item, sticker: checkSticker() } })
    dispatch(modalSlice.showModal())
  }

  const onViewMessage = (no: number) => {
    setConfigModal({ type: 'ViewMessage', itemId: no })
    dispatch(modalSlice.showModal())
  }

  const onViewNextVisit = (no: number) => {
    setConfigModal({ type: 'NextVisit', itemId: no })
    dispatch(modalSlice.showModal())
  }


  const handleQtyStock = (value: string) => {
    if (value) {
      const valSticker: StickerType = JSON.parse(value)
      return valSticker.stock || 0
    } else return 0
  }

  const handleChange = async (e: any, index: number) => {
    const list = treatments.summeryTreatments[index]

    if (e.target.name === 'qty') {
      const list = treatments.summeryTreatments[index]
      const isOperative = list.operativeTypeId ? true : false
      const stock = Number(list.stock) || Number(list.qtyStock) || handleQtyStock(list.sticker)
      const quantity = !e.target.value ? 0 : String(e.target.value).replace(/[^0-9.]/g, '')
      let newQty = Number(quantity)

      if (stock && Number(quantity) > Number(stock)) {
        newQty = stock
        setPopupProductOutOfStock({
          type: list.group === 'MEDICINE' ? 'medicine' : 'product',
          code: list.itemCode,
          name: list.itemName || list.itemNameEn,
          qty: list.stock || list.qtyStock || handleQtyStock(list.sticker),
          unit: list.itemUnitName || list.itemUnitNameEn
        })
        dispatch(modalSlice.showModal())
      }

      const rawAdditional: RawAdditionalType = {
        price: list.price,
        qty: newQty,
        additional: Number(list.additional),
        additionalUnit: list.additionalUnit
      }

      const rawDiscount: RawDiscountType = {
        price: list.price,
        qty: newQty,
        discount: Number(list.discount),
        discountUnit: list.discountUnit,
        dfUnit: list.dfUnit,
        dfAmount: Number(list.dfAmount),
        dfDiscountAmount: Number(list.dfDiscountAmount),
        dfDiscountUnit: list.dfDiscountUnit,
        cfAmount: Number(list.cfAmount),
        cfDiscountAmount: Number(list.cfDiscountAmount),
        cfDiscountUnit: list.cfDiscountUnit,
        additionalNet: renderSumAdditional(rawAdditional),
        doctorFeeAmount: list.doctorFeeAmount,
        clinicFeeAmount: list.clinicFeeAmount
      }

      const discount = (!isOperative && rawDiscount.discount) || renderSumDiscount(rawDiscount) || 0
      const additional = (!isOperative && rawAdditional.additional) || renderSumAdditional(rawAdditional) || 0
      const total = !isOperative ? list.price * (newQty >= stock ? stock : newQty) - discount + additional : list.price * newQty - discount + additional

      const calculateDf = (qty: number) => {
        if (list.price === 0 && list.discountUnit === 'BAHT') {
          return qty * list.dfAmount
        }
        const discount = list.discountUnit === 'BAHT' ? list.discount : (list.price * newQty) / 100 * list.discount
        const add = list.additionalUnit === "BAHT" ? list.additional : (list.price * newQty) / 100 * list.additional
        const truePrice = ((list.price * qty) - Number(discount) + Number(add))
        const df = list.dfUnit === 'BAHT' ? list.dfAmount * qty : truePrice * (list.dfAmount / 100)
        const discountDf = list.dfDiscountUnit === 'BAHT' ? list.dfDiscountAmount : (df / 100 * list.dfDiscountAmount)
        return df - discountDf
      }

      const calculateCf = (qty: number) => {
        if (list.price === 0 && list.discountUnit === 'BAHT') {
          return 0
        }
        const discount = list.discountUnit === 'BAHT' ? list.discount : (list.price * newQty) / 100 * list.discount
        const add = list.additionalUnit === "BAHT" ? list.additional : (list.price * newQty) / 100 * list.additional
        const truePrice = ((list.price * qty) - Number(discount) + Number(add))
        const cf = list.cfUnit === 'BAHT' ? list.cfAmount * qty : truePrice * (list.cfAmount / 100)
        const discountCf = list.cfDiscountUnit === 'BAHT' ? list.cfDiscountAmount : (cf / 100 * list.cfDiscountAmount)
        return cf - discountCf
      }

      if (props?.patientId && (props?.type === 'Payment' || props?.type === 'Treatment')) {
        const summaryTreatments = _.merge([], treatments.summeryTreatments)
        summaryTreatments[index] = { ...summaryTreatments[index], qty: newQty, total: total }
        dispatch(loadPromotionTreatments(props.patientId, summaryTreatments))
      }

      if (!list?.promotion?.length && !(props?.type === 'Payment' || props?.type === 'Treatment')) {
        dispatch(setSummeryTreatments({ index: index, key: 'qty', value: newQty }))
        dispatch(setSummeryTreatments({ index: index, key: 'total', value: total }))
        if (list.dfAmount !== null) {
          dispatch(setSummeryTreatments({ index: index, key: 'dfAmountNet', value: calculateDf(newQty) }))
          dispatch(setSummeryTreatments({ index: index, key: 'cfAmountNet', value: calculateCf(newQty) }))
          // if (!list.qty || !list.cfAmountNet) {
          //   dispatch(setSummeryTreatments({ index: index, key: 'dfAmountNet', value: calculateDf(newQty) }))
          //   dispatch(setSummeryTreatments({ index: index, key: 'cfAmountNet', value: calculateCf(newQty) }))
          // } else {
          //   dispatch(setSummeryTreatments({ index: index, key: 'dfAmountNet', value: newQty * (Number(list.dfAmountNet) / Number(list.qty)) }))
          //   dispatch(setSummeryTreatments({ index: index, key: 'cfAmountNet', value: newQty * (Number(list.cfAmountNet) / Number(list.qty)) }))
          // }
        }
      } else {
        const promotionUsage = list?.promotion?.length ? list?.promotion?.map((item: any) => item?.promotionBranchId) : []
        const newPromotions: any = []

        const newDataTreatmentDFCF = { dfAmountNet: calculateDf(newQty), cfAmountNet: calculateCf(newQty) }
        const newDataPromotionDiscountOp = { dfPromotionDiscountAmount: 0, dfPromotionDiscountUnit: CURRENCY_UNIT.BAHT, cfPromotionDiscountAmount: 0, cfPromotionDiscountUnit: CURRENCY_UNIT.BAHT }
        const newDataVoucherDiscount = { voucherListId: 0, voucher: {}, voucherDiscount: 0, voucherDiscountUnit: CURRENCY_UNIT.BAHT, }
        const newDataVoucherDiscountOp = { dfVoucherDiscountAmount: 0, dfVoucherDiscountUnit: CURRENCY_UNIT.BAHT, cfVoucherDiscountAmount: 0, cfVoucherDiscountUnit: CURRENCY_UNIT.BAHT }
        const newDataPromotionDiscount = newPromotions[index]?.operativeId ?
          { ...newDataTreatmentDFCF, promotionDiscount: 0, promotionDiscountUnit: CURRENCY_UNIT.BAHT, ...newDataPromotionDiscountOp, ...newDataVoucherDiscount, ...newDataVoucherDiscountOp }
          : { promotionDiscount: 0, promotionDiscountUnit: CURRENCY_UNIT.BAHT, ...newDataVoucherDiscount }

        treatments?.summeryTreatments?.forEach((item: any, num: number) => {
          const dfcfNet = item?.dfAmount !== null ? { dfAmountNet: calculateDf(newQty), cfAmountNet: calculateCf(newQty) } : {}
          if (item?.promotion?.length) {
            const checkType = item?.promotion?.some((info: any) => info?.promotionType === 'EXTRA' && promotionUsage.includes(info?.promotionBranchId))
            if (!checkType) {
              const newPromotionItem = item?.promotion?.filter((info: any) => !promotionUsage.includes(info?.promotionBranchId)) || []
              if (index === num) {
                newPromotions.push({ ...item, promotion: newPromotionItem, qty: newQty, total: total, ...newDataPromotionDiscount, ...dfcfNet })
              } else {
                newPromotions.push({ ...item, promotion: newPromotionItem })
              }
            }
          } else {
            if (index === num) {
              newPromotions.push({ ...item, qty: newQty, total: total, ...newDataPromotionDiscount, ...dfcfNet })
            } else {
              newPromotions.push(item)
            }
          }

        })
        dispatch(setTreatmentByKey({ key: 'summeryTreatments', value: newPromotions }))
      }

    } else if (e.target.name === 'doctorId' && treatments.summeryTreatments[0].pinDoctorId && e.target.value !== treatments.summeryTreatments[0].pinDoctorId) {
      handlePinDoctor('unPin', 0)
      dispatch(setSummeryTreatments({ index: index, key: e.target.name, value: e.target.value }))
    } else if (e.target.name === 'doctorId') {
      const list = treatments.summeryTreatments[index]

      dispatch(setSummeryTreatments({ index: index, key: e.target.name, value: e.target.value }))
      if (list?.operativeId) {
        const updateDF: UpdateDFIF | boolean = await updateDFValue({ ...list, doctorId: e.target.value })
        if (updateDF) {
          dispatch(setSummeryTreatments({ index: index, key: 'dfAmount', value: updateDF.dfAmount }))
          dispatch(setSummeryTreatments({ index: index, key: 'dfUnit', value: updateDF.dfUnit }))
          dispatch(setSummeryTreatments({ index: index, key: 'cfAmount', value: updateDF.cfAmount }))
          dispatch(setSummeryTreatments({ index: index, key: 'dfAmountNet', value: updateDF.dfAmountNet }))
          dispatch(setSummeryTreatments({ index: index, key: 'cfAmountNet', value: updateDF.cfAmountNet }))
          dispatch(setSummeryTreatments({ index: index, key: 'doctorFeeAmount', value: updateDF.dfAmount }))
          dispatch(setSummeryTreatments({ index: index, key: 'clinicFeeAmount', value: updateDF.cfAmount }))
        }
      }
    } else dispatch(setSummeryTreatments({ index: index, key: e.target.name, value: e.target.value }))
  }

  const handlePinDoctor = (type: 'pin' | 'unPin', doctorId: number) => {
    const item = _.map(treatments.summeryTreatments, (treatmentSummary, index) => {
      if (type === 'pin') return { ...treatmentSummary, doctorId: treatmentSummary?.isEdit !== '0' ? doctorId : treatmentSummary.doctorId, pinDoctorId: doctorId }
      else return { ...treatmentSummary, pinDoctorId: null }
    })
    dispatch(setTreatmentByKey({ key: 'summeryTreatments', value: item }))
  }

  const onSubmitTreatmentsCourse = () => {
    return
  }

  const onRestPopup = () => {
    setConfigModal({})
    dispatch(modalSlice.resetModal())
    setPopupProductOutOfStock(initProductOutOfStock)
  }

  const handleRowHover = (type: 'onMouseEnter' | 'onMouseLeave', item: string) => {
    if (type === 'onMouseEnter') $(`#${item}`).prev().addClass('table-row-hover')
    else $(`#${item}`).prev().removeClass('table-row-hover')
  }

  const editToothChart = async (id: number) => {
    if (!treatments.summeryTreatments[id].teeth) {
      const resp: any = await OperativeTypeApi.findById(treatments.summeryTreatments[id].operativeTypeId)
      if (resp.status === 200) {
        $('.div-component').delay(500).animate({ scrollTop: 0 }, 500)
        dispatch(setSummeryTreatments({ index: id, key: 'color', value: resp.data.color }))
        dispatch(setEditTootChartType(treatments.summeryTreatments[id].teethType))
        dispatch(setEditTootChart(id))
        if (props.type === 'RetrospectiveEdit') {
          const emrDetail = $('.emr-detail')
          const toothChart = $('.tooth-chart')
          emrDetail[0].scrollTo({ top: toothChart[0].offsetTop - 180, behavior: "smooth" })
        }
      }
    } else {
      $('.div-component').delay(500).animate({ scrollTop: 0 }, 500)
      dispatch(setEditTootChartType(treatments.summeryTreatments[id].teethType))
      dispatch(setEditTootChart(id))
      if (props.type === 'RetrospectiveEdit') {
        const emrDetail = $('.emr-detail')
        const toothChart = $('.tooth-chart')
        emrDetail[0].scrollTo({ top: toothChart[0].offsetTop - 180, behavior: "smooth" })
      }
    }
  }

  const renderTreatmentNextVisit = (value: any) => {
    let renderTreatment = typeof value === 'string' && value ? JSON.parse(value) : value
    if (typeof renderTreatment === 'string') {
      const step1 = renderTreatment.replace(/\\/g, '');
      const step2 = step1.replace(/^"|"$/g, '');
      renderTreatment = JSON.parse(step2)
    }

    if (!_.isEmpty(renderTreatment)) {
      return `${renderTreatment[0]?.operativeName || renderTreatment[0]?.operativeTypeName} ${_.size(renderTreatment) > 1
        ? t('TREATMENT_SUMMARY.POPUP.TREATMENT_NEXT_VISIT.TEMPLATE_MESSAGE.NEXT_VISIT_MORE_TREATMENT', {
          treatment_count: _.size(renderTreatment) - 1
        })
        : ''
        }`
    } else return ''
  }

  const headCellsTooth = clinicTheme.theme === clinicTheme.type.DENTAL ? [{ id: 'message', disablePadding: false, label: t('TREATMENT_SUMMARY.TITLE.TOOTH') }] : []
  const headCellsToothEmrEdit = clinicTheme.theme === clinicTheme.type.DENTAL ? [{ id: 'message', disablePadding: false, label: t('TREATMENT_SUMMARY.TITLE.TOOTH'), width: '110px' }] : []

  const headCells = [
    { id: 'no', disablePadding: false, label: '#' },
    ...headCellsTooth,
    { id: 'message', disablePadding: false, label: t('TREATMENT_SUMMARY.TITLE.TYPE') },
    { id: 'message', disablePadding: false, label: t('TREATMENT_SUMMARY.TITLE.SERVICE') },
    { id: 'message', disablePadding: false, align: 'center', label: t('TREATMENT_SUMMARY.TITLE.QUANTITY') },
    { id: 'message', disablePadding: false, align: 'right', label: t('TREATMENT_SUMMARY.TITLE.NET_PRICE') },
    { id: 'message', disablePadding: false, align: 'left', label: t('TREATMENT_SUMMARY.TITLE.DOCTOR') },
    { id: 'message', disablePadding: false, align: 'right', label: t('TREATMENT_SUMMARY.TITLE.DISCOUNT') },
    { id: 'message', disablePadding: false, align: 'right', label: t('TREATMENT_SUMMARY.TITLE.ADDON') },
    { id: 'message', disablePadding: false, align: 'right', label: t('TREATMENT_SUMMARY.TITLE.TOTAL') },
    { id: 'message', disablePadding: false, align: 'center', label: t('TREATMENT_SUMMARY.POPUP.COMMISSION.COMMISSION'), width: '110px' },
    { id: 'message', disablePadding: false, align: 'center', label: t('Action') }
  ]

  const headCellsEmrEdit = [
    { id: 'no', disablePadding: false, label: '#', width: '30px' },
    ...headCellsToothEmrEdit,
    { id: 'message', disablePadding: false, label: t('TREATMENT_SUMMARY.TITLE.TYPE'), width: '110px' },
    { id: 'message', disablePadding: false, label: t('TREATMENT_SUMMARY.TITLE.SERVICE'), width: '155px' },
    { id: 'message', disablePadding: false, align: 'center', label: t('TREATMENT_SUMMARY.TITLE.QUANTITY'), width: '85px' },
    { id: 'message', disablePadding: false, align: 'right', label: t('TREATMENT_SUMMARY.TITLE.NET_PRICE'), width: '100px' },
    { id: 'message', disablePadding: false, align: 'left', label: t('TREATMENT_SUMMARY.TITLE.DOCTOR'), width: '180px' },
    { id: 'message', disablePadding: false, align: 'right', label: t('TREATMENT_SUMMARY.TITLE.DISCOUNT'), width: '130px' },
    { id: 'message', disablePadding: false, align: 'right', label: t('TREATMENT_SUMMARY.TITLE.ADDON'), width: '130px' },
    { id: 'message', disablePadding: false, align: 'right', label: t('TREATMENT_SUMMARY.TITLE.TOTAL'), width: '130px' },
    { id: 'message', disablePadding: false, align: 'center', label: t('TREATMENT_SUMMARY.POPUP.COMMISSION.COMMISSION'), width: '110px' },
    { id: 'message', disablePadding: false, align: 'center', label: t('Action'), width: '65px' }
  ]

  const renderData = (data: any, no: number) => {

    const {
      operativeId,
      operativeTypeId,
      operativeTypeName,
      operativeTypeNameEn,
      operativeName,
      operativeNameEn,
      qty,
      doctorId,
      note,
      nextVisit,
      noteNextVisit,
      itemTypeName,
      itemTypeNameEn,
      itemName,
      itemNameEn,
      teeth,
      clinicFeeId,
      clinicFeeName,
      clinicFeeNameEn,
      clinicFeePrice,
      sticker,
      hasCommission,
      commission,
      refType
    } = data

    const isEdit = branchInfo?.pdfInvoiceTreatmentCondition === '0' ? data?.isEdit : '1'
    const dataTreamtmentCurrent = (data?.approvalItems?.body && data?.approvalItems?.status === ApprovalStatus.PENDING) ? JSON.parse(data?.approvalItems?.body) : undefined
    const newData = dataTreamtmentCurrent || data

    let stickerProductUseDetail = ''
    let stickerDosage = ''
    let stickerProductUnitName = ''
    let stickerProductInstructionName = ''

    if (sticker) {
      const stickerObj: any = JSON.parse(sticker)
      const { productUseDetail, dosage, productUnitName, productInstructionName } = stickerObj
      stickerProductUseDetail = productUseDetail || ''
      stickerDosage = dosage || ''
      stickerProductUnitName = productUnitName || ''
      stickerProductInstructionName = productInstructionName || ''
    }

    const price = Number(newData.price || 0)
    const total = price <= 0 && newData.dfUnit !== 'PERCENT' && operativeTypeId ? 0 : Number(newData.total || 0)

    const rawAdditional: RawAdditionalType = {
      price: price,
      qty: Number(newData.qty),
      additional: Number(newData.additional),
      additionalUnit: newData.additionalUnit
    }

    const rawDiscount: RawDiscountType = {
      price: price,
      qty: Number(newData.qty),
      discount: Number(newData.discount),
      discountUnit: newData.discountUnit,
      dfUnit: newData.dfUnit,
      dfAmount: Number(newData.dfAmount),
      dfDiscountAmount: Number(newData.dfDiscountAmount),
      dfDiscountUnit: newData.dfDiscountUnit,
      cfAmount: Number(newData.cfAmount),
      cfDiscountAmount: Number(newData.cfDiscountAmount),
      cfDiscountUnit: newData.cfDiscountUnit,
      additionalNet: renderSumAdditional(rawAdditional),
      doctorFeeAmount: newData.doctorFeeAmount,
      clinicFeeAmount: newData.clinicFeeAmount
    }

    let tooltipServiceName = false
    let tooltipServiceType = false
    const handleTooltip = () => {
      const textServiceName = document.querySelector(`.service-name-${operativeId}`)
      if (textServiceName) {
        const textElement = textServiceName.querySelector('.serivce-name')
        if (textElement) {
          if (textElement.scrollWidth > textServiceName.clientWidth) {
            tooltipServiceName = true
          } else {
            tooltipServiceName = false
          }
        }
      }
      const textServicetype = document.querySelector(`.service-type-${operativeId}`)
      if (textServicetype) {
        const textElement = textServicetype.querySelector('p')
        if (textElement) {
          if (textElement.scrollWidth > textServicetype.clientWidth) {
            tooltipServiceType = true
          } else {
            tooltipServiceType = false
          }
        }
      }
    }

    handleTooltip()

    const renderDoctors = () => {
      const pinDoctor = _.find(props.doctors, { userId: treatments?.summeryTreatments[0]?.pinDoctorId })
      if (pinDoctor) {
        const filterDoctor = _.filter(props.doctors, (d) => d.userId !== pinDoctor.userId)
        return [pinDoctor, ...filterDoctor]
      } else return props.doctors
    }

    const handleDisabledMedAndPd = (status: 'Create' | 'Delete') => {
      if (props.type !== 'Payment' && props.type !== 'RetrospectiveOrder') {
        return props.type === 'RetrospectiveEdit' ? true : false
      } else if (data?.group) {
        if (data?.group === "MEDICINE") {
          return user?.[`has${status}Med`] !== 'PERMISS' ? true : false
        }
        if (data?.group === "PRODUCT") {
          return user?.[`has${status}Product`] !== 'PERMISS' ? true : false
        }
        return false
      } else {
        if (status === 'Delete') {
          return props?.hasDeleteTreatment === 'PERMISS' ? false : true
        }
        return false
      }
    }

    const disabledItemName = handleDisabledMedAndPd('Create')
    const disabledDelItem = handleDisabledMedAndPd('Delete')

    // nextVisit
    const handleDisabledNextVisit = () => {
      if (props.type === 'RetrospectiveEdit') {
        if (nextVisit) {
          return nextVisit?.statusAppointment === 'DONE'
        }
        return false
      }
      return false
    }

    const disabledNextVisit = handleDisabledNextVisit()

    const disabledCourse = refType === 'COURSE' && user?.hasDeleteTreatmentCourse !== 'PERMISS' && props.type === 'Payment'
    const disabledPlan = refType === 'PLAN' && user?.hasDeleteTreatmentPlan !== 'PERMISS' && props.type === 'Payment'

    const renderIconApproval = ((props.type === 'Payment' || props.type === 'Treatment' && approvalRight?.hasChangeCost) && Object.values(ApprovalStatus).includes(data?.approvalItems?.status)) ? <Box sx={{ marginLeft: '4px' }}>{approvalStatusList[data?.approvalItems?.status as ApprovalStatus]?.icon}</Box> : <></>
    const disabledApproval = (data?.approvalItems?.status === ApprovalStatus?.PENDING) && approvalRight?.hasChangeCost

    const disabledPromotionExtra = data?.promotion?.length ? data?.promotion.some((item: any) => item?.promotionType === "EXTRA") : false
    const usablePromotions = promotions?.length ? promotions.find((item: any) => item?.itemId === operativeId || item?.itemId === data?.itemId)?.promotions : []
    const checkPromotions = usablePromotions?.length > 0

    const usableVouchers = promotions?.length ? promotions.find((item: any) => item?.itemId === operativeId || item?.itemId === data?.itemId)?.vouchers : []
    const checkVouchers = usableVouchers?.length > 0

    return (
      <>
        <TableRow key={`treatment-${no}`} className={`main-table-row`}>
          <TableCell align="center" scope="row" className="pt-3 pt-sm-4 pt-xl-2">
            {no + 1}
          </TableCell>
          <TableCell align="left" scope="row" colSpan={width >= 1200 ? 1 : _.size(headCells) - 1} className={`pt-3 pt-xl-2`}>
            <Box className="d-flex align-items-sm-center d-xl-block">
              {(clinicTheme.theme === clinicTheme.type.DENTAL && (
                <>
                  {width < 1200 && <UseStyled.Title className="d-inline-block pr-2">{t('TREATMENT_SUMMARY.TITLE.TOOTH')}</UseStyled.Title>}
                  {(operativeTypeId && (
                    <UseStyled.InputToothChart className={`d-sm-flex w-100 text-nowrap ${isEdit === '0' ? 'pe-none' : ''}`}>
                      <InputTextField disabled value={teeth || ''} />
                      <UseStyled.BtnEditToothChart disableFocusRipple disableRipple onClick={() => editToothChart(no)} className={`p-0 ml-2 ${isEditTootCharts === no ? 'active' : ''} ${props.hasTreatment === 'UNPERMISS' ? 'd-none' : ''}`}>
                        {isEditTootCharts === no ? <Icons.ToothSolid /> : <Icons.ToothOutlined />}
                      </UseStyled.BtnEditToothChart>
                    </UseStyled.InputToothChart>
                  )) || <Typography sx={{ flex: 1 }}>-</Typography>}
                </>
              )) || (
                  <>
                    {width < 1200 && <UseStyled.Title className="d-inline-block pr-2">{t('TREATMENT_SUMMARY.TITLE.TYPE')}</UseStyled.Title>}
                    <Typography className="text-ellipsis" sx={{ flex: 1 }}>
                      {operativeTypeId ? operativeTypeName || operativeTypeNameEn || '-' : itemTypeName || itemTypeNameEn || '-'}
                    </Typography>
                  </>
                )}
              {width < 1200 && (
                <IconButton
                  disableFocusRipple
                  disableRipple
                  hidden={disabledDelItem && props.type !== 'RetrospectiveEdit'}
                  disabled={(disabledDelItem) || disabledCourse || disabledPlan || disabledApproval || disabledPromotionExtra}
                  onClick={() => handleDiscard(no)}
                  className={`p-0 align-self-start mr-2 ml-3 ${isEdit === '0' ? 'pe-none' : ''}`}
                  sx={{
                    backgroundColor: 'transparent',
                    filter: (isEdit === '0' || disabledDelItem || disabledCourse || disabledPlan || disabledApproval || disabledPromotionExtra) ? 'grayscale(1)' : 'grayscale(0)',
                    opacity: (isEdit === '0' || disabledDelItem || disabledCourse || disabledPlan || disabledApproval || disabledPromotionExtra) ? 0.5 : 1
                  }}
                >
                  <img src={icons.btnMinus} alt="discard" />
                </IconButton>
              )}
            </Box>
            {width < 1200 && (
              <ResponsiveRenderData
                no={no}
                onViewTreatmentsCourse={() => onViewTreatmentsCourse(no, data, doctorId)}
                onViewManageProduct={() => onViewManageProduct(no, data, sticker)}
                onViewMessage={() => onViewMessage(no)}
                onViewNextVisit={() => onViewNextVisit(no)}
                hasEditNextVisit={disabledNextVisit || false}
                onChange={(e: any) => handleChange(e, no)}
                data={data}
                doctors={props.doctors}
                doctorsSelect={renderDoctors()}
                price={price || 0}
                discount={Number((operativeTypeId && renderSumDiscount(rawDiscount)) || cal_sum(newData, rawDiscount.discount, 'discount') || 0)}
                additional={Number((operativeTypeId && renderSumAdditional(rawAdditional)) || cal_sum(newData, rawAdditional.additional, 'additional') || 0)}
                total={cal_sum(newData, total, 'total')}
                hasTreatment={props.hasTreatment}
                hasChangeTreatmentCost={props.hasChangeTreatmentCost}
                hasChangeTreatmentNote={props.hasChangeTreatmentNote}
                renderTreatmentNextVisit={renderTreatmentNextVisit(nextVisit?.treatment)}
                hasDeleteTreatment={props.hasDeleteTreatment || 'PERMISS'}
                hasCommission={hasCommission}
                handleCommission={handleCommission}
                commission={commission || 0}
                type={props.type}
                disabledItemName={disabledItemName}
                renderIconApproval={renderIconApproval}
              />
            )}
          </TableCell>
          {width >= 1200 && (
            <>
              {clinicTheme.theme === clinicTheme.type.DENTAL && <>
                {!tooltipServiceType && (
                  <TableCell align="left" scope="row" className={`service-type-${operativeId}`}>
                    <Typography className="text-ellipsis">{operativeTypeId ? operativeTypeName || operativeTypeNameEn || '-' : itemTypeName || itemTypeNameEn || '-'}</Typography>
                  </TableCell>
                )}
                {tooltipServiceType && (
                  <Tooltip title={operativeTypeId ? operativeTypeName || operativeTypeNameEn || '-' : itemTypeName || itemTypeNameEn || '-'} placement="top">
                    <TableCell align="left" scope="row" className={`service-type-${operativeId}`}>
                      <Typography className="text-ellipsis">{operativeTypeId ? operativeTypeName || operativeTypeNameEn || '-' : itemTypeName || itemTypeNameEn || '-'}</Typography>
                    </TableCell>
                  </Tooltip>
                )}
              </>
              }
              <TableCell align="left" scope="row" className={`service-name-${operativeId}`}>
                <Box display={'flex'} alignItems={'center'}>
                  {(checkVouchers && !disabledPromotionExtra) &&
                    <Box marginRight={'4px'} display={'flex'} alignItems={'center'} minWidth={'fit-content'}
                      className={`p-0 text-left overflow-hidden ${props.hasChangeTreatmentCost === 'UNPERMISS' || isEdit === '0' || disabledPromotionExtra ? 'pe-none' : ''}`}
                      onClick={() => {
                        if (disabledItemName) {
                          return
                        }
                        if (operativeTypeId) onViewTreatmentsCourse(no, data, doctorId)
                        else onViewManageProduct(no, data, sticker)
                      }}
                      sx={{ cursor: 'pointer' }}
                    >
                      <IconGiftVoucher />
                      {/* {usablePromotions?.length > 1 &&
                        <Box marginLeft={'4px'} color={colors.themeSecondColor} sx={{ textDecoration: 'underline' }}>
                          {`(${usablePromotions?.length})`}
                        </Box>
                      } */}
                    </Box>

                  }
                  {(checkPromotions && !disabledPromotionExtra) &&
                    <Box marginRight={'4px'} display={'flex'} alignItems={'center'} minWidth={'fit-content'}
                      className={`p-0 text-left overflow-hidden ${props.hasChangeTreatmentCost === 'UNPERMISS' || isEdit === '0' || disabledPromotionExtra ? 'pe-none' : ''}`}
                      onClick={() => {
                        if (disabledItemName) {
                          return
                        }
                        if (operativeTypeId) onViewTreatmentsCourse(no, data, doctorId)
                        else onViewManageProduct(no, data, sticker)
                      }}
                      sx={{ cursor: 'pointer' }}
                    >
                      <IconPromotion />
                      {/* {usablePromotions?.length > 1 &&
                        <Box marginLeft={'4px'} color={colors.themeSecondColor} sx={{ textDecoration: 'underline' }}>
                          {`(${usablePromotions?.length})`}
                        </Box>
                      } */}
                    </Box>
                  }
                  <UseStyled.ButtonOperative
                    disabled={disabledItemName ? true : false}
                    variant="text"
                    disableFocusRipple
                    disableElevation
                    disableRipple
                    disableTouchRipple
                    onClick={() => {
                      if (operativeTypeId) onViewTreatmentsCourse(no, data, doctorId)
                      else onViewManageProduct(no, data, sticker)
                    }}
                    className={`p-0 text-left overflow-hidden ${props.hasChangeTreatmentCost === 'UNPERMISS' || isEdit === '0' || disabledPromotionExtra ? 'pe-none' : ''}`}
                    startIcon={renderIconApproval}
                  >
                    {(disabledPromotionExtra) &&
                      <Box component={'span'}>
                        {t('PROMOTION.MODAL_MANAGE.EXTRA')}
                      </Box>
                    }
                    {!tooltipServiceName && (
                      <span className="text-ellipsis serivce-name" style={{ textTransform: 'none', textDecoration: props.hasChangeTreatmentCost === 'UNPERMISS' || isEdit === '0' || disabledItemName ? '' : 'underline' }}>
                        {operativeName || operativeNameEn || itemName || itemNameEn}
                      </span>
                    )}
                    {tooltipServiceName && (
                      <Tooltip title={operativeName || operativeNameEn || itemName || itemNameEn} placement="top">
                        <span className="text-ellipsis serivce-name" style={{ textTransform: 'none', textDecoration: props.hasChangeTreatmentCost === 'UNPERMISS' || isEdit === '0' || disabledItemName ? '' : 'underline' }}>
                          {operativeName || operativeNameEn || itemName || itemNameEn}
                        </span>
                      </Tooltip>
                    )}
                  </UseStyled.ButtonOperative>
                  {newData?.group === 'MEDICINE' && <div>{`${stickerProductInstructionName} ${stickerDosage} ${stickerProductUnitName} ${stickerProductUseDetail}`}</div>}
                </Box>
                {clinicFeeId && (
                  <Box>
                    <Typography className="d-inline" sx={{ fontWeight: 700 }}>
                      CF:
                    </Typography>
                    <Typography className="d-inline pl-2" sx={{ fontWeight: 500, color: colors.textLightGray }}>
                      {clinicFeeName || clinicFeeNameEn} ({numberFormat(clinicFeePrice || 0, 0)} {t('CURRENCY_CHANGE.TH')})
                    </Typography>
                  </Box>
                )
                }
              </TableCell >
              <TableCell align="center" scope="row">
                <InputTextField
                  name="qty"
                  inputProps={{ min: 1 }}
                  disabled={isEdit === '0' || disabledItemName || disabledPromotionExtra || (dataTreamtmentCurrent && approvalRight?.hasChangeCost) ? true : false}
                  onchange={(e: any) => handleChange(e, no)}
                  value={newData?.qty || qty}
                  label={''}
                  inputTextAlign="right"
                  hideControl
                  helperText={qty === 0 ? 'qty' : ''}
                  style={{ '.MuiFormHelperText-root.Mui-error': { display: 'none' } }}
                />
              </TableCell>
              <TableCell align="right" scope="row">
                {numberFormat(newData?.installment?.installmentItemPrice || newData?.installment?.totalPinstallmentItemPricerice || price)}
                {/* {numberFormat(_.find(newData?.installment?.installmentList, (item) => item.status === 'PENDING')?.price || price)} */}
              </TableCell>
              <TableCell align="left" scope="row">
                <AutocompleteSelect
                  labelId="sl-dentist"
                  options={renderDoctors()}
                  getOptionLabel={(option) => option.fullname}
                  renderOption={(props, option) => {
                    return (
                      <UseStyled.DentistList
                        {...props}
                        key={option.userId}
                        component="li"
                        className={`d-flex align-items-center justify-content-between w-100 cursor-pointer overflow-hidden`}
                        sx={{
                          pointerEvents: doctorHasOverDue(treatments, option.userId) ? 'none' : 'auto',
                          color: doctorHasOverDue(treatments, option.userId) ? colors.disabledGray : colors.textPrimary,
                          svg: {
                            filter: doctorHasOverDue(treatments, option.userId) ? 'grayscale(1)' : 'grayscale(0)'
                          }
                        }}
                      >
                        <div title={option.fullname} className="text-ellipsis">
                          <span onClick={() => handleChange({ target: { name: 'doctorId', value: option.userId } }, no)} className="text-ellipsis">
                            {option.fullname}
                          </span>
                        </div>
                        <Box className="ml-2" onClick={() => handlePinDoctor('pin', option.userId)}>
                          <Icons.MarkLine active={option.userId === treatments?.summeryTreatments[0]?.pinDoctorId} />
                        </Box>
                      </UseStyled.DentistList>
                    )
                  }}
                  onchange={() => {
                    return
                  }}
                  noOptionsText={t('NOT_FOUND')}
                  filterOptions={(option) => option.fullname}
                  value={_.find(props.doctors, { userId: doctorId }) || null}
                  disableClearable
                  disabled={isEdit === '0' || props.type === 'RetrospectiveEdit' ? true : false}
                  params={{
                    ListboxProps: {
                      sx: {
                        maxHeight: '200px'
                      }
                    }
                  }}
                />
              </TableCell>
              <TableCell align="right" scope="row">
                {numberFormat(Number((operativeTypeId && renderSumDiscount(rawDiscount)) || cal_sum(newData, rawDiscount.discount, 'discount') || 0))} {t('CURRENCY_CHANGE.TH')}
              </TableCell>
              <TableCell align="right" scope="row">
                {numberFormat(Number((operativeTypeId && renderSumAdditional(rawAdditional)) || cal_sum(newData, rawAdditional.additional, 'additional') || 0))} {t('CURRENCY_CHANGE.TH')}
              </TableCell>
              <TableCell align="right" scope="row" sx={{ position: 'relative' }}>
                {((data?.promotion?.length || data?.voucher?.voucherListId) && data?.fullPrice && (Number(data?.fullPrice) !== Number(cal_sum(newData, total, 'total')))) ?
                  <Box color={'#808080'} fontSize={'12px'} sx={{ textDecorationLine: 'line-through' }}>{numberFormat(data?.fullPrice)}</Box>
                  : <></>
                }
                {numberFormat(cal_sum(newData, total, 'total'))} {t('CURRENCY_CHANGE.TH')}

              </TableCell>
              <TableCell align="center" scope="row" >
                <div>
                  <ButtonCustom
                    onClick={() => handleCommission(data, hasCommission, no)}
                    disabled={isEdit === '0' || props.type === 'RetrospectiveEdit'}
                    style={{
                      width: i18n.language === 'th' ? "64px" : "100%",
                      height: i18n.language === 'th' ? "25px" : "100%",
                      '&.MuiButton-contained': {
                        padding: "0px !important",
                        height: '100%', width: '100%'
                      }
                    }}
                    textButton={t('TREATMENT_SUMMARY.POPUP.COMMISSION.COMMISSION')}
                  />
                  {commission && commission !== 0 &&
                    <div style={{ fontSize: "12px", color: "#929292", marginTop: "1px" }}>
                      {numberFormat(commission)} {t('CURRENCY_CHANGE.TH')}
                    </div> || <></>
                  }

                </div>
              </TableCell>
              <TableCell align="center" scope="row" className={`pt-3 pt-sm-4 pt-xl-2`}>
                <IconButton
                  disableFocusRipple
                  disableRipple
                  hidden={disabledDelItem && props.type !== 'RetrospectiveEdit'}
                  disabled={(disabledDelItem) || disabledCourse || disabledPlan || disabledApproval || disabledPromotionExtra}
                  onClick={() => handleDiscard(no)}
                  className={`p-0 ${isEdit === '0' ? 'pe-none' : ''}`}
                  sx={{
                    backgroundColor: 'transparent',
                    filter: ((isEdit === '0' || disabledDelItem || disabledCourse || disabledPlan || disabledApproval || disabledPromotionExtra)) ? 'grayscale(1)' : 'grayscale(0)',
                    opacity: ((isEdit === '0' || disabledDelItem || disabledCourse || disabledPlan || disabledApproval || disabledPromotionExtra)) ? 0.5 : 1
                    // && props.type !== 'RetrospectiveOrder'
                  }}
                >
                  <img src={icons.btnMinus} alt="discard" />
                </IconButton>
              </TableCell>
            </>
          )}
        </TableRow >
        {width >= 1200 && (
          <TableRow key={`treatment-row-${no}`} id={`treatment-row-${no}`} onMouseEnter={() => handleRowHover('onMouseEnter', `treatment-row-${no}`)} onMouseLeave={() => handleRowHover('onMouseLeave', `treatment-row-${no}`)}>
            <TableCell align="center" scope="row" className="pt-1"></TableCell>
            <TableCell align="left" scope="row" colSpan={_.size(headCells) - 1} className="pt-1">
              <Box className="d-flex align-items-start">
                <Box className={`${isEdit === '0' ? 'pe-none' : ''} w-100 d-flex`} sx={{ paddingRight: operativeTypeId ? '12px' : 0 }}>
                  <Typography>{t('TREATMENT_SUMMARY.TITLE.MORE_INFO')}</Typography>
                  <UseStyled.InputFormControl className="ml-2 d-flex position-relative" sx={{ flex: 1 }}>
                    <TextareaAutosize
                      name="note"
                      disabled={props.hasChangeTreatmentNote === 'UNPERMISS' || isEdit === '0' ? true : false}
                      className={`input-form-note ${props.hasChangeTreatmentNote === 'UNPERMISS' || isEdit === '0' ? 'disabled' : ''}`}
                      value={note}
                      onChange={(e: any) => handleChange(e, no)}
                      minRows={2}
                    />
                    <Box className="input-form-note-wrap position-absolute"></Box>
                    <ButtonCustom
                      disabled={props.hasChangeTreatmentNote === 'UNPERMISS' || isEdit === '0' ? true : false}
                      onClick={() => onViewMessage(no)}
                      textButton={<img src={icons.iconFeatherEdit} alt="" style={{ width: 24 }} />}
                      className="h-100"
                      style={{ margin: 0, height: '100%' }}
                    />
                  </UseStyled.InputFormControl>
                </Box>
                {operativeTypeId && (
                  <Box className="w-100" sx={{ paddingLeft: '12px' }}>
                    <Box className="d-flex">
                      <Typography>{t('Next visit')}</Typography>
                      <UseStyled.InputFormControl className="ml-2 d-flex position-relative next-visit" sx={{ flex: 1 }}>
                        <TextareaAutosize name="noteNextVisit" className={`input-form-note ${isEdit === '0' ? 'pe-none disabled' : ''}`} value={noteNextVisit} onChange={(e: any) => handleChange(e, no)} minRows={2} />
                        <Box className="input-form-note-wrap position-absolute"></Box>
                        <ButtonCustom
                          onClick={() => onViewNextVisit(no)}
                          textButton={<img src={icons.iconCalendarPlus} alt="" style={{ width: 28 }} />}
                          className="h-100"
                          style={{ margin: 0, height: '100%' }}
                          disabled={(isEdit === '0' || disabledNextVisit) ? true : false}
                        />
                      </UseStyled.InputFormControl>
                    </Box>
                    {(!_.isEmpty(nextVisit) && (
                      <Box className="d-flex mt-2 mb-1">
                        <Typography sx={{ flex: 'none', opacity: 0 }}>{t('Next visit')}</Typography>
                        <Typography className="ml-2">
                          <span style={{ fontWeight: 600 }}>{t('TREATMENT_SUMMARY.TITLE.APPOINTMENT')} :</span>
                          <span className="ml-1" style={{ color: colors.textLightGray }}>{`${t('TREATMENT_SUMMARY.POPUP.TREATMENT_NEXT_VISIT.TEMPLATE_MESSAGE.NEXT_VISIT', {
                            date: nextVisit?.date ? toBuddhistYear(moment(nextVisit?.date), 'DD/MM/YYYY') : '-',
                            time: nextVisit?.timeStart ? toBuddhistYear(moment(nextVisit.timeStart, 'HH:mm'), 'HH:mm') : '-',
                            dentist: _.get(_.find(props.doctors, { userId: nextVisit?.doctorId }), 'fullname'),
                            treatment: renderTreatmentNextVisit(nextVisit?.treatment)
                          })}${disabledNextVisit ? t('TREATMENT_SUMMARY.POPUP.TREATMENT_NEXT_VISIT.TEMPLATE_MESSAGE.SUCCESS') : ''}`}</span>
                        </Typography>
                      </Box>
                    )) || <></>}
                  </Box>
                )}
              </Box>
            </TableCell>
            {clinicTheme.theme === clinicTheme.type.DENTAL && <TableCell align="center" scope="row" className="pt-1"></TableCell>}
          </TableRow>
        )}
      </>
    )
  }

  const handleCommission = async (data: any, hasCommission: '1' | '0', index: number) => {
    let CommissionInfo: any = {}
    const sumPrice = data.price * data.qty

    if (data.operativeId) {
      if (!data.tempOrderTreatmentId) {
        const patientState: any = location.state
        const servicePointId = patientState?.servicePointId
        const summeryTreatments: any = {
          ...data,
          servicePointId: servicePointId,
          clinicFeeId: Number(data.clinicFeeId) || null
        }
        await TreatmentSummaryApi.setTempOrderTreatment(props?.patientCaseId || 0, summeryTreatments)
          .then((res: any) => {
            const res_sumPrice = res.data.price * res.data.qty
            dispatch(setSummeryTreatments({ index: index, key: 'tempOrderTreatmentId', value: res.data.tempOrderTreatmentId }))
            CommissionInfo = {
              type: 'treatment',
              patientCaseId: props?.patientCaseId,
              code: res.data.operativeCode,
              itemName: res.data.operativeName,
              qty: res.data.qty,
              price: res.data.price,
              sum: res_sumPrice,
              tempOrderId: res.data.tempOrderTreatmentId,
              operativeId: res.data.operativeId
            }
          })
          .catch((e) => {
            return
          })
      } else {
        CommissionInfo = {
          type: 'treatment',
          patientCaseId: props?.patientCaseId,
          code: data.operativeCode,
          itemName: data.operativeName,
          qty: data.qty,
          price: data.price,
          sum: sumPrice,
          tempOrderId: data.tempOrderTreatmentId,
          operativeId: data.operativeId
        }
      }
    } else {
      if (data.group === 'MEDICINE') {
        CommissionInfo = {
          type: 'medicine',
          patientCaseId: props?.patientCaseId,
          code: data.itemCode,
          itemName: data.itemName,
          qty: data.qty,
          price: data.price,
          sum: sumPrice,
          tempOrderId: data.tempOrderProductId,
          operativeId: data.operativeId
        }
      } else {
        CommissionInfo = {
          type: 'product',
          patientCaseId: props?.patientCaseId,
          code: data.itemCode,
          itemName: data.itemName,
          qty: data.qty,
          price: data.price,
          sum: sumPrice,
          tempOrderId: data.tempOrderProductId,
          operativeId: data.operativeId
        }
      }
    }

    setDataCommission(CommissionInfo)
    setConfigModal({ type: 'Commission', hasCommission: hasCommission })
    dispatch(modalSlice.showModal())
  }

  useEffect(() => {
    if (!modal) onRestPopup()
  }, [modal])

  return (
    <Box>
      {props.type !== 'RetrospectiveEdit' && (
        <UseStyled.ResponsiveSummaryTreatmentTable className={'overflow-hidden custom-scroll'}>
          <TableCustom
            hidePagination
            customScroll
            page={0}
            pageLimit={0}
            sortType={'ASC'}
            sortBy={''}
            rowCount={0}
            textEmptyData={t('REGISTER.NO_ITEM')}
            onSort={() => {
              return
            }}
            setPageLimit={() => {
              return
            }}
            setPage={() => {
              return
            }}
            headCells={headCells}
            rowsData={_.map(treatments.summeryTreatments, (i, index: number) => {
              return renderData(i, index)
            }).reverse()}
          />
        </UseStyled.ResponsiveSummaryTreatmentTable>
      )}
      {props.type === 'RetrospectiveEdit' && (
        <UseStyled.ResponsiveSummaryTreatmentTableEmrEdit className={'overflow-hidden custom-scroll'}>
          <TableCustom
            hidePagination
            customScroll
            page={0}
            pageLimit={0}
            sortType={'ASC'}
            sortBy={''}
            rowCount={0}
            textEmptyData={t('REGISTER.NO_ITEM')}
            onSort={() => {
              return
            }}
            setPageLimit={() => {
              return
            }}
            setPage={() => {
              return
            }}
            headCells={headCellsEmrEdit}
            rowsData={_.map(treatments.summeryTreatments, (i, index: number) => {
              return renderData(i, index)
            }).reverse()}
          />
        </UseStyled.ResponsiveSummaryTreatmentTableEmrEdit>
      )}

      {props.type !== 'Payment' && props.type !== 'RetrospectiveOrder' && (
        <UseStyled.NetPrice className={`d-flex align-items-center justify-content-between justify-content-md-end mt-2 px-3 px-md-4`}>
          {t('TREATMENT_SUMMARY.TITLE.SUM_TOTAL')}{' '}
          <span className="pl-2 pl-md-5 text-right">
            {numberFormat(_.sumBy(treatments.summeryTreatments.map((item: any) => {
              if (item?.approvalItems?.status === ApprovalStatus.PENDING) {
                const newData = JSON.parse(item?.approvalItems?.body)
                return { ...item, total: newData?.total }
              } else {
                return { ...item }
              }
            }), 'total'))} {t('CURRENCY_CHANGE.TH')}
          </span>
        </UseStyled.NetPrice>
      )}

      {configModal.type === 'TreatmentsCourse' &&
        <PopupManageTreatmentCourse
          userId={configModal.doctorId}
          itemId={configModal.itemId}
          operative={configModal.operative}
          onSubmit={onSubmitTreatmentsCourse}
          onReset={onRestPopup}
          patient={{ patientCaseId: props?.patientCaseId, patientName: props?.patientName, patientId: props?.patientId }}
        />
      }
      {configModal.type === 'ViewMessage' && <PopupMessage type="summaryTreatment" userId={props.userId} itemId={configModal.itemId} onReset={onRestPopup} />}
      {configModal.type === 'NextVisit' && <PopupNextVisit patient={{ patientId: props.patientId, patientName: props.patientName }} userId={props.userId} itemId={configModal.itemId} onReset={onRestPopup} />}
      {configModal.type === 'ManageProduct' && (
        <PopupManageProduct
          page={(props.type === 'Treatment' && 'Treatment') || (props.type === 'Payment' && 'Payment') || 'RetrospectiveOrder'}
          group={configModal.product?.group}
          type={'summaryTreatment'}
          index={configModal.itemId}
          item={configModal.product}
          patientCaseId={props.patientCaseId || 0}
          onCancel={onRestPopup}
          onSubmit={onRestPopup}
          patientPhone={props.patientPhone}
          patientName={props.patientName || ''}
          hasChangeProductPrice={props.hasChangeProductPrice}
          hasChangeProductDiscountAdditional={props.hasChangeProductDiscountAdditional}
          patientId={props?.patientId}
        />
      )}
      {configModal.type === 'Commission' && <PopupCommission data={dataCommission} onReset={onRestPopup} hasCommission={configModal.hasCommission} onSave={props?.onSave ? props?.onSave : undefined} />}
      {popupProductOutOfStock.name && <AlertProductOutOfStock type="product" name={popupProductOutOfStock.name} code={popupProductOutOfStock.code} qty={popupProductOutOfStock.qty} unit={popupProductOutOfStock.unit} />}
    </Box>
  )
}

function ResponsiveRenderData(props: ResponsiveRenderDataProps) {
  const { t, i18n } = useTranslation()
  const dispatch = useDispatch()
  const treatments: TreatmentStateInterface = useSelector(treatment)
  const approvalRight = useSelector(checkApprovalRight)
  const promotions = useSelector(promotionsTreatments)

  const {
    operativeId,
    operativeTypeId,
    operativeTypeName,
    operativeTypeNameEn,
    operativeName,
    operativeNameEn,
    qty,
    doctorId,
    note,
    nextVisit,
    noteNextVisit,
    itemTypeName,
    itemTypeNameEn,
    itemName,
    itemNameEn,
    isEdit,
    clinicFeeId,
    clinicFeeName,
    clinicFeeNameEn,
    clinicFeePrice
  } = props.data

  const dataTreamtmentCurrent = (props.data?.approvalItems?.body && props.data?.approvalItems?.status === ApprovalStatus.PENDING) ? JSON.parse(props.data?.approvalItems?.body) : undefined
  const newData = dataTreamtmentCurrent || props.data

  const handlePinDoctor = (type: 'pin' | 'unPin', doctorId: number) => {
    const item = _.map(treatments.summeryTreatments, (treatmentSummary, index) => {
      if (type === 'pin') return { ...treatmentSummary, doctorId: treatmentSummary?.isEdit !== '0' ? doctorId : treatmentSummary.doctorId, pinDoctorId: doctorId }
      else return { ...treatmentSummary, pinDoctorId: null }
    })
    dispatch(setTreatmentByKey({ key: 'summeryTreatments', value: item }))
  }

  const handleChangeDoctor = async (id: number) => {
    if (treatments.summeryTreatments[0].pinDoctorId && id !== treatments.summeryTreatments[0].pinDoctorId) {
      handlePinDoctor('unPin', 0)
      dispatch(setSummeryTreatments({ index: props.no, key: 'doctorId', value: id }))
    } else dispatch(setSummeryTreatments({ index: props.no, key: 'doctorId', value: id }))
    const list = treatments.summeryTreatments[props.no]
    if (list?.operativeId) {
      const updateDF: UpdateDFIF | boolean = await updateDFValue({ ...list, doctorId: id })
      if (updateDF) {
        dispatch(setSummeryTreatments({ index: props.no, key: 'dfAmount', value: updateDF.dfAmount }))
        dispatch(setSummeryTreatments({ index: props.no, key: 'dfUnit', value: updateDF.dfUnit }))
        dispatch(setSummeryTreatments({ index: props.no, key: 'cfAmount', value: updateDF.cfAmount }))
        dispatch(setSummeryTreatments({ index: props.no, key: 'dfAmountNet', value: updateDF.dfAmountNet }))
        dispatch(setSummeryTreatments({ index: props.no, key: 'cfAmountNet', value: updateDF.cfAmountNet }))
        dispatch(setSummeryTreatments({ index: props.no, key: 'doctorFeeAmount', value: updateDF.dfAmount }))
        dispatch(setSummeryTreatments({ index: props.no, key: 'clinicFeeAmount', value: updateDF.cfAmount }))
      }
    }
  }

  const disabledPromotionExtra = props.data?.promotion?.length ? props.data?.promotion.some((item: any) => item?.promotionType === "EXTRA") : false
  const usablePromotions = promotions?.length ? promotions.find((item: any) => item?.itemId === operativeId || item?.itemId === props.data?.itemId)?.promotions : []
  const checkPromotions = usablePromotions?.length > 0

  const usableVouchers = promotions?.length ? promotions.find((item: any) => item?.itemId === operativeId || item?.itemId === props.data?.itemId)?.vouchers : []
  const checkVouchers = usableVouchers?.length > 0

  return (
    <>
      <Grid container spacing={2} alignItems="center" className="mt-0">
        {clinicTheme.theme === clinicTheme.type.DENTAL && (
          <Grid item xs={12} className="w-100">
            <Box className="d-flex align-items-start">
              <UseStyled.Title className="d-inline-block pr-2">{t('TREATMENT_SUMMARY.TITLE.TYPE')}</UseStyled.Title>
              <Typography>{operativeTypeId ? operativeTypeName || operativeTypeNameEn || '-' : itemTypeName || itemTypeNameEn || '-'}</Typography>
            </Box>
          </Grid>
        )}
        <Grid item xs={12} className={`w-100`}>
          <Box className="d-flex align-items-center">
            <UseStyled.Title className="d-inline-block pr-2">{t('TREATMENT_SUMMARY.TITLE.SERVICE')}</UseStyled.Title>
            {checkVouchers &&
              <Box marginRight={'4px'} display={'flex'} alignItems={'center'} minWidth={'fit-content'}
                className={`p-0 text-left overflow-hidden ${props.hasChangeTreatmentCost === 'UNPERMISS' || isEdit === '0' || disabledPromotionExtra ? 'pe-none' : ''}`}
                onClick={() => {
                  if (props.disabledItemName) {
                    return
                  }
                  if (operativeTypeId) props.onViewTreatmentsCourse && props.onViewTreatmentsCourse()
                  else props.onViewManageProduct && props.onViewManageProduct()
                }}
                sx={{ cursor: 'pointer' }}
              >
                <IconGiftVoucher />
                {/* {usablePromotions?.length > 1 &&
                  <Box marginLeft={'4px'} color={colors.themeSecondColor} sx={{ textDecoration: 'underline' }}>
                    {`(${usablePromotions?.length})`}
                  </Box>
                } */}
              </Box>

            }
            {(checkPromotions && !disabledPromotionExtra) &&
              <Box marginRight={'4px'} display={'flex'} alignItems={'center'}
                className={`p-0 text-left overflow-hidden ${props.hasChangeTreatmentCost === 'UNPERMISS' || isEdit === '0' || disabledPromotionExtra ? 'pe-none' : ''}`}
                onClick={() => {
                  if (props.disabledItemName) {
                    return
                  }
                  if (operativeTypeId) props.onViewTreatmentsCourse && props.onViewTreatmentsCourse()
                  else props.onViewManageProduct && props.onViewManageProduct()
                }}
                sx={{ cursor: 'pointer' }}
              >
                <IconPromotion />
                {/* {usablePromotions?.length > 1 &&
                  <Box marginLeft={'4px'} color={colors.themeSecondColor} sx={{ textDecoration: 'underline' }}>
                    {`(${usablePromotions?.length})`}
                  </Box>
                } */}
              </Box>
            }
            <UseStyled.ButtonOperative
              disabled={props.disabledItemName}
              variant="text"
              disableFocusRipple
              disableElevation
              disableRipple
              disableTouchRipple
              onClick={() => {
                if (operativeTypeId) props.onViewTreatmentsCourse && props.onViewTreatmentsCourse()
                else props.onViewManageProduct && props.onViewManageProduct()
              }}
              className={`p-0 text-left overflow-hidden ${props.hasChangeTreatmentCost === 'UNPERMISS' || (isEdit === '0' || props.disabledItemName) ? 'pe-none' : ''}`}
              startIcon={props.renderIconApproval}
            >
              {(disabledPromotionExtra) &&
                <Box component={'span'}>
                  {t('PROMOTION.MODAL_MANAGE.EXTRA')}
                </Box>
              }
              <span className="text-ellipsis" style={{ textDecoration: props.hasChangeTreatmentCost === 'UNPERMISS' || (isEdit === '0' || props.disabledItemName) ? '' : 'underline' }}>
                {operativeName || operativeNameEn || itemName || itemNameEn}
              </span>
            </UseStyled.ButtonOperative>
            {clinicFeeId && (
              <Box>
                <Typography className="d-inline" sx={{ fontWeight: 700 }}>
                  CF:
                </Typography>
                <Typography className="d-inline pl-2" sx={{ fontWeight: 500, color: colors.textLightGray }}>
                  {clinicFeeName || clinicFeeNameEn} ({numberFormat(clinicFeePrice || 0, 0)} {t('CURRENCY_CHANGE.TH')})
                </Typography>
              </Box>
            )}
          </Box>
        </Grid>
        <Grid item xs={6} className={`w-100 ${isEdit === '0' ? 'pe-none' : ''}`}>
          <Box className="d-flex align-items-center">
            <UseStyled.Title className="d-inline-block pr-2">{t('TREATMENT_SUMMARY.TITLE.QUANTITY')}</UseStyled.Title>
            <InputTextField
              inputProps={{ min: 1 }}
              name="qty"
              disabled={isEdit === '0' || props.disabledItemName || disabledPromotionExtra || (dataTreamtmentCurrent && approvalRight?.hasChangeCost)}
              onchange={props.onChange}
              value={newData?.qty || qty}
              label={''}
              inputTextAlign="right"
              hideControl
              helperText={qty === 0 ? 'qty' : ''}
              style={{ maxWidth: '64px', '.MuiFormHelperText-root.Mui-error': { display: 'none' } }}
            />
          </Box>
        </Grid>
        <Grid item xs={6} className="w-100">
          <Box className="d-flex align-items-center">
            <UseStyled.Title className="d-inline-block pr-2">{t('TREATMENT_SUMMARY.TITLE.NET_PRICE')}</UseStyled.Title>
            <Typography>{numberFormat(props.price)}</Typography>
          </Box>
        </Grid>
        <Grid item xs={12} className={`w-100 ${isEdit === '0' ? 'pe-none' : ''}`}>
          <Box className="d-flex align-items-center">
            <UseStyled.Title className="d-inline-block pr-2">{t('TREATMENT_SUMMARY.TITLE.DOCTOR')}</UseStyled.Title>
            <Box className="w-100 overflow-hidden" sx={{ maxWidth: '240px' }}>
              <AutocompleteSelect
                labelId="sl-dentist"
                options={props.doctorsSelect}
                getOptionLabel={(option) => option.fullname}
                renderOption={(props, option) => {
                  return (
                    <UseStyled.DentistList
                      {...props}
                      key={option.userId}
                      component="li"
                      className={`d-flex align-items-center justify-content-between w-100 cursor-pointer overflow-hidden`}
                      sx={{
                        pointerEvents: doctorHasOverDue(treatments, option.userId) ? 'none' : 'auto',
                        color: doctorHasOverDue(treatments, option.userId) ? colors.disabledGray : colors.textPrimary,
                        svg: {
                          filter: doctorHasOverDue(treatments, option.userId) ? 'grayscale(1)' : 'grayscale(0)'
                        }
                      }}
                    >
                      <span onClick={() => handleChangeDoctor(option.userId)} className="text-ellipsis">
                        {option.fullname}
                      </span>
                      <Box className="ml-2" onClick={() => handlePinDoctor('pin', option.userId)}>
                        <Icons.MarkLine active={option.userId === treatments?.summeryTreatments[0]?.pinDoctorId} />
                      </Box>
                    </UseStyled.DentistList>
                  )
                }}
                onchange={() => {
                  return
                }}
                noOptionsText={t('NOT_FOUND')}
                filterOptions={(option) => option.fullname}
                value={_.find(props.doctors, { userId: doctorId }) || null}
                disableClearable
                disabled={isEdit === '0'}
              />
            </Box>
          </Box>
        </Grid>
        <Grid item xs={6} className="w-100">
          <Box className="d-flex align-items-center">
            <UseStyled.Title className="d-inline-block pr-2">{t('TREATMENT_SUMMARY.TITLE.DISCOUNT')}</UseStyled.Title>
            <Typography>
              {numberFormat(props.discount || 0)} {t('CURRENCY_CHANGE.TH')}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={6} className="w-100">
          <Box className="d-flex align-items-center">
            <UseStyled.Title className="d-inline-block pr-2">{t('TREATMENT_SUMMARY.TITLE.ADDON')}</UseStyled.Title>
            <Typography>
              {numberFormat(props.additional || 0)} {t('CURRENCY_CHANGE.TH')}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} className="w-100">
          <Box className="d-flex align-items-center">
            <UseStyled.Title className="d-inline-block pr-2">{t('TREATMENT_SUMMARY.TITLE.TOTAL')}</UseStyled.Title>
            <Box sx={{ position: 'relative' }}>
              {((props?.data?.promotion?.length || props?.data?.voucher?.voucherListId) && props.data?.fullPrice && (Number(props.data?.fullPrice) !== Number(cal_sum(newData, props.total, 'total')))) ?
                <Box color={'#808080'} fontSize={'12px'} sx={{ textDecorationLine: 'line-through' }}>{numberFormat(props.data?.fullPrice)}</Box>
                : <></>
              }
              <Typography>
                {numberFormat(props.total)} {t('CURRENCY_CHANGE.TH')}
              </Typography>
            </Box>

          </Box>
        </Grid>
        <Grid item xs={12} className="w-100">
          <Box className="w-100 d-flex flex-column flex-sm-row">
            <UseStyled.Title className="d-inline-block pr-2">{t('TREATMENT_SUMMARY.POPUP.COMMISSION.COMMISSION')}</UseStyled.Title>
            <div>
              <ButtonCustom
                onClick={() => {
                  if (props.handleCommission) {
                    props?.handleCommission(props.data, props.hasCommission, props?.no)
                  } else return
                }}
                disabled={isEdit === '0' || props.type === 'RetrospectiveEdit'}
                style={{
                  width: i18n.language === 'th' ? "64px" : "100%",
                  height: i18n.language === 'th' ? "25px" : "100%",
                  '&.MuiButton-contained': {
                    padding: "0px !important",
                    height: '100%', width: '100%'
                  }
                }}
                textButton={t('TREATMENT_SUMMARY.POPUP.COMMISSION.COMMISSION')}
              />
              {(props?.commission && props?.commission !== 0 && (
                <div style={{ fontSize: '12px', color: '#929292', marginTop: '1px' }}>
                  {numberFormat(props?.commission)} {t('CURRENCY_CHANGE.TH')}
                </div>
              )) || <></>}
            </div>
          </Box>
        </Grid>
        <Grid item xs={12} className={`w-100 ${!operativeTypeId ? 'pb-2' : ''} ${isEdit === '0' ? 'pe-none' : ''}`}>
          <Box className="w-100 d-flex flex-column flex-sm-row">
            <UseStyled.Title className="d-inline-block pr-2">{t('TREATMENT_SUMMARY.TITLE.MORE_INFO')}</UseStyled.Title>
            <Box className="d-flex w-100 mt-2 mt-sm-0">
              <UseStyled.InputFormControl className="pl-sm-2 d-flex position-relative" sx={{ flex: 1 }}>
                <TextareaAutosize
                  name="note"
                  disabled={props.hasChangeTreatmentNote === 'UNPERMISS' || isEdit === '0' ? true : false}
                  className={`input-form-note ${props.hasChangeTreatmentNote === 'UNPERMISS' || isEdit === '0' ? 'disabled' : ''}`}
                  value={note}
                  onChange={props.onChange}
                  minRows={2}
                />
                <Box className="input-form-note-wrap position-absolute"></Box>
                <ButtonCustom
                  disabled={props.hasChangeTreatmentNote === 'UNPERMISS' || isEdit === '0' ? true : false}
                  onClick={props.onViewMessage}
                  textButton={<img src={icons.iconFeatherEdit} alt="" style={{ width: 24 }} />}
                  className="h-100"
                  style={{ margin: 0, height: '100%' }}
                />
              </UseStyled.InputFormControl>
            </Box>
          </Box>
        </Grid>
        {operativeTypeId && (
          <Grid item xs={12} className="w-100 pb-2">
            <Box className="w-100 d-flex flex-column flex-sm-row">
              <UseStyled.Title className="d-inline-block pr-2">{t('Next visit')}</UseStyled.Title>
              <Box className="d-flex w-100 mt-2 mt-sm-0">
                <UseStyled.InputFormControl className="pl-sm-2 d-flex position-relative next-visit" sx={{ flex: 1 }}>
                  <TextareaAutosize name="noteNextVisit" className={`input-form-note ${isEdit === '0' ? 'pe-none disabled' : ''}`} value={noteNextVisit} onChange={props.onChange} minRows={2} />
                  <Box className="input-form-note-wrap position-absolute"></Box>
                  <ButtonCustom
                    onClick={props.onViewNextVisit}
                    textButton={<img src={icons.iconCalendarPlus} alt="" style={{ width: 28 }} />}
                    className="h-100"
                    style={{ margin: 0, height: '100%' }}
                    disabled={(isEdit === '0' || props?.hasEditNextVisit) ? true : false} />
                </UseStyled.InputFormControl>
              </Box>
            </Box>
            {(!_.isEmpty(nextVisit) && (
              <Box className="w-100 d-flex flex-column flex-sm-row">
                <UseStyled.Title className="d-none d-sm-inline-block pr-2" sx={{ flex: 'none', opacity: 0 }}>
                  {t('Next visit')}
                </UseStyled.Title>
                <Typography className="mt-2">
                  <span style={{ fontWeight: 600 }}>{t('TREATMENT_SUMMARY.TITLE.APPOINTMENT')} :</span>
                  <span className="ml-1" style={{ color: colors.textLightGray }}>{`${t('TREATMENT_SUMMARY.POPUP.TREATMENT_NEXT_VISIT.TEMPLATE_MESSAGE.NEXT_VISIT', {
                    date: nextVisit?.date ? toBuddhistYear(moment(nextVisit?.date), 'DD/MM/YYYY') : '-',
                    time: nextVisit?.timeStart ? toBuddhistYear(moment(nextVisit.timeStart, 'HH:mm'), 'HH:mm') : '-',
                    dentist: _.get(_.find(props.doctors, { userId: nextVisit?.doctorId }), 'fullname'),
                    treatment: props.renderTreatmentNextVisit
                  })}`}</span>
                </Typography>
              </Box>
            )) || <></>}
          </Grid>
        )}
      </Grid>
    </>
  )
}

export const cal_sum = (data: any, rawData: any, status: string) => {
  if (data.group !== 'MEDICINE') {
    return rawData
  } else {
    const promotion = data?.promotionDiscountUnit === 'BAHT' ? data?.promotionDiscount || 0 : data.price * data.qty * ((data?.promotionDiscount || 0) / 100)
    const voucher = data?.voucherDiscountUnit === 'BAHT' ? data?.voucherDiscount || 0 : data.price * data.qty * ((data?.voucherDiscount || 0) / 100)
    const discount = data.discountUnit === 'BAHT' ? data.discount : data.price * data.qty * (data.discount / 100)
    const additional = data.additionalUnit === 'BAHT' ? data.additional : data.price * data.qty * (data.additional / 100)
    const total = (data.price * data.qty - discount + additional) - (promotion + voucher)
    if (status === 'discount') {
      return discount
    }
    if (status === 'additional') {
      return additional
    }
    if (status === 'total') {
      return total
    }
  }
}

export const renderSumDiscount = (value: RawDiscountType) => {
  const discount = value.discountUnit === 'PERCENT' ? (value.price * value.qty * value.discount) / 100 : value.discount
  const additional = value.additionalNet
  let rawDfAmount = 0
  let rawCfAmount = 0

  if (value.price === 0 && value.dfUnit !== 'PERCENT') {
    rawDfAmount = value.dfAmount || value.doctorFeeAmount
    rawCfAmount = 0
  } else if (value.dfUnit === 'PERCENT') {
    if (value.price === 0) {
      rawDfAmount = 0
      rawCfAmount = 0
    } else {
      rawDfAmount = ((value.price * value.qty - discount + additional) / 100) * (value.doctorFeeAmount || value.dfAmount)
      rawCfAmount = ((value.price * value.qty - discount + additional) / 100) * (value.clinicFeeAmount || value.cfAmount)
    }
  } else {
    rawDfAmount = value.dfAmount || value.doctorFeeAmount
    rawCfAmount = value.cfAmount || value.price * value.qty - discount + additional - value.doctorFeeAmount
  }

  const dfDiscount = value.dfDiscountUnit === 'PERCENT' ? (rawDfAmount * value.dfDiscountAmount) / 100 : value.dfDiscountAmount
  const cfDiscount = value.cfDiscountUnit === 'PERCENT' ? (rawCfAmount * value.cfDiscountAmount) / 100 : value.cfDiscountAmount

  return discount + dfDiscount + cfDiscount
}

export const renderSumAdditional = (value: RawAdditionalType) => {
  const additional = value.additionalUnit === 'PERCENT' ? (value.price * value.qty * value.additional) / 100 : value.additional

  return additional
}

export const doctorHasOverDue = (treatments: TreatmentStateInterface, doctorId: number | null) => {
  const hasOverdue = _.some(treatments?.summeryTreatments, { isEdit: '0', doctorId: _.get(treatments?.summeryTreatments[0], 'pinDoctorId') || doctorId })
  const branchInfo = getClinicInfo()
  return branchInfo?.pdfInvoiceTreatmentCondition === '0' ? hasOverdue : false
}

interface UpdateDFValueInterface {
  operativeId: number
  doctorId: number
  price: number
  qty: number
  discount: number
  discountUnit: string
  additional: number
  additionalUnit: string
  dfAmount: number
  dfUnit: string
  dfDiscountAmount: number
  dfDiscountUnit: string
  cfDiscountAmount: number
  cfDiscountUnit: string
}
async function updateDFValue(props: UpdateDFValueInterface) {

  const operative = props
  const condition: FindDoctorFeeProps = {
    operativeId: props.operativeId,
    branchId: Number(getBranch()),
    doctorId: props.doctorId
  }
  const resp = await TreatmentApi.findDoctorFee(condition)
  if (resp.status === 200) {
    const doctorFee = resp.data
    const price = Number(operative.price)
    const qty = Number(operative.qty)
    const newNormalPrice = price * qty

    const rawDiscount = Number(operative.discount)
    const rawDiscountUnit = operative.discountUnit
    const rawAdditional = Number(operative.additional)
    const rawAdditionalUnit = operative.additionalUnit
    let rawDiscountAmount = 0
    let rawAdditionalAmount = 0
    if (rawDiscountUnit === CURRENCY_UNIT.PERCENT) rawDiscountAmount = (newNormalPrice * rawDiscount) / 100
    if (rawDiscountUnit === CURRENCY_UNIT.BAHT) rawDiscountAmount = rawDiscount
    if (rawAdditionalUnit === CURRENCY_UNIT.PERCENT) rawAdditionalAmount = (newNormalPrice * rawAdditional) / 100
    if (rawAdditionalUnit === CURRENCY_UNIT.BAHT) rawAdditionalAmount = rawAdditional

    const newAmount = newNormalPrice - rawDiscountAmount + rawAdditionalAmount

    let newDF = 0
    let newCF = 0
    let rawDfDiscountAmount = 0
    let rawCfDiscountAmount = 0
    if (price === 0 && doctorFee.dfUnit !== CURRENCY_UNIT.PERCENT) {
      const sumDf = doctorFee.dfAmount
      newDF = sumDf
      newCF = 0
    } else if (doctorFee.dfUnit === CURRENCY_UNIT.PERCENT) {
      const sumDf = newAmount * (doctorFee.dfAmount / 100)
      const sumCf = newAmount * (doctorFee.cfAmount / 100)
      newDF = sumDf
      newCF = sumCf
    } else {
      newDF = doctorFee.dfAmount
      newCF = newAmount - doctorFee.dfAmount
    }

    if (operative.dfDiscountUnit === CURRENCY_UNIT.PERCENT) {
      rawDfDiscountAmount = (Number(newDF) * Number(operative.dfDiscountAmount)) / 100
    } else if (operative.dfDiscountUnit === CURRENCY_UNIT.BAHT) {
      rawDfDiscountAmount = operative.dfDiscountAmount

    }

    if (operative.cfDiscountUnit === CURRENCY_UNIT.PERCENT) {
      rawCfDiscountAmount = (Number(newCF) * Number(operative.cfDiscountAmount)) / 100
    } else if (operative.cfDiscountUnit === CURRENCY_UNIT.BAHT) {
      rawCfDiscountAmount = Number(operative.cfDiscountAmount)
    }

    const dfAmountNet = newDF - rawDfDiscountAmount
    const cfAmountNet = newCF - rawCfDiscountAmount
    return { dfAmount: doctorFee.dfAmount, cfAmount: doctorFee.cfAmount, dfUnit: doctorFee.dfUnit, dfAmountNet: dfAmountNet, cfAmountNet: cfAmountNet, total: dfAmountNet + cfAmountNet >= 0 ? dfAmountNet + cfAmountNet : 0 }
  }
  return false
}

import { useState, useEffect, useCallback } from 'react'
import { Box, Grid, Typography, MenuItem, Tooltip } from '@mui/material'
import _ from 'lodash'
import { useDispatch, useSelector } from 'react-redux'

/** TRANSLATION */
import { useTranslation } from 'react-i18next'

/** API*/
import TreatmentApi, { FindDoctorFeeProps } from 'api/dentists/treatment.api'
import OperativeApi from 'api/setting/treatments/operative.api'

/** CONSTANTS */
import { colors } from 'constants/theme'

/** UTILS || SLICE */
import { numberFormat, getBranch, getClinicInfo, getPackage, getCrm } from 'utils/app.utils'
import { treatment, setTreatmentByKey, TreatmentStateInterface, checkApprovalRight, setApprovalRight, promotionsTreatments, loadPromotionTreatments, StickerType, ProductStickerType, } from 'app/slice/treatment.slice'
import * as modalSlice from 'app/slice/modal.slice'

/** COMPONENT */
import ModalCustom from 'component/ModalCustom/ModalCustom'
import InputTextField from 'component/Input/InputTextField'
import InputTextarea from 'component/Input/InputTextarea'
import BasicSelect from 'component/Select/BasicSelect'
import Loading from 'component/Loading'

/** STYLE */
import { styled } from '@mui/material/styles'
import 'component/Treatments/style/modal.css'
import { swalCustom } from 'component/Alert/Swal'
import { ApprovalSelectorCustom } from 'features/approvel-list/approvel-list'
import ApprovalApi, { ApprovalStatus } from 'api/approval/approval.api'
import { useLocation } from 'react-router-dom'
import AutocompleteSelect from 'component/Select/AutocompleteSelect'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import InfoIcon from '@mui/icons-material/Info';
import { selectMe } from 'app/slice/user.slice'
import { ModalSelectPromotionExtra, onPromotionSave } from 'features/manage-crm/promotion/promotion-component'
import MainButton from 'new-components/buttons/main-button'
import TableRowCommon from 'component/Table/TableRowCommon'
import TableCustom from 'component/Table/TableCustom'
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined';
import { dateToView } from 'utils/date.utils'
// import IconClose from 'assets/icon/iconClose'
import IconClose from '@mui/icons-material/CloseRounded';
import PromotionsApi from 'api/promotion/promotion.api'
import { notiError } from 'component/notifications/notifications'
import VoucherApi, { VoucherFindByCodeProps } from 'api/voucher/voucher.api'

export enum CURRENCY_UNIT {
  BAHT = 'BAHT',
  PERCENT = 'PERCENT'
}

const Title = styled(Typography)(({ theme }) => ({
  color: colors.themeMainColor,
  [theme.breakpoints.up(375)]: {
    fontSize: 18
  }
}))

const CustomLoading = styled(Box)(({ theme }) => ({
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: colors.backdrop,
  zIndex: 2016
}))

export interface IPopupManageTreatmentCourse {
  itemId?: number
  userId?: number
  operative: any
  onSubmit?: () => void
  onReset?: () => void
  noModal?: boolean
  branchId?: number
  patient?: any
}

export interface ClinicFeeType {
  clinicFeeId: number
  clinicFeeText: string
  operativeClinicFeeId: number
  operativeId: number
  price: number
}

export interface IOperative {
  price: number
  qty: number
  discount: number
  discountUnit: CURRENCY_UNIT
  additional: number
  additionalUnit: CURRENCY_UNIT
  dfUnit: CURRENCY_UNIT
  dfAmount: number
  dfDiscountAmount: number
  dfDiscountUnit: CURRENCY_UNIT
  dfAmountNet: number
  cfAmount: number
  cfDiscountAmount: number
  cfDiscountUnit: CURRENCY_UNIT
  cfAmountNet: number
  dfRemark?: string
  total: number
  doctorFeeAmount?: number
  clinicFeeAmount?: number
  clinicFeeId?: number
  clinicFeeName?: string
  clinicFeeNameEn?: string
  clinicFeePrice?: number
}

export default function PopupManageTreatmentCourse(props: IPopupManageTreatmentCourse) {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const location = useLocation()
  const modal: boolean = useSelector(modalSlice.handleShow)
  const treatments: TreatmentStateInterface = useSelector(treatment)
  const user = useSelector(selectMe)
  const promotions = useSelector(promotionsTreatments)
  const [promotionBranchMain, setPropmotionBranchMain] = useState<any>({})
  const [promotionCurrent, setPromotionCurrent] = useState<any>([])
  const [promotionLoadClear, setPromotionLoadClear] = useState<boolean>(false)
  const [loading, setLoading] = useState(false)
  const [doctorFee, setDoctorFee]: any = useState({})
  const [operative, setOperative]: any = useState({})
  const [operativeCurrent, setOperativeCurrent] = useState<any>({})
  const [statusLoadFirstOp, setStatusLoadFirstOp] = useState<boolean>(true)
  const units: CURRENCY_UNIT[] = [CURRENCY_UNIT.PERCENT, CURRENCY_UNIT.BAHT]

  const [normalPrice, setNormalPrice] = useState(0)

  const [discountAmount, setDiscountAmount] = useState(0)
  const [amount, setAmount] = useState(0)

  const [df, setDf] = useState(0)
  const [cf, setCf] = useState(0)
  const [dfDiscountAmount, setDfDiscountAmount] = useState(0)
  const [cfDiscountAmount, setCfDiscountAmount] = useState(0)

  const [clinicFees, setClinicFees] = useState<any>([])

  const [disabled, setDisabled] = useState(false)

  const [errorMessage, setErrorMessage] = useState({
    PRICE: '',
    QTY: ''
  })

  const [isFocus, setIsFocus] = useState<string>('')

  const [approval, setApproval] = useState<boolean>(false)
  const [isDisabledChange, setIsDisabledChange] = useState<boolean>(false)
  const approvalRight = useSelector(checkApprovalRight)

  const [outToken, setOutToken] = useState<boolean>(false)
  const search = window.location.search;
  const params = new URLSearchParams(search);

  const [promotionOperative, setPromotionOperative] = useState<any>([])
  const [promotionOperativeCurrent, setPromotionProductCurrent] = useState<any>([])
  const [popPromotionExtra, setPopPromotionExtra] = useState<boolean>(false)
  const [promotionExtraValue, setPromotionExtraValue] = useState<any>({})
  const [promotionExtraBuy, setPromotionExtraBuy] = useState<any>([])
  const [promotionExtraNew, setPromotionExtraNew] = useState<any>([])

  const [promotionBuy, setPromotionBuy] = useState<any>([])
  const [promotionExtra, setPromotionExtra] = useState<any>([])

  const [promotionDelId, setPromotionDelId] = useState<number>(0)

  const branchInfo = getClinicInfo()
  const [disabledSave, setDisabledSave] = useState<boolean>(false)
  const [isPromtion, setIsPromotion] = useState<boolean>(false)

  const [voucherLoadClear, setVoucherLoadClear] = useState<boolean>(false)
  const [voucherOperative, setVoucherOperative] = useState<any>({})
  const [vouchers, setVoucher] = useState<any>({})
  const [popVoucherSelectLot, setPopVoucherSelectLot] = useState<boolean>(false)
  const [dataPopVoucherSelectLot, setDataPopVoucherSelectLot] = useState<any>({})
  const [searchVoucher, setSearchVoucher] = useState<string>("")
  const [errorSearchVoucher, setErrorSearchVoucher] = useState<string>("")

  const isPackage = getPackage()

  const loadCheckApprovePermiss = async () => {
    await ApprovalApi.checkApprovePermiss().then(({ data }) => {
      if (data) {
        dispatch(setApprovalRight(data))
      }
    })
  }

  useEffect(() => {
    if (!props.noModal && !params.get('token')) {
      loadCheckApprovePermiss()
    }
  }, [])

  useEffect(() => {
    const newLocation: any = location?.state
    if (newLocation) {
      const { approval } = newLocation
      setApproval(approval)
    }

    if (location?.search) {
      setOutToken(true)
    }

    if (['/treatments', '/payment/create'].includes(location?.pathname)) {
      setIsPromotion(true)
    }

  }, [location])

  useEffect(() => {
    if (statusLoadFirstOp && operative?.doctorFeeAmount) {
      setOperativeCurrent(operative)
      setStatusLoadFirstOp(false)
    }
  }, [operative])

  const loadDoctorFee = async (doctorId: number, operativeId: number) => {
    if (operativeId && !params.get('token')) {
      setLoading(true)
      const condition: FindDoctorFeeProps = {
        operativeId: operativeId,
        branchId: Number(getBranch()),
        doctorId: doctorId
      }
      const resp = await TreatmentApi.findDoctorFee(condition)
      if (resp.status === 200) setDoctorFee(resp.data)
    } else {
      const condition: FindDoctorFeeProps = {
        operativeId: operativeId,
        branchId: props?.branchId || 0,
        doctorId: doctorId
      }
      const resp = await ApprovalApi.findApprovalOperativeById(condition)
      if (resp.status === 200) setDoctorFee(resp.data)
      setLoading(false)
    }
  }

  const loadOperativeById = async (operativeId: number) => {
    if (operativeId && !params.get('token')) {
      const resp: any = await OperativeApi.findById(operativeId).finally(() => setLoading(false))
      if (resp.status === 200) setClinicFees(resp.data?.operativeClinicFees)
    }
  }

  useEffect(() => {
    if (clinicFees[0]) {
      setOperative({
        ...operative,
        clinicFeeId: clinicFees[0]?.clinicFeeId,
        clinicFeeName: clinicFees[0]?.clinicFeeText,
        clinicFeePrice: clinicFees[0]?.price
      })
      setOperativeCurrent({
        ...operativeCurrent,
        clinicFeeId: clinicFees[0]?.clinicFeeId,
        clinicFeeName: clinicFees[0]?.clinicFeeText,
        clinicFeePrice: clinicFees[0]?.price
      })
    }
  }, [clinicFees])

  const handleSetPromotionCurrent = (option?: any) => {
    let mainId = 0
    const newOp = option || promotionOperative || []
    if (newOp?.length) {
      const promotionUsage = newOp?.map((item: any) => {
        if (item?.isMain === '1') {
          mainId = item?.promotionBranchId || 0
        }
        return item?.promotionBranchId
      })
      const newExtra: any[] = []
      const newPromotions = promotions.find((item: any) => item?.itemId === operative?.operativeId)?.promotions || []
      const promotionItem = treatments?.summeryTreatments.find((item: any) => item?.promotion?.some((info: any) => info?.promotionType === 'EXTRA' && info?.promotionBranchId === mainId))?.promotion?.find((item: any) => item?.promotionBranchId === mainId)
      const newPromotionCurrent = (newPromotions?.length && promotionUsage?.length) ? newPromotions.filter((item: any) => {
        if (mainId === item?.promotionBranchId) {
          if (item?.type === "EXTRA" && promotionItem?.groupNumber) {
            item?.extra.forEach((info: any) => {
              if (info?.type === "EXTRA" && promotionItem?.groupNumber === info?.groupNumber) {
                newExtra.push(info)
              }

            })
          }
          setPropmotionBranchMain(item)
        }
        return promotionUsage.includes(item?.promotionBranchId)
      }) : []
      if (promotionItem?.groupNumber && !promotionExtraNew?.length) {
        setPromotionExtraNew(newExtra)
      }
      setPromotionCurrent(newPromotionCurrent)

    } else {
      setPromotionCurrent([])
    }

    if (!mainId) {
      setPropmotionBranchMain({})
    }
  }

  useEffect(() => {
    handleSetPromotionCurrent()
  }, [promotionOperative])

  const handlePromotions = () => {
    if (promotions?.length && operative?.operativeId) {
      const newOption = promotions.find((item: any) => item?.itemId === operative?.operativeId)?.promotions || []
      const promotionUsage = promotionOperative?.map((item: any) => item?.promotionBranchId) || []
      const newPromotion: any = []
      const operativeMain = promotionOperative?.find((item: any) => item?.isMain === '1')
      newOption.forEach((item: any) => {
        if (promotionUsage.includes(item?.promotionBranchId)) {
          const checkMain = operativeMain?.promotionBranchId === item?.promotionBranchId
          newPromotion.push({
            promotionBranchId: item?.promotionBranchId,
            promotionType: checkMain && item?.type === "EXTRA" ? "BUY" : item?.type,
            promotionId: item?.promotionId,
            isMain: checkMain ? '1' : '0'
          })
        }
      })
      setPromotionOperative(newPromotion)
    }
  }

  const handleVoucher = () => {
    if (promotions?.length && operative?.operativeId) {
      const newOption = promotions.find((item: any) => item?.itemId === operative?.operativeId)?.vouchers || []
      // if (!newOption?.length || (newOption?.length && !newOption.some((item: any) => item?.voucherId === voucherOperative?.voucherId))) {
      //   setVoucherOperative({})
      //   setSearchVoucher("")
      // }
      setVoucher(newOption)
    }
  }

  useEffect(() => {
    handlePromotions()
    handleVoucher()
  }, [promotions])

  const onLoadPromotionTreatments = () => {
    const patientId = treatments?.summeryTreatments?.find((item: any) => item?.patientId)?.patientId || 0
    if ((props?.itemId || props?.itemId === 0) && (props?.patient?.patientId || patientId) && operative?.operativeId) {
      const summaryTreatments = _.merge([], treatments.summeryTreatments)
      summaryTreatments[props.itemId] = operative
      dispatch(loadPromotionTreatments(props?.patient?.patientId || patientId, summaryTreatments))
    }
  }

  useEffect(() => {
    if ((!isNaN(operative?.total) && operative?.total !== 0) || operative?.qty === 0) {
      onLoadPromotionTreatments()
    }

  }, [operative?.total, operative?.qty])

  const calPromotionDFCF = (dfcf: number, total: number, data: any) => {
    const keyName = !data?.voucherId ? 'promotion' : 'voucher'
    if (data?.[`${keyName}PriceType`] === "DISCOUNT") {
      if (data?.[`${keyName}Usage`] !== 'OPERATIVE') {
        if (data?.[`${keyName}PriceUnit`] === 'PERCENT') {
          return (total * (data?.[`${keyName}Price`])) / 100
        } else {
          return data?.[`${keyName}Price`]
        }
      } else {
        if (data?.[`${keyName}PriceUnit`] === 'PERCENT') {
          return (dfcf * (data?.[`${keyName}Price`])) / 100
        } else {
          const dfSum = (dfcf * 100) / total
          return (dfSum * data?.[`${keyName}Price`]) / 100
        }
      }
    } else {
      if (data?.[`${keyName}Usage`] !== 'OPERATIVE') {
        return dfcf - data?.[`${keyName}Price`]
      } else {
        const dfSum = (dfcf * 100) / total
        return dfcf - (dfSum * data?.[`${keyName}Price`]) / 100
      }
    }
  }

  const handleCheckApproval = () => {
    if (isPackage?.package !== 'ULTRA') {
      return false
    }
    const { qty, dfAmountNet, cfAmountNet, total, dfRemark, promotion,
      promotionDiscount,
      promotionDiscountUnit,
      dfPromotionDiscountAmount,
      dfPromotionDiscountUnit,
      cfPromotionDiscountAmount,
      cfPromotionDiscountUnit,
      voucherDiscount,
      voucherDiscountUnit,
      dfVoucherDiscountAmount,
      dfVoucherDiscountUnit,
      cfVoucherDiscountAmount,
      cfVoucherDiscountUnit,
      voucher,
      voucherListId,
      ...dataOfOperative } = operative
    const {
      qty: qtyCurrent,
      dfAmountNet: dfAmountNetCurrent,
      cfAmountNet: cfAmountNetCurrent,
      total: totalCurrent,
      dfRemark: dfRemarkCurrent,
      promotion: promotionsCurrent,
      promotionDiscount: promotionDiscountCurrent,
      promotionDiscountUnit: promotionDiscountUnitCurrent,
      dfPromotionDiscountAmount: dfPromotionDiscountAmountCurrent,
      dfPromotionDiscountUnit: dfPromotionDiscountUnitCurrent,
      cfPromotionDiscountAmount: cfPromotionDiscountAmountCurrent,
      cfPromotionDiscountUnit: cfPromotionDiscountUnitCurrent,
      voucherDiscount: voucherDiscountCurrent,
      voucherDiscountUnit: voucherDiscountUnitCurrent,
      dfVoucherDiscountAmount: dfVoucherDiscountAmountCurrent,
      dfVoucherDiscountUnit: dfVoucherDiscountUnitCurrent,
      cfVoucherDiscountAmount: cfVoucherDiscountAmountCurrent,
      cfVoucherDiscountUnit: cfVoucherDiscountUnitCurrent,
      voucher: voucherCurrent,
      voucherListId: voucherListIdCurrent,
      ...dataOfOperativeCurrent
    } = operativeCurrent;
    return JSON.stringify(dataOfOperative) !== JSON.stringify(dataOfOperativeCurrent)
  }

  const onCheckPromotionCurrent = (data: any) => {
    const promotionCurrentId = promotionOperativeCurrent?.find((item: any) => item?.isMain === '1')?.promotionBranchId
    const promotionId = promotionOperative?.find((item: any) => item?.isMain === '1')?.promotionBranchId
    if (promotionCurrentId && promotionCurrentId !== promotionId) {
      const newData: any[] = []
      data.forEach((item: any) => {
        if (item?.promotion?.length && item?.promotion?.some((info: any) => info?.promotionBranchId === promotionCurrentId)) {
          if (item?.promotion?.some((info: any) => info?.promotionType !== "EXTRA")) {
            return newData.push({ ...item, promotion: item?.promotion?.filter((i: any) => i?.promotionBranchId !== promotionCurrentId) })
          }
        } else {
          return newData.push(item)
        }
      })
      return newData
    } else {
      return data
    }
  }

  const handleSubmit = async () => {
    setDisabledSave(true)
    const newLocation: any = location
    const checkSave = (newLocation?.pathname === '/payment/create' || newLocation?.pathname === '/treatments')
    if ((!operative.price && operative.price !== 0) || !operative.qty) {
      setDisabledSave(false)
      return setErrorMessage({ PRICE: !operative.price ? t('TREATMENT_SUMMARY.POPUP.TREATMENT_COURSE.ERROR.EMPTY_PRICE') : '', QTY: !operative.qty ? t('TREATMENT_SUMMARY.POPUP.TREATMENT_COURSE.ERROR.EMPTY_QTY') : '' })
    }
    const { dataTreatmentsPromotions: newOperatives, errorExtra, newBuy, newExtra, errorBuy } = await onPromotionSave({
      patient: props?.patient,
      promotionDelId,
      promotionExtraBuy,
      promotionExtraNew,
      promotionExtraValue,
      dataPromotions: promotionLoadClear && operative,
      treatments,
      user,
      t: t
    })

    if (errorExtra || errorBuy) {
      setDisabledSave(false)
      return
    }

    const operatives = [...newOperatives]

    if (props.itemId || props.itemId === 0) operatives[props.itemId] = {
      ...operative,
      isReload: promotionLoadClear || voucherLoadClear,
      promotion: promotionOperative,
      voucher: voucherOperative,
      voucherListId: voucherOperative?.voucherListId || 0
    }
    if (checkSave && (props.itemId || props.itemId === 0) && handleCheckApproval() && approvalRight?.hasChangeCost) {
      operatives[props.itemId] = {
        ...operatives[props.itemId],
        isChangeCost: "1",
        bodyData: JSON.stringify(operativeCurrent),
        newBodyData: JSON.stringify(operative),
        requestReason: "",
        isReload: promotionLoadClear || voucherLoadClear
      }
      swalCustom(
        t('APPROVELLIST.FILTER.PENDING'),
        `<div style="width: 350px"> ${t('APPROVELLIST.MESSAGE.PENDING_')}  </div>`,
        (res: any) => console.log(),
        'warning',
        {
          confirmButton: { showConfirmButton: false },
          cancelButton: { cancelButtonText: t('BUTTON.ENTER') },
          showCloseButton: false,
          containerClass: 'swal-change-cost'
        }
      )
      const newTreatment = onCheckPromotionCurrent(operatives)
      dispatch(setTreatmentByKey({ key: 'summeryTreatments', value: [...newTreatment, ...newExtra, ...newBuy] }))
      dispatch(modalSlice.resetModal())
      setDisabledSave(false)
    } else {
      const newTreatment = onCheckPromotionCurrent(operatives)
      dispatch(setTreatmentByKey({ key: 'summeryTreatments', value: [...newTreatment, ...newExtra, ...newBuy] }))
      dispatch(modalSlice.resetModal())
      setDisabledSave(false)
    }
  }

  const handleChange = (e: any) => {
    const name = e.target.name
    setVoucherOperative({})
    setSearchVoucher("")
    const checkNaN = () => {
      if (!isNaN(Number(String(e.target.value).replace(/[^0-9.]/g, '')))) {
        if (name === 'qty') {
          return Number(String(e.target.value).replace(/[^0-9.]/g, ''))
        }
        return String(e.target.value).replace(/[^0-9.]/g, '')
      } else {
        return Number('')
      }
    }
    const value = name === 'price' || name === 'qty' || name === 'discount' || name === 'additional' || name === 'dfDiscountAmount' || name === 'cfDiscountAmount' ? checkNaN() : e.target.value

    name === 'qty' && setPromotionOperative(promotionOperative?.filter((item: any) => item?.isMain !== "1"))

    setErrorMessage({ PRICE: '', QTY: '' })
    if (
      (name === 'discount' && operative.discountUnit === CURRENCY_UNIT.BAHT) ||
      (name === 'additional' && operative.additionalUnit === CURRENCY_UNIT.BAHT) ||
      (name === 'dfDiscountAmount' && operative.dfDiscountUnit === CURRENCY_UNIT.BAHT) ||
      (name === 'cfDiscountAmount' && operative.cfDiscountUnit === CURRENCY_UNIT.BAHT)
    ) {
      return handleChangeDiscountPriceCurrency(name, value)
    } else if (
      (name === 'discount' && operative.discountUnit === CURRENCY_UNIT.PERCENT) ||
      (name === 'additional' && operative.additionalUnit === CURRENCY_UNIT.PERCENT) ||
      (name === 'dfDiscountAmount' && operative.dfDiscountUnit === CURRENCY_UNIT.PERCENT) ||
      (name === 'cfDiscountAmount' && operative.cfDiscountUnit === CURRENCY_UNIT.PERCENT)
    ) {
      return handleChangeDiscountPercent(name, value)
    } else if ((name === 'discountUnit' || name === 'additionalUnit' || name === 'dfDiscountUnit' || name === 'cfDiscountUnit') && value === CURRENCY_UNIT.PERCENT) {
      return handleChangeDiscountUnitToPercent(name, value)
    } else setOperative({ ...operative, [name]: e.target.type === 'number' && value < 0 && value !== '' ? (name === 'qty' && 1) || 0 : value, })
  }

  const handleChangeDiscountPriceCurrency = (name: 'discount' | 'additional' | 'dfDiscountAmount' | 'cfDiscountAmount', val: number | string) => {
    const preValue = String(val).replace(/[^0-9.]/g, '') || ''
    const checkNaN = () => {
      if (!isNaN(Number(preValue))) {
        return preValue
      } else {
        return Number('')
      }
    }
    const value = preValue !== '' ? checkNaN() : Number('')
    let obj = {}
    if (name === 'discount') {
      const additional = operative?.additional || 0
      const total = operative?.price * operative?.qty + additional || 0
      obj = { discount: value >= total ? total : value }
    } else if (name === 'dfDiscountAmount') {
      obj = { [name]: Number(value) >= df ? df : value }
    } else if (name === 'cfDiscountAmount') {
      obj = { [name]: Number(value) >= cf ? cf > 0 ? cf : 0 : value }
    } else obj = { [name]: value }

    setOperative({ ...operative, ...obj })
  }

  const handleChangeDiscountPercent = (name: 'discount' | 'additional' | 'dfDiscountAmount' | 'cfDiscountAmount', val: number | string) => {
    const preValue = String(val).replace(/[^0-9.]/g, '') || ''
    const value = preValue !== '' ? preValue : Number('')
    setOperative({ ...operative, [name]: Number(value) >= 100 ? 100 : value })
  }

  const handleChangeDiscountUnitToPercent = (name: 'discountUnit' | 'additionalUnit' | 'dfDiscountUnit' | 'cfDiscountUnit', value: CURRENCY_UNIT.PERCENT) => {
    let obj = {}
    if (name === 'discountUnit') obj = { [name]: value, discount: operative?.discount >= 100 ? 100 : operative?.discount }
    else if (name === 'dfDiscountUnit') obj = { [name]: value, dfDiscountAmount: operative?.dfDiscountAmount >= 100 ? 100 : operative?.dfDiscountAmount }
    else if (name === 'cfDiscountUnit') obj = { [name]: value, cfDiscountAmount: operative?.cfDiscountAmount >= 100 ? 100 : operative?.cfDiscountAmount }
    else obj = { [name]: value, additional: operative?.additional >= 100 ? 100 : operative?.additional }

    setOperative({ ...operative, ...obj })
  }

  useEffect(() => {
    setErrorSearchVoucher("")
    setDisabled(true)
    const price = Number(operative.price)
    const qty = Number(operative.qty)
    const newNormalPrice = price * qty
    setNormalPrice(newNormalPrice)

    const rawDiscount = Number(operative.discount)
    const rawDiscountUnit = operative.discountUnit
    const rawAdditional = Number(operative.additional)
    const rawAdditionalUnit = operative.additionalUnit
    let rawDiscountAmount = 0
    let rawAdditionalAmount = 0
    if (rawDiscountUnit === CURRENCY_UNIT.PERCENT) rawDiscountAmount = (newNormalPrice * rawDiscount) / 100
    if (rawDiscountUnit === CURRENCY_UNIT.BAHT) rawDiscountAmount = rawDiscount
    if (rawAdditionalUnit === CURRENCY_UNIT.PERCENT) rawAdditionalAmount = (newNormalPrice * rawAdditional) / 100
    if (rawAdditionalUnit === CURRENCY_UNIT.BAHT) rawAdditionalAmount = rawAdditional

    const newAmount = newNormalPrice - rawDiscountAmount + rawAdditionalAmount
    setAmount(newAmount)
    setDiscountAmount(rawDiscountAmount)

    let newDF = 0
    let newCF = 0
    let total = newAmount
    let dfAmount = doctorFee.dfAmount;
    let cfAmount = doctorFee.cfAmount
    let doctorFeeAmount = doctorFee.dfAmount
    let clinicFeeAmount = doctorFee.clinicFeeAmount
    let dfUnit = doctorFee.dfUnit
    let dfAmounNet = doctorFee.dfAmounNet
    if (price === 0 && doctorFee.dfUnit !== CURRENCY_UNIT.PERCENT) {
      const sumDf = doctorFee.dfAmount
      newDF = doctorFee.dfAmount * qty
      newCF = 0
      setDf(sumDf * qty)
      setCf(0)

      dfAmounNet = sumDf * qty
      dfAmount = sumDf
      cfAmount = 0
      doctorFeeAmount = sumDf
      clinicFeeAmount = 0
      dfUnit = doctorFee.dfUnit
    } else if (doctorFee.dfUnit === CURRENCY_UNIT.PERCENT) {
      const sumDf = (newAmount / 100) * doctorFee.dfAmount
      const sumCf = (newAmount / 100) * doctorFee.cfAmount
      newDF = sumDf
      newCF = sumCf

      setDf(sumDf)
      setCf(sumCf)

      dfAmount = doctorFee.dfAmount
      cfAmount = doctorFee.cfAmount
      doctorFeeAmount = doctorFee.dfAmount
      clinicFeeAmount = doctorFee.cfAmount
      dfUnit = doctorFee.dfUnit
    } else {
      newDF = doctorFee.dfAmount * qty
      newCF = newAmount - (doctorFee.dfAmount * qty)

      setDf(newDF)
      setCf(newAmount - (doctorFee.dfAmount * qty))

      dfAmount = doctorFee.dfAmount
      cfAmount = doctorFee.cfAmount
      doctorFeeAmount = doctorFee.dfAmount
      clinicFeeAmount = doctorFee.cfAmount
      dfUnit = doctorFee.dfUnit
    }


    let rawDfDiscountAmount = 0
    let rawCfDiscountAmount = 0

    if (operative.dfDiscountUnit === CURRENCY_UNIT.PERCENT) {
      rawDfDiscountAmount = (Number(newDF) * Number(operative.dfDiscountAmount)) / 100
      setDfDiscountAmount(rawDfDiscountAmount)
    } else if (operative.dfDiscountUnit === CURRENCY_UNIT.BAHT) {
      rawDfDiscountAmount = Number(operative.dfDiscountAmount)
      setDfDiscountAmount(rawDfDiscountAmount)
    }

    if (operative.cfDiscountUnit === CURRENCY_UNIT.PERCENT) {
      rawCfDiscountAmount = (Number(newCF) * Number(operative.cfDiscountAmount)) / 100
      setCfDiscountAmount(rawCfDiscountAmount)
    } else if (operative.cfDiscountUnit === CURRENCY_UNIT.BAHT) {
      rawCfDiscountAmount = Number(operative.cfDiscountAmount)
      setCfDiscountAmount(Number(operative.cfDiscountAmount))
    }

    const dfAmountNet = newDF - rawDfDiscountAmount
    const cfAmountNet = newCF - rawCfDiscountAmount

    const checkPromotion = (promotionOperative?.length && promotionBranchMain?.promotionBranchId && promotionBranchMain?.type === 'DISCOUNT')
    const promotionDiscount = {
      promotionDiscount: checkPromotion ? promotionBranchMain?.promotionPriceType === "DISCOUNT" ? promotionBranchMain?.promotionPrice : newAmount - promotionBranchMain?.promotionPrice : 0,
      promotionDiscountUnit: checkPromotion ? promotionBranchMain?.promotionPriceUnit === "BATH" || promotionBranchMain?.promotionPriceUnit === "BAHT" ? CURRENCY_UNIT.BAHT : CURRENCY_UNIT.PERCENT : CURRENCY_UNIT.BAHT,
      dfPromotionDiscountAmount: checkPromotion && (promotionBranchMain?.promotionUsage === 'OPERATIVEDF' || promotionBranchMain?.promotionUsage === 'OPERATIVE') ? calPromotionDFCF(dfAmountNet, newAmount, promotionBranchMain) : 0,
      dfPromotionDiscountUnit: checkPromotion ? promotionBranchMain?.promotionPriceUnit === "BATH" || promotionBranchMain?.promotionPriceUnit === "BAHT" ? CURRENCY_UNIT.BAHT : CURRENCY_UNIT.PERCENT : CURRENCY_UNIT.BAHT,
      cfPromotionDiscountAmount: checkPromotion && (promotionBranchMain?.promotionUsage === 'OPERATIVECF' || promotionBranchMain?.promotionUsage === 'OPERATIVE') ? calPromotionDFCF(cfAmountNet, newAmount, promotionBranchMain) : 0,
      cfPromotionDiscountUnit: checkPromotion ? promotionBranchMain?.promotionPriceUnit === "BATH" || promotionBranchMain?.promotionPriceUnit === "BAHT" ? CURRENCY_UNIT.BAHT : CURRENCY_UNIT.PERCENT : CURRENCY_UNIT.BAHT,
    }

    const rawPromotionDf = promotionDiscount?.dfPromotionDiscountAmount || 0
    const rawPromotionCf = promotionDiscount?.cfPromotionDiscountAmount || 0

    const checkVoucher = voucherOperative?.voucherId ? true : false

    const voucherDiscount = {
      voucherDiscount: checkVoucher ? voucherOperative?.voucherPriceType === "DISCOUNT" ? voucherOperative?.voucherPrice : newAmount - voucherOperative?.voucherPrice : 0,
      voucherDiscountUnit: checkVoucher ? voucherOperative?.voucherPriceUnit === "BATH" || voucherOperative?.voucherPriceUnit === "BAHT" ? CURRENCY_UNIT.BAHT : CURRENCY_UNIT.PERCENT : CURRENCY_UNIT.BAHT,
      dfVoucherDiscountAmount: checkVoucher && (voucherOperative?.voucherUsage === 'OPERATIVEDF' || voucherOperative?.voucherUsage === 'OPERATIVE') ? calPromotionDFCF(dfAmountNet, newAmount, voucherOperative) : 0,
      dfVoucherDiscountUnit: checkVoucher ? voucherOperative?.voucherPriceUnit === "BATH" || voucherOperative?.voucherPriceUnit === "BAHT" ? CURRENCY_UNIT.BAHT : CURRENCY_UNIT.PERCENT : CURRENCY_UNIT.BAHT,
      cfVoucherDiscountAmount: checkVoucher && (voucherOperative?.voucherUsage === 'OPERATIVECF' || voucherOperative?.voucherUsage === 'OPERATIVE') ? calPromotionDFCF(cfAmountNet, newAmount, voucherOperative) : 0,
      cfVoucherDiscountUnit: checkVoucher ? voucherOperative?.voucherPriceUnit === "BATH" || voucherOperative?.voucherPriceUnit === "BAHT" ? CURRENCY_UNIT.BAHT : CURRENCY_UNIT.PERCENT : CURRENCY_UNIT.BAHT,
    }

    const rawVoucherDf = voucherDiscount?.dfVoucherDiscountAmount || 0
    const rawVoucherCf = voucherDiscount?.cfVoucherDiscountAmount || 0

    if (price === 0 && doctorFee.dfUnit !== CURRENCY_UNIT.PERCENT) {
      total = newAmount - ((rawPromotionDf + rawPromotionCf) + (rawVoucherDf + rawVoucherCf))
    } else {
      total = (newAmount - rawDfDiscountAmount - rawCfDiscountAmount) - ((rawPromotionDf + rawPromotionCf) + (rawVoucherDf + rawVoucherCf))
    }

    const newOp = {
      ...operative,
      total: total >= 0 ? total : 0,
      dfAmountNet: dfAmountNet,
      cfAmountNet: cfAmountNet,
      dfAmount,
      cfAmount,
      doctorFeeAmount,
      clinicFeeAmount,
      dfUnit,
      dfAmounNet,
      ...promotionDiscount,
      ...voucherDiscount
    }

    setOperative(newOp)

    setDisabled(false)
  }, [operative.price, operative.qty, operative.discount, operative.discountUnit, operative.additional, operative.additionalUnit, doctorFee, operative.dfDiscountAmount, operative.dfDiscountUnit, operative.cfDiscountAmount, operative.cfDiscountUnit, promotionBranchMain, promotionOperative, voucherOperative])

  // useEffect(() => {
  //   const rawDfAmount = Number(df)
  //   const rawDfDiscountAmount = Number(dfDiscountAmount)
  //   const rawCfAmount = Number(cf)
  //   const rawCfDiscountAmount = Number(cfDiscountAmount)
  //   const dfAmountNet = rawDfAmount - rawDfDiscountAmount
  //   const cfAmountNet = rawCfAmount - rawCfDiscountAmount
  //   setOperative({ ...operative, dfAmountNet: dfAmountNet, cfAmountNet: cfAmountNet })
  // }, [df, cf, dfDiscountAmount, cfDiscountAmount, amount])

  useEffect(() => {
    if (!operative.price || operative.price === 0) {
      const newData = { ...operative, discount: 0 }
      setOperative(newData)
    }
  }, [operative.price])

  useEffect(() => {
    if ((modal || props?.noModal) && props.userId) {
      setIsDisabledChange(props.operative?.approvalItems?.status === ApprovalStatus.PENDING)
      setOperativeCurrent(props.operative)
      setOperative(props.operative)
      setPromotionOperative(props.operative?.promotion)
      setPromotionProductCurrent(props.operative?.promotion)
      setVoucherOperative(props?.operative?.voucher)
      setSearchVoucher(props?.operative?.voucher?.voucherCode)
      loadDoctorFee(props.userId, props.operative?.operativeId)
      loadOperativeById(props.operative?.operativeId)
    } else props.onReset && props.onReset()
  }, [modal])

  const handleBlurInput = () => {
    if (operative[isFocus]) {
      const [num, numDot] = operative[isFocus].toString().split('.')
      if (numDot && numDot.length === 1) {
        operative[isFocus] = `${operative[isFocus]}0`
      }
    }
    setIsFocus('')
  }

  const onClerPopPromotionExtra = () => {
    // setPromotionExtraBuy([])
    setPromotionExtra([])
    setPopPromotionExtra(false)
  }

  const onChangePromotionExtra = (data: any, dataBuy?: any) => {
    setPromotionLoadClear(true)
    if (promotionExtraValue?.promotionBranchId && (dataBuy[0]?.groupNumber || promotionExtraBuy[0]?.groupNumber)) {
      onClerPopPromotionExtra()
      if (dataBuy?.length) {
        setPromotionExtraBuy(dataBuy)
      }
      setPromotionExtraNew(data)
      const newOpPromotion = promotionOperative?.length ? promotionOperative?.filter((item: any) => item?.isMain !== "1") : []
      setPromotionOperative([
        ...newOpPromotion,
        {
          promotionBranchId: promotionExtraValue?.promotionBranchId,
          promotionType: "BUY",
          promotionId: promotionExtraValue?.promotionId,
          isMain: '1',
          groupNumber: dataBuy[0]?.groupNumber || promotionExtraBuy[0]?.groupNumber
        }
      ])
    }
  }

  const handleChangePromotionProduct = (value: any) => {
    const opCId = promotionOperativeCurrent?.find((item: any) => item?.isMain === '1')?.promotionBranchId || 0
    setPromotionDelId(opCId)
    setPromotionLoadClear(true)
    if (value?.promotionBranchId) {
      if (value?.type === "EXTRA" && value?.extra?.length) {
        const newBuy: any[] = []
        const newExtra: any[] = []
        value?.extra.forEach((item: any) => {
          if (item?.type === "BUY") {
            const buyInfo = newBuy[item?.groupNumber - 1] || []
            newBuy[item?.groupNumber - 1] = [...buyInfo, item]
          } else {
            const extraInfo = newExtra[item?.groupNumber - 1] || []
            newExtra[item?.groupNumber - 1] = [...extraInfo, item]
          }
        })

        const onChangeTreatmentSum = (num: number) => {
          const newData = [...treatments?.summeryTreatments]
          newData[num] = operative
          return newData
        }

        const treatmentSum = treatments?.summeryTreatments?.findIndex((item: any) => item?.itemId === operative?.operativeId && (!item?.promotion?.length || item?.promotion?.length && item?.promotion?.every((info: any) => info?.promotionType !== "EXTRA")))
        const treatmentSummerys = treatmentSum < 0 ? [...treatments?.summeryTreatments, operative] : onChangeTreatmentSum(treatmentSum)

        let autoPromotionBuy = null

        const newTreatmentSummery = treatmentSummerys?.length ? treatmentSummerys : [operative]
        if (newBuy?.length) {
          const treatmentSummerysCanUse = newTreatmentSummery.filter((item: any) => {
            if ((item?.promotion?.length && item?.promotion.some((info: any) => info?.promotionType !== "EXTRA")) || !item?.promotion?.length) {
              return item
            }
          }).map((item: any) => { return { itemId: item?.operativeId || item?.itemId, qty: item.qty } })
          const dataBuyCanUse = newBuy.find((info: any) => info.every((i: any) => {
            return treatmentSummerysCanUse.some((t: any) => {
              return t?.itemId === i?.itemId && t?.qty === i?.qty
            })
          }))
          if (dataBuyCanUse) {
            autoPromotionBuy = dataBuyCanUse
            setPromotionExtraBuy(dataBuyCanUse)
          } else {
            setPromotionBuy(newBuy?.filter((item: any) => item.some((info: any) => info?.itemId === operative?.operativeId))
              .map((item: any) => item.map((info: any) => { return { ...info, disabled: !treatmentSummerysCanUse.some((t: any) => t?.itemId === info?.itemId && t?.qty === info?.qty) } })) || [])
          }
        }

        if (autoPromotionBuy && autoPromotionBuy[0]?.groupNumber && newExtra?.length === 1) {
          setPromotionExtraValue(value)
          setPromotionExtraNew(newExtra[0])
          const newOpPromotion = promotionOperative?.length ? promotionOperative?.filter((item: any) => item?.isMain !== "1") : []
          setPromotionOperative([
            ...newOpPromotion,
            {
              promotionBranchId: value?.promotionBranchId,
              promotionType: "BUY",
              promotionId: value?.promotionId,
              isMain: '1',
              groupNumber: autoPromotionBuy[0]?.groupNumber
            }
          ])
        } else {
          setPromotionExtraValue(value)
          setPromotionExtra(newExtra)
          setPopPromotionExtra(true)
        }
      } else {
        setPromotionExtraNew([])
        setPromotionExtraBuy([])
        setPromotionBuy([])
        setPromotionExtraValue({})
        const newOpPromotion = promotionOperative?.length ? promotionOperative?.filter((item: any) => item?.isMain !== "1") : []
        setPromotionOperative([
          ...newOpPromotion,
          {
            promotionBranchId: value?.promotionBranchId,
            promotionType: value?.type,
            promotionId: value?.promotionId,
            isMain: '1'
          }
        ])
      }

    } else {
      setPromotionExtraNew([])
      setPromotionExtraBuy([])
      setPromotionBuy([])
      setPromotionExtraValue({})
      const newOpPromotion = promotionOperative?.length ? promotionOperative?.filter((item: any) => item?.isMain !== "1") : []
      setPromotionOperative([...newOpPromotion])
    }
  }

  const renderPromotionOption = useCallback(() => {
    if (promotions?.length && operative?.operativeId) {
      const newOption = promotions.find((item: any) => item?.itemId === operative?.operativeId)?.promotions || []
      return newOption
    }
    return []
  }, [promotions, operative?.operativeId])

  const renderDetailListExtra = (data: any, index?: number) => {

    if (data?.length) {
      const newText = data.map((item: any, index: number) => {
        return `${item?.itemName || ''} ${item?.qty || 0} ${item?.itemUnitName || ''}`
      })

      return newText.join(', ')
    }

    if (promotionCurrent?.length && (index || index === 0) && promotionCurrent[index]) {

      const newText: string[] = []
      if (promotionCurrent[index]?.type === "EXTRA" && promotionOperative?.length) {
        const promotionItem = treatments?.summeryTreatments.find((item: any) => item?.promotion?.some((info: any) => info?.promotionType === 'EXTRA' && info?.promotionBranchId === promotionCurrent[index]?.promotionBranchId))?.promotion?.find((item: any) => item?.promotionBranchId === promotionCurrent[index]?.promotionBranchId)
        if (promotionItem?.groupNumber) {
          promotionCurrent[index]?.extra.forEach((item: any) => {
            if (item?.type === "EXTRA" && promotionItem?.groupNumber === item?.groupNumber) {
              newText.push(item?.itemName)
            }
          })
          return newText.join(', ')
        }
      }
    }
    return <></>
  }

  const onOpenPopVoucherSelectLot = (item: any) => {
    setDataPopVoucherSelectLot(item)
    setPopVoucherSelectLot(true)
  }

  const onClerPopVoucherSelectLot = () => {
    setDataPopVoucherSelectLot({})
    setPopVoucherSelectLot(false)
  }

  const handleVoucherSelect = (item?: any) => {
    setVoucherLoadClear(true)
    setSearchVoucher(item?.voucherCode || '')
    if (item?.voucherListId) {
      setVoucherOperative({ ...dataPopVoucherSelectLot, ...item })
      onClerPopVoucherSelectLot()
    } else {
      setVoucherOperative({})
    }
  }



  const onLoadVoucher = async () => {
    if (searchVoucher && searchVoucher !== '' && voucherOperative?.voucherCode !== searchVoucher) {
      const patientId = treatments?.summeryTreatments?.find((item: any) => item?.patientId)?.patientId || 0
      const newTreatment = [...treatments?.summeryTreatments?.filter((item: any) => item?.operativeId !== operative?.operativeId && (!item?.promotion?.length || item?.promotion.every((info: any) => info?.promotionType !== 'EXTRA'))), operative]
      const condition: VoucherFindByCodeProps = {
        code: searchVoucher,
        itemType: "OPERATIVE",
        itemId: operative?.operativeId || 0,
        qty: operative?.qty,
        min: _.sumBy(newTreatment.map((item: any) => {
          if (item?.approvalItems?.status === ApprovalStatus.PENDING) {
            const newData = JSON.parse(item?.approvalItems?.body)
            return { ...item, total: newData?.total }
          } else {
            return { ...item }
          }
        }), 'total'),
        patientId: props?.patient?.patientId || patientId
      }
      await VoucherApi.findVoucherByCode(condition).then((res) => {
        handleVoucherSelect(res?.data[0])
      }).catch((err) => {
        const error = err?.response?.data
        if (error.statusCode === 404) {
          if (error.message === "NOT_FOUND_VOUCHER_CODE") {
            setErrorSearchVoucher(t(`PAYMENT.MESSAGE.ERROR.${error.message}`))
          } else {
            notiError(t(`VOUCHER.POP_PRODUCT.MESSAGE.ERROR.${error.message}`))
          }
        } else {
          notiError(t(`VOUCHER.POP_PRODUCT.MESSAGE.ERROR.ERROR`))
        }
      })
    }
  }

  const renderVoucher = () => {
    if (voucherOperative?.voucherId) {
      return (
        <Box width={"100%"}>
          <Box display={'flex'} alignItems={'center'} flexWrap={'wrap'} marginBottom={'8px'}>
            <Box width={'140px'}>
              {t('VOUCHER.POP_PRODUCT.FORM.VOUCHER_NAME')}
            </Box>
            <Box display={'flex'} alignItems={'center'} gap={'8px'}>
              <Box display={'flex'} alignItems={'center'} gap={'8px'}>
                {voucherOperative?.voucherName}
                {voucherOperative?.detail &&
                  (
                    <span>
                      <Tooltip title={voucherOperative?.detail} placement={'top-start'} sx={{ 'path': { fill: '#A8A8A8' }, }}>
                        <InfoIcon />
                      </Tooltip>
                    </span>
                  )
                }
              </Box>
            </Box>

          </Box>
          <Box display={'flex'} alignItems={'center'} flexWrap={'wrap'}>
            <Box width={'140px'}>
              {t('VOUCHER.POP_PRODUCT.FORM.VOUCHER_COST')}
            </Box>
            <Box display={'flex'} alignItems={'center'} gap={'8px'}>
              <Box display={'flex'} alignItems={'center'} gap={'8px'}>
                {voucherOperative?.voucherPrice}
                {voucherOperative?.voucherPriceUnit === "PERCENT" ?
                  <Box>
                    {'%'}
                  </Box>
                  : <Box>
                    {t('PROMOTION.FORM.BATH')}
                  </Box>
                }
              </Box>
            </Box>

          </Box>
        </Box>
      )
    } else if (vouchers?.length) {
      return (
        <Box width={"100%"}>
          {vouchers.map((item: any, index: number) => {
            return (
              <Box key={index} display={'flex'} alignItems={'center'} flexWrap={'wrap'}>
                <Box width={'140px'}>
                  {t('VOUCHER.POP_PRODUCT.FORM.VOUCHER_NAME')}
                </Box>
                <Box display={'flex'} alignItems={'center'} gap={'8px'}>
                  <Box display={'flex'} alignItems={'center'} gap={'8px'}>
                    {item?.voucherName}
                    {item?.detail &&
                      (
                        <span>
                          <Tooltip title={item?.detail} placement={'top-start'} sx={{ 'path': { fill: '#A8A8A8' }, }}>
                            <InfoIcon />
                          </Tooltip>
                        </span>
                      )
                    }
                  </Box>
                  <Box
                    sx={{ textDecoration: 'underline', color: colors.themeSecondColor, cursor: 'pointer', width: 'fit-content' }}
                    onClick={() => onOpenPopVoucherSelectLot(item)}
                  >
                    {t('VOUCHER.POP_PRODUCT.FORM.VOUCHER_SELECT_INDEX', { index: item?.voucherDetails?.length || 0 })}
                  </Box>
                </Box>

              </Box>
            )
          })}
        </Box>
      )
    } else {
      return (
        <Box width={"100%"}>
          <Box width={"100%"} alignContent={'center'} textAlign={'center'} color={'#808080'}>
            {t('VOUCHER.POP_PRODUCT.FORM.VOUCHER_NONE')}
          </Box>
        </Box>
      )
    }
  }

  const renderCheckCurrentUsePromtion = () => {
    const promotionAll: number[] = []
    treatments?.summeryTreatments?.forEach((item: any) => {
      if (item?.promotion?.length && item?.promotion?.some((info: any) => info.isMain === '1' && info?.promotionBranchId !== promotionDelId)) {
        const newItem = item?.promotion?.find((info: any) => info.isMain === '1')
        promotionAll.push(newItem?.promotionBranchId)
      }
    })
    return promotionAll
  }

  const renderBody = () => {
    return (
      <>
        <Box className="custom-scroll" sx={{ overflowY: 'auto' }}>
          <Grid container spacing={2}>
            <Grid container item xs={12} className={'w-100 align-items-center'}>
              <Grid item xs={!approval && !outToken ? 6 : 12} className={`text-nowrap d-flex align-items-center`}>
                <Typography className="d-sm-inline-block" sx={{ fontWeight: 500 }}>
                  {t('TREATMENT_SUMMARY.POPUP.TREATMENT_COURSE.OPERATIVE_ID')}: {operative.operativeCode || '-'}
                </Typography>
                <Typography className="d-sm-inline-block pl-sm-3 text-ellipsis" sx={{ fontWeight: 500 }}>
                  {t('TREATMENT_SUMMARY.POPUP.TREATMENT_COURSE.OPERATIVE_NAME')}: {operative.operativeName || operative.operativeNameEn || '-'}
                </Typography>
              </Grid>
              {(!approval && approvalRight?.hasChangeCost && (props.itemId || props.itemId === 0) && Object.values(ApprovalStatus).includes(treatments.summeryTreatments[props.itemId]?.approvalItems?.status)) ?
                <Grid item xs={6}>
                  <Box className={`d-flex justify-content-end align-items-center`}>
                    <ApprovalSelectorCustom
                      approval={treatments.summeryTreatments[props.itemId]?.approvalItems?.status as ApprovalStatus}
                      disabled
                      userApprovel={{
                        fullname: treatments.summeryTreatments[props.itemId]?.approvalItems?.approvedBy,
                        date: treatments.summeryTreatments[props.itemId]?.approvalItems?.approvedAt
                      }}
                    />
                  </Box>
                </Grid>
                : <></>
              }
            </Grid>
            <Grid item xs={6} className={'w-100'}>
              <InputTextField
                name="price"
                onchange={handleChange}
                value={operative.price}
                onfocus={() => setIsFocus('price')}
                onblur={() => handleBlurInput()}
                label={`${t('TREATMENT_SUMMARY.POPUP.TREATMENT_COURSE.PRICE_OF_UNIT')} (${t('CURRENCY_CHANGE.TH')})`}
                inputTextAlign="left"
                hideControl
                helperText={errorMessage.PRICE || ''}
                disabled={props?.noModal || isDisabledChange}
              />
            </Grid>
            <Grid item xs={6} className={'w-100'}>
              <InputTextField disabled={props?.noModal || isDisabledChange} name="qty" onchange={handleChange} value={operative.qty} label={`${t('TREATMENT_SUMMARY.POPUP.TREATMENT_COURSE.QUANTITY_OF_UNIT')}`} inputTextAlign="left" hideControl helperText={errorMessage.QTY || ''} />
            </Grid>
            <Grid item xs={12} className={'w-100'}>
              <InputTextField disabled name="treatment" value={numberFormat(normalPrice || 0)} label={`${t('TREATMENT_SUMMARY.POPUP.TREATMENT_COURSE.PRICE_OF_TREATMENT')} (${t('CURRENCY_CHANGE.TH')})`} inputTextAlign="left" hideControl />
            </Grid>

            {(!approval && isPromtion && getCrm()) &&
              <Grid container item xs={12} >
                <Grid item xs={12} md={6} paddingRight={{ md: '16px', xs: '' }} borderRight={{ md: `1px solid ${colors.lightGray}`, xd: '' }}>
                  <Box fontWeight={'500'}>
                    {t('PROMOTION.MODAL_MANAGE.PROMOTION')}
                  </Box>
                  <Box display={'flex'} flexDirection={'column'} gap={'16px'} marginTop={'16px'}>
                    <Box sx={{ '.MuiOutlinedInput-root': { paddingRight: '9px !important' }, '.MuiAutocomplete-popupIndicator': { transform: 'rotate(0deg) !important' } }}>
                      <AutocompleteSelect
                        labelId={'promotion'}
                        inputLabel={t('PROMOTION.FORM.PROMOTION_NAME')}
                        noOptionsText={t('NOT_FOUND')}
                        getOptionLabel={(option) => option?.promotionFullName || ''}
                        options={renderPromotionOption()}
                        popupIcon={<FontAwesomeIcon icon={faSearch} style={{ fontSize: 15 }} />}
                        renderOption={(props, option) => {
                          return (
                            <Box {...props}
                              aria-disabled={renderCheckCurrentUsePromtion().includes(option?.promotionBranchId)}
                            >
                              {option?.promotionName || ''}
                            </Box>
                          )
                        }}
                        onchange={(e, value) => handleChangePromotionProduct(value)}
                        value={promotionBranchMain?.promotionBranchId ? promotionBranchMain : ''}
                        disableClearable={!promotionBranchMain?.promotionBranchId}
                      />
                    </Box>
                    {(promotionBranchMain?.promotionBranchId) ?
                      <Box width={"100%"}>
                        <Box padding={'0 24px'} display={'flex'} flexDirection={'column'} gap={'8px'}>
                          <Box display={'flex'} alignItems={'center'} width={'100%'}>
                            <Box minWidth={'160px'}>
                              {t('PROMOTION.FORM.PROMOTION_NAME')}
                            </Box>
                            <Box className={'text-ellipsis'} width={'100%'} >
                              {promotionBranchMain?.promotionName}
                              {promotionBranchMain?.detail &&
                                (
                                  <span>
                                    <Tooltip title={promotionBranchMain?.detail} placement={'top-start'} sx={{ 'path': { fill: '#A8A8A8' }, }}>
                                      <InfoIcon />
                                    </Tooltip>
                                  </span>
                                )
                              }
                            </Box>
                          </Box>
                          <Box display={'flex'} alignItems={'center'} width={'100%'}>
                            <Box minWidth={'160px'}>
                              {t('PROMOTION.FORM.TYPE')}
                            </Box>
                            <Box className={'text-ellipsis'} width={'100%'} >
                              {promotionBranchMain?.type === 'DISCOUNT' ? t('PROMOTION.PROMOTION_TYPE.DISCOUNT') : t('PROMOTION.PROMOTION_TYPE.EXTRA')}
                            </Box>
                          </Box>
                          {promotionBranchMain?.type === 'DISCOUNT' ?
                            <Box display={'flex'} alignItems={'center'} width={'100%'}>
                              <Box minWidth={'160px'}>
                                {t('PROMOTION.FORM.COST')}
                              </Box>
                              <Box className={'text-ellipsis'} width={'100%'} >
                                {promotionBranchMain?.promotionPrice} {promotionBranchMain?.promotionPriceUnit === "PERCENT" ? "%" : t('PROMOTION.FORM.BATH')}
                              </Box>
                            </Box>
                            :
                            <Box display={'flex'} alignItems={'center'} width={'100%'}>
                              <Box minWidth={'160px'}>
                                {t('PROMOTION.MODAL_MANAGE.LIST_EXTRA')}
                              </Box>
                              <Box className={'text-ellipsis'} width={'100%'} title={renderDetailListExtra(promotionExtraNew)}>
                                {renderDetailListExtra(promotionExtraNew)}
                              </Box>
                            </Box>
                          }
                        </Box>
                      </Box>
                      : <Box width={"100%"} alignContent={'center'} textAlign={'center'} color={'#808080'}>
                        {t('PROMOTION.MODAL_MANAGE.NONE_PROMOTION')}
                      </Box>
                    }
                    {promotionCurrent?.length && promotionCurrent.filter((item: any) => item?.promotionBranchId !== promotionBranchMain?.promotionBranchId)?.length ?
                      <Box margin={'8px 0'}>
                        <Box>
                          {t('PROMOTION.MODAL_MANAGE.PROMOTION_BIND')}
                        </Box>
                        <Box width={"100%"}>
                          {promotionCurrent.filter((item: any) => item?.promotionBranchId !== promotionBranchMain?.promotionBranchId).map((item: any, index: number) => {
                            if (item?.promotionBranchId !== promotionBranchMain?.promotionBranchId) {
                              return (
                                <Box key={index} padding={'0 24px'} display={'flex'} flexDirection={'column'} gap={'8px'}>
                                  <Box display={'flex'} alignItems={'center'} width={'100%'}>
                                    <Box minWidth={'160px'}>
                                      {t('PROMOTION.FORM.PROMOTION_NAME')}
                                    </Box>
                                    <Box className={'text-ellipsis'} width={'100%'} >
                                      {item?.promotionName}
                                      {item?.detail &&
                                        (
                                          <span>
                                            <Tooltip title={item?.detail} placement={'top-start'} sx={{ 'path': { fill: '#A8A8A8' }, }}>
                                              <InfoIcon />
                                            </Tooltip>
                                          </span>
                                        )
                                      }
                                    </Box>
                                  </Box>
                                  <Box display={'flex'} alignItems={'center'} width={'100%'}>
                                    <Box minWidth={'160px'}>
                                      {t('PROMOTION.FORM.TYPE')}
                                    </Box>
                                    <Box className={'text-ellipsis'} width={'100%'} >
                                      {item?.type === 'DISCOUNT' ? t('PROMOTION.PROMOTION_TYPE.DISCOUNT') : t('PROMOTION.PROMOTION_TYPE.EXTRA')}
                                    </Box>
                                  </Box>
                                  {item?.type === 'DISCOUNT' ?
                                    <Box display={'flex'} alignItems={'center'} width={'100%'}>
                                      <Box minWidth={'160px'}>
                                        {t('PROMOTION.FORM.COST')}
                                      </Box>
                                      <Box className={'text-ellipsis'} width={'100%'} >
                                        {item?.promotionPrice} {item?.promotionPriceUnit === "PERCENT" ? "%" : t('PROMOTION.FORM.BATH')}
                                      </Box>
                                    </Box>
                                    :
                                    <Box display={'flex'} alignItems={'center'} width={'100%'}>
                                      <Box minWidth={'160px'}>
                                        {t('PROMOTION.MODAL_MANAGE.LIST_EXTRA')}
                                      </Box>
                                      <Box className={'text-ellipsis'} width={'100%'} title={renderDetailListExtra([], index)}>
                                        {renderDetailListExtra([], index)}
                                      </Box>
                                    </Box>
                                  }
                                </Box>
                              )
                            }
                          })}
                        </Box>

                      </Box>

                      : <></>}
                  </Box>
                </Grid>
                <Grid item xs={12} md={6} paddingLeft={{ md: '16px', xs: '' }}>
                  <Box fontWeight={'500'}>
                    Gift Voucher
                  </Box>
                  <Box display={'flex'} flexDirection={'column'} gap={'16px'} marginTop={'16px'}>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: '16px' }}>
                      <Box sx={{ width: '100%', '.MuiOutlinedInput-root': { paddingRight: '9px !important' }, '.MuiFormControl-root': { position: 'relative' }, '.MuiFormHelperText-root': { position: 'absolute', bottom: '-20px' } }}>
                        <InputTextField
                          label={t('VOUCHER.POP_PRODUCT.FORM.VOUCHER_CODE')}
                          onchange={(event) => {
                            setErrorSearchVoucher("")
                            setSearchVoucher(event.target.value)
                          }}
                          value={searchVoucher}
                          // onblur={() => setSearchVoucher(voucherOperative?.voucherCode || '')}
                          inputProps={{
                            endAdornment: (
                              <Box display={'flex'} alignItems={'center'} gap={'8px'}>
                                {voucherOperative?.voucherName &&
                                  <Box sx={{ cursor: 'pointer' }} onClick={handleVoucherSelect}>
                                    <IconClose />
                                  </Box>
                                }
                                <FontAwesomeIcon icon={faSearch} style={{ fontSize: 15 }} />
                              </Box>
                            ),
                          }}
                          helperText={errorSearchVoucher}
                        />
                      </Box>
                      <Box sx={{ minWidth: '100px', button: { width: '100%' } }}>
                        <MainButton
                          onClick={onLoadVoucher}
                          title={t('VOUCHER.POP_PRODUCT.FORM.VOUCHER_CHECKCODE')}
                          type={'primary'}
                          variant={'outlined'}
                        />
                      </Box>
                    </Box>
                    <Box padding={'16px 16px 0 16px'}>
                      {renderVoucher()}
                    </Box>

                  </Box>
                </Grid>
              </Grid>
            }

            <Grid item xs={12} className={'w-100'}>
              <Typography className="mt-2" sx={{ fontWeight: 500 }}>
                {t('TREATMENT_SUMMARY.POPUP.TREATMENT_COURSE.DISCOUNT_ADDON')}
              </Typography>
            </Grid>
            <Grid item xs={6} className={'w-100'}>
              <Grid container spacing={2}>
                <Grid item xs={6} sx={{ width: '50%' }}>
                  <InputTextField disabled={props?.noModal || isDisabledChange} name="discount" onchange={handleChange} value={operative.discount} label={`${t('TREATMENT_SUMMARY.POPUP.TREATMENT_COURSE.DISCOUNT')}`} inputTextAlign="left" hideControl />
                </Grid>
                <Grid item xs={6} sx={{ width: '50%' }}>
                  <BasicSelect
                    name="discountUnit"
                    labelId="lb-unit"
                    selectId="sl-unit"
                    label=""
                    value={operative.discountUnit || ''}
                    options={_.map(units, (unit: string, index: number) => {
                      return (
                        <MenuItem key={index} value={unit}>
                          {unit === CURRENCY_UNIT.PERCENT ? '%' : t('CURRENCY_CHANGE.TH')}
                        </MenuItem>
                      )
                    })}
                    onchange={handleChange}
                    disabled={props?.noModal || isDisabledChange}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6} className={'w-100'}>
              <Grid container spacing={2}>
                <Grid item xs={6} sx={{ width: '50%' }}>
                  <InputTextField disabled={props?.noModal || isDisabledChange} name="additional" onchange={handleChange} value={operative.additional} label={`${t('TREATMENT_SUMMARY.POPUP.TREATMENT_COURSE.ADDON')}`} inputTextAlign="left" hideControl />
                </Grid>
                <Grid item xs={6} sx={{ width: '50%' }}>
                  <BasicSelect
                    name="additionalUnit"
                    labelId="lb-unit"
                    selectId="sl-unit"
                    label=""
                    value={operative.additionalUnit || ''}
                    options={_.map(units, (unit: string, index: number) => {
                      return (
                        <MenuItem key={index} value={unit}>
                          {unit === CURRENCY_UNIT.PERCENT ? '%' : t('CURRENCY_CHANGE.TH')}
                        </MenuItem>
                      )
                    })}
                    onchange={handleChange}
                    disabled={props?.noModal || isDisabledChange}
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} className={'w-100'}>
              <InputTextField disabled name="total" value={numberFormat(amount || 0)} label={`${t('TREATMENT_SUMMARY.POPUP.TREATMENT_COURSE.TOTAL')}`} inputTextAlign="left" hideControl />
            </Grid>

            <Grid item xs={12} className={'w-100 mb-2'}>
              <Typography className="mt-2" sx={{ fontWeight: 500 }}>
                {t('TREATMENT_SUMMARY.POPUP.TREATMENT_COURSE.SALARY')}
              </Typography>
            </Grid>

            <Grid item xs={6} className={'w-100 pt-2 pr-sm-2 pr-xl-3'} sx={{ borderRight: { xs: `1px solid ${colors.lightGray}` } }}>
              <Grid container spacing={2}>
                <Grid item xs={12} className="w-100">
                  <InputTextField
                    disabled
                    name="df"
                    value={numberFormat(df || 0)}
                    label={`${t(`DF ${operative.dfAmount} ${operative.dfUnit === 'PERCENT' ? `%` : `${t('CURRENCY_CHANGE.TH')}`}`)}`}
                    inputTextAlign="left"
                    hideControl
                  />
                </Grid>
                <Grid item lg={4} sx={{ width: '50%' }}>
                  <InputTextField disabled={props?.noModal} name="dfDiscountAmount" onchange={handleChange} value={operative.dfDiscountAmount} label={`${t('TREATMENT_SUMMARY.POPUP.TREATMENT_COURSE.DISCOUNT')}`} inputTextAlign="left" hideControl />
                </Grid>
                <Grid item lg={4} sx={{ width: '50%' }}>
                  <BasicSelect
                    name="dfDiscountUnit"
                    labelId="lb-unit"
                    selectId="sl-unit"
                    label=""
                    value={operative.dfDiscountUnit || ''}
                    options={_.map(units, (unit: string, index: number) => {
                      return (
                        <MenuItem key={index} value={unit}>
                          {unit === CURRENCY_UNIT.PERCENT ? '%' : t('CURRENCY_CHANGE.TH')}
                        </MenuItem>
                      )
                    })}
                    onchange={handleChange}
                    disabled={props?.noModal || isDisabledChange}
                  />
                </Grid>
                <Grid item lg={4} className="w-100">
                  <InputTextField disabled name="df-discount" value={numberFormat(dfDiscountAmount || 0)} label={`${t('TREATMENT_SUMMARY.POPUP.TREATMENT_COURSE.DISCOUNT')} (${t('CURRENCY_CHANGE.TH')})`} inputTextAlign="left" hideControl />
                </Grid>
                <Grid item xs={12} className="w-100">
                  <InputTextField disabled name="df-balance" value={numberFormat((operative.dfAmountNet || 0) - (Number(operative?.dfPromotionDiscountAmount || 0) + Number(operative?.dfVoucherDiscountAmount || 0)))} label={`${t('TREATMENT_SUMMARY.POPUP.TREATMENT_COURSE.BALANCE')}`} inputTextAlign="left" hideControl />
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={6} className={'w-100 pt-2 pl-sm-2 pl-xl-3'}>
              <Grid container spacing={2}>
                <Grid item xs={12} className="w-100">
                  <InputTextField
                    disabled
                    name="cfAmount"
                    value={numberFormat(cf > 0 ? cf : 0)}
                    label={`${t(`CF ${operative.cfAmount} ${operative.dfUnit === 'PERCENT' ? `% ` : `${t('CURRENCY_CHANGE.TH')}`}`)}`}
                    inputTextAlign="left"
                    hideControl
                  />
                </Grid>
                <Grid item lg={4} sx={{ width: '50%' }}>
                  <InputTextField disabled={props?.noModal || isDisabledChange} name="cfDiscountAmount" onchange={handleChange} value={operative.cfDiscountAmount > 0 ? operative.cfDiscountAmount : 0} label={`${t('TREATMENT_SUMMARY.POPUP.TREATMENT_COURSE.DISCOUNT')}`} inputTextAlign="left" hideControl />
                </Grid>
                <Grid item lg={4} sx={{ width: '50%' }}>
                  <BasicSelect
                    name="cfDiscountUnit"
                    labelId="lb-unit"
                    selectId="sl-unit"
                    label=""
                    value={operative.cfDiscountUnit || ''}
                    options={_.map(units, (unit: string, index: number) => {
                      return (
                        <MenuItem key={index} value={unit}>
                          {unit === CURRENCY_UNIT.PERCENT ? '%' : t('CURRENCY_CHANGE.TH')}
                        </MenuItem>
                      )
                    })}
                    onchange={handleChange}
                    disabled={props?.noModal || isDisabledChange}
                  />
                </Grid>
                <Grid item lg={4} className="w-100">
                  <InputTextField disabled name="cf-discount" value={numberFormat(cfDiscountAmount || 0)} label={`${t('TREATMENT_SUMMARY.POPUP.TREATMENT_COURSE.DISCOUNT')} (${t('CURRENCY_CHANGE.TH')})`} inputTextAlign="left" hideControl />
                </Grid>
                <Grid item xs={12} className="w-100">
                  <InputTextField disabled name="cf-balance" value={numberFormat(operative?.cfAmountNet - (Number(operative?.cfPromotionDiscountAmount || 0) + Number(operative?.cfVoucherDiscountAmount || 0)) > 0 ? (operative?.cfAmountNet || 0) - (Number(operative?.cfPromotionDiscountAmount || 0) + Number(operative?.cfVoucherDiscountAmount || 0)) : 0)} label={`${t('TREATMENT_SUMMARY.POPUP.TREATMENT_COURSE.BALANCE')}`} inputTextAlign="left" hideControl />
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} className={'w-100'}>
              <Typography className="mt-2" sx={{ fontWeight: 500 }}>
                {t('TREATMENT_SUMMARY.POPUP.TREATMENT_COURSE.CLINIC_FEE')}
              </Typography>
            </Grid>
            <Grid item xs={12} className="w-100">
              <BasicSelect
                name="clinicFeeId"
                labelId="lb-clinic-fee"
                selectId="sl-clinic-fee"
                label={t('TREATMENT_SUMMARY.POPUP.TREATMENT_COURSE.CLINIC_FEE')}
                value={operative.clinicFeeId || ''}
                options={_.map(clinicFees, (clinic: ClinicFeeType, index: number) => {
                  return (
                    <MenuItem key={index} value={clinic.clinicFeeId}>
                      {clinic.clinicFeeText} ({clinic.price} {t('CURRENCY_CHANGE.TH')})
                    </MenuItem>
                  )
                })}
                onchange={(e: any) =>
                  setOperative({
                    ...operative,
                    clinicFeeId: e.target.value,
                    clinicFeeName: _.get(_.find(clinicFees, { clinicFeeId: e.target.value }), 'clinicFeeText'),
                    clinicFeePrice: _.get(_.find(clinicFees, { clinicFeeId: e.target.value }), 'price')
                  })
                }
                allowClear
                onClear={() => setOperative(_.omit(operative, ['clinicFeeId', 'clinicFeeName', 'clinicFeeNameEn']))}
                disabled={props?.noModal || isDisabledChange}
              />
            </Grid>
            {!props?.noModal &&
              <Grid item xs={12} className="w-100">
                <InputTextarea disabled={isDisabledChange} name="dfRemark" label={t('TREATMENT_SUMMARY.POPUP.TREATMENT_COURSE.REMARK')} value={operative.dfRemark} onchange={handleChange} height={94} />
              </Grid>
            }

          </Grid>
        </Box>

        <Box className="py-3">
          <Grid container spacing={1}>
            <Grid item xs={4} sm={5} className="pt-0 d-none d-sm-block"></Grid>
            <Grid item xs={4} sm={4} className="pt-0" sx={{ width: '50%' }}>
              <Title className="d-inline-block">
                {t('TREATMENT_SUMMARY.POPUP.TREATMENT_COURSE.TOTAL_DISCOUNT')} ({t('CURRENCY_CHANGE.TH')}):
              </Title>
            </Grid>
            <Grid item xs={4} sm={3} className="pt-0 text-right" sx={{ width: '50%' }}>
              <Title className="d-inline-block">{numberFormat(discountAmount + dfDiscountAmount + (cfDiscountAmount || 0) + (operative?.dfPromotionDiscountAmount || 0) + (operative?.cfPromotionDiscountAmount || 0) + (operative?.dfVoucherDiscountAmount || 0) + (operative?.cfVoucherDiscountAmount || 0))}</Title>
            </Grid>
            <Grid item xs={4} sm={5} className="pt-0 d-none d-sm-block"></Grid>
            <Grid item xs={4} sm={4} className="pt-0" sx={{ width: '50%' }}>
              <Title className="d-inline-block">
                {t('TREATMENT_SUMMARY.POPUP.TREATMENT_COURSE.TOTAL_NET')} ({t('CURRENCY_CHANGE.TH')}):
              </Title>
            </Grid>
            <Grid item xs={4} sm={3} className="pt-0 text-right" sx={{ width: '50%' }}>
              <Title className="d-inline-block">{numberFormat(operative.total)}</Title>
            </Grid>
          </Grid>
        </Box>

        {operative?.doctorName &&
          <Box marginTop={'8px'}>
            <Box component={'span'} fontWeight={'500'}>
              {t('MODAL_TREATMENT_CONSENT.SIGN_TABS.DOCTOR')}:
            </Box>
            <Box component={'span'}>
              {`  ${operative?.doctorName}`}
            </Box>
          </Box>
        }

        {popPromotionExtra &&
          <ModalSelectPromotionExtra
            promotionExtraValue={promotionExtraValue}
            onClose={onClerPopPromotionExtra}
            promotionExtra={promotionExtra}
            onChangePromotionExtra={onChangePromotionExtra}
            promotionBuy={promotionBuy}
          />
        }

        {
          popVoucherSelectLot &&
          <ModalVoucherSelectLot
            onClose={onClerPopVoucherSelectLot}
            data={dataPopVoucherSelectLot}
            handleVoucherSelect={handleVoucherSelect}
          />
        }

      </>
    )
  }

  const renderModal = () => {
    return (
      <ModalCustom
        size={'xl'}
        title={t('TREATMENT_SUMMARY.POPUP.TREATMENT_COURSE.TITLE')}
        alignFooter={'end'}
        fullscreen={'xxl-down'}
        footerInline
        disabledSubmit={disabledSave}
        textBtnCancel={t('BUTTON.CANCEL')}
        textBtnConfirm={t('BUTTON.SAVE')}
        onReset={props.onReset}
        onSubmit={handleSubmit}
        modalStyle={`modal-treatment-course ${disabled ? 'disabled-modal-footer' : ''}`}
        component={renderBody()}
      />
    )
  }

  if (!props?.noModal) {
    return (
      (!loading && (
        renderModal()
      ) || (
          <CustomLoading className="d-flex align-items-center justify-content-center">
            <Loading show={true} type="fullLoading" />
          </CustomLoading>
        )
      )
    )
  } else {
    return renderBody()
  }
}

type ModalVoucherSelectLotProps = {
  onClose: () => void
  data: any
  handleVoucherSelect: (item: any) => void
}

export const ModalVoucherSelectLot = (props: ModalVoucherSelectLotProps) => {
  const { t } = useTranslation()

  const [page, setPage] = useState<number>(1)
  const [pageLimit, setPageLimit] = useState<number>(5)
  const [rowCount, setRowCount] = useState<number>(0)
  const [sortBy, setSortBy] = useState('voucherId')
  const [sortType, setSortType] = useState('DESC')

  const [copy, setCopy] = useState(0)

  const onRequestSort = (sortByVal: string, sortTypeVal: string) => {
    setSortType(sortTypeVal)
    setSortBy(sortByVal)
  }

  const handleChangePage = (val: number) => setPage(val)

  const handleChangeRowsPerPage = (limit: number) => setPageLimit(limit)

  const headCells = [
    { id: 'no', disablePadding: false, label: t('#'), width: '50px', align: 'center', },
    { id: 'code', disablePadding: false, label: t('VOUCHER.POP_PRODUCT.FORM.VOUCHER_CODE_'), align: 'left', width: '50%', class: 'text-nowrap ' },
    { id: 'codeList', disablePadding: false, label: t('VOUCHER.POP_PRODUCT.FORM.VOUCHER_CODE_LOT'), width: '100px', align: 'center', class: 'text-nowrap ' },
    { id: 'endDate', disablePadding: false, label: t('VOUCHER.POP_PRODUCT.FORM.VOUCHER_DATEEND'), width: '120px', align: 'center', class: 'text-nowrap ' },
    { id: 'action', disablePadding: false, label: t('Action'), width: '100px', align: 'center', class: 'text-nowrap ' },
  ]

  const renderData = (objData: any, index: number,) => {
    index = page * pageLimit - pageLimit + index + 1
    const { voucherListId, voucherCode, endDate, lotNumber } = objData

    const commonColumns = [
      { option: 'TEXT', align: 'center', label: index },
      {
        option: 'COMPONENT', class: 'text-nowrap', component: (
          <Box display={'flex'} alignItems={'center'} gap={'8px'} >
            {voucherCode}
            <Box onClick={() => handleCopyClick(voucherCode, index)} sx={{ cursor: 'pointer', 'svg': { fill: '#808080' } }}>
              <FileCopyOutlinedIcon />
              <Box>
                {copy === index &&
                  <Box position="absolute" padding="10px" bgcolor="#5A5A5A" color="#FFFFFF" borderRadius="2px" top="-40px" fontSize="14px" fontWeight={400}>Copied</Box>
                }
              </Box>
            </Box>
          </Box>
        )
      },
      { option: 'TEXT', class: 'text-nowrap ', align: 'center', label: lotNumber },
      { option: 'TEXT', align: 'center', label: dateToView(endDate) },
      {
        option: 'COMPONENT', class: 'text-nowrap', component: (
          <Box textAlign={'center'} sx={{ button: { minWidth: 'fit-content !important', padding: '0 14px', fontSize: '20px', fontWeight: '600' } }}>
            <MainButton
              type={'primary'}
              variant={'contained'}
              title={'+'}
              onClick={() => props.handleVoucherSelect(objData)}
            />
          </Box>
        )
      },
    ]

    const objRenderData = {
      key: voucherListId,
      id: voucherListId,
      obj: objData,
      columns: commonColumns
    }

    return <TableRowCommon {...objRenderData} />
  }

  const renderVoucher = () => {
    const startIndex = (page - 1) * pageLimit
    return props.data?.voucherDetails?.slice(startIndex, startIndex + pageLimit)
  }

  useEffect(() => {
    setRowCount(props.data?.voucherDetails?.length || 0)
  }, [props.data?.voucherDetails])

  const handleCopyClick = (text: string, index: number) => {
    setCopy(index);
    navigator?.clipboard?.writeText(text)
    setTimeout(() => {
      setCopy(0);
    }, 2000);
  };

  return (
    <Box
      position={'fixed'}
      zIndex={2900}
      top={0}
      right={0}
      left={0}
      bottom={0}
      width={'100%'}
      height={'100%'}
    >
      <Box display={'flex'} flexDirection={'column'} alignItems={'center'} justifyContent={'center'} height={'100%'} padding={'24px'} bgcolor={'#231f201a'} >
        <Box display={'flex'} flexDirection={'column'} justifyContent={'center'} height={'fit-content'} gap={'24px'} bgcolor={'#fff'} padding={'24px'} borderRadius={'10px'} overflow={'hidden'} position={'relative'} maxWidth={'800px'} width={'100%'}>
          <Box position={'absolute'} top={'12px'} right={'12px'} sx={{ cursor: 'pointer', path: { fill: '#000000 !important', stroke: 'transparent !important', opacity: '0.5' } }}
            onClick={props.onClose}
          >
            <IconClose />
          </Box>
          <Box textAlign={'center'} fontSize={'20px'} fontWeight={'600'} color={colors.themeMainColor} >
            {t('VOUCHER.POP_PRODUCT.FORM.VOUCHER_CODE')}
          </Box>
          <Box display={'flex'} flexDirection={'column'} height={'100%'} overflow={'hidden'}>
            <Box display={'flex'} alignItems={'center'} gap={'8px'} >
              <Box>
                {t('VOUCHER.POP_PRODUCT.FORM.NAME')}
              </Box>
              <Box>
                {props.data?.voucherName}
              </Box>

              {props.data?.detail &&
                <span>
                  <Tooltip title={props.data?.detail} placement={'top-start'} sx={{ 'path': { fill: '#A8A8A8' }, }}>
                    <InfoIcon />
                  </Tooltip>
                </span>
              }
            </Box>
            <Box sx={{ marginTop: '16px', display: 'flex', flexDirection: 'column', height: 'calc(100% - 42px)' }}>
              <TableCustom
                page={page}
                pageLimit={pageLimit}
                sortType={sortType}
                sortBy={sortBy}
                onSort={onRequestSort}
                setPageLimit={handleChangeRowsPerPage}
                setPage={handleChangePage}
                rowCount={rowCount}
                headCells={headCells}
                rowsData={props.data?.voucherDetails?.length ? renderVoucher()?.map((item: any, index: number) => {
                  return renderData(item, index)
                }) : []}
              />
            </Box>
          </Box>
          <Box width={'100%'} display={'flex'} justifyContent={'flex-end'}>
            <MainButton
              onClick={props.onClose}
              type={'secondary'}
              title={t('BUTTON.CANCEL')}
              variant={'outlined'}
            />
          </Box>
        </Box>
      </Box>


    </Box>
  )
}

import { useState, useCallback, useEffect, useRef, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col, Image, Dropdown, DropdownButton, ButtonGroup } from 'react-bootstrap'
import { Box, Chip, Collapse, Tooltip, Typography } from '@mui/material'
import _, { forEach } from 'lodash'
import moment from 'moment'
import { getClinicInfo, numberFormat, toBuddhistYear } from "utils/app.utils"
import { styled } from '@mui/material/styles'
import * as UseStyles from 'features/application/manage-web/contact-us/style'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormControl from '@mui/material/FormControl'
import { resetModal } from 'app/slice/modal.slice'
import { formAppointment } from 'app/slice/appointment.slice'
import { PACKAGE_ADDONS } from 'constants/package'
import { ThemeIcon, colors } from 'constants/theme'
import { useHistory, useLocation, } from 'react-router-dom'

/** TRANSLATION*/
import { useTranslation } from 'react-i18next'

/** ICON || IMAGE */
import { Icons, icons } from 'constants/images'
import cancel from 'assets/icon/appointment/status-btn/cancel.svg'
import changeSchedule from 'assets/icon/appointment/status-btn/changeSchedule.svg'
import enter from 'assets/icon/appointment/status-btn/enter.svg'
import notConnect from 'assets/icon/appointment/status-btn/notConnect.svg'
import userCheckFalse from 'assets/icon/appointment/status-btn/userCheckFalse.svg'
import userCheckTrue from 'assets/icon/appointment/status-btn/userCheckTrue.svg'

/** API */
import ClinicApi from 'api/master/clinic.api'
import AppointmentDurationApi from 'api/setting/defaults/appointment-duration.api'
import AppointmentApi, { AppointmentInterface, ValidateAppointmentInterface } from 'api/counter/appointment.api'
import RegisterApi from 'api/counter/register.api'

/** COMPONENT */
import ModalCustom, { BtnDelete, BtnConfirm, BtnCancel } from 'component/ModalCustom/ModalCustom'
import InputTextField from 'component/Input/InputTextField'
import BasicSelect from 'component/Select/BasicSelect'
import InputSecondNewDatePicker from 'component/Input/InputSecondNewDatePicker'
import MenuItem from '@mui/material/MenuItem'
import InputTextarea from 'component/Input/InputTextarea'
import { getBranch, checkPackage } from 'utils/app.utils'
import { notiError, notiSuccess } from 'component/notifications/notifications'
import DoctorScheduleApi, { SchedulesAllProps } from 'api/counter/doctor-schedule.api'
import { DentistsInterface } from 'features/counter/appointment/appointment-interface'
import ComponentApi, { TreatmentItemsInterface } from 'api/components-api'
import AutocompleteSelect from 'component/Select/AutocompleteSelect'
import InputTimePicker from 'component/Input/InputTimePicker'
import Loading from 'component/Loading'
import SearchPatients from 'component/SearchPatients'
import InputRadio from 'component/Input/InputRadio'
import { swalCustom } from 'component/Alert/Swal'

/** STYLE */
import 'features/counter/appointment/style.css'
import ButtonCustom from 'component/Button/ButtonCustom'
import PopupMessageAppointMent from './popup-message-appointment'
import { ModalSlice, ModalSlice_Footer, ModalSlice_Title } from './style'
import useWindowSize from 'utils/useWindowSize'

import IconArrow from 'assets/icon/iconArrow'
import { event } from 'jquery'
import { FindAllInterface } from 'api/api-interface'
import { TIME_APPOINTMENT } from 'features/setting/branch-service-point/branch/form-branch'
import { isDelete, } from 'utils/verification.utils'

const LabelAddPatient = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  padding: '5px 0px 5px 16px',
  fontSize: '18px',
  fontWeight: 700,
  marginLeft: '30px',
  borderRadius: '4px',
  width: '100%',
  backgroundColor: colors.themeSecondColor10,
  [theme.breakpoints.down('lg')]: {

    marginLeft: '0px'
  }
}))

const ButtonAddPatient = styled('button')(({ theme }) => ({
  width: 'fit-content',
  height: '42px',
  borderRadius: '4px',
  border: `solid 1px ${colors.themeSecondColor}`,
  backgroundColor: colors.white,
  fontSize: '16px',
  color: colors.themeSecondColor,
  '&.add-disabled': {
    color: colors.disabledGray,
    borderColor: colors.disabledGray
  },
  '& img': {
    verticalAlign: 'top',
    marginTop: '2px'
  }
}))

const CustomBody = styled('div')<{ apStatus?: any }>(({ theme, apStatus }) => ({
  '& .dropdownColor': {
    height: 43,
    '& button': {
      backgroundColor: `${apStatus?.color || colors.lightGray} !important`,
      borderColor: `${apStatus?.color || colors.lightGray} !important`,
      color: `${apStatus?.color ? colors.white : colors.black} !important`,
      boxShadow: 'none !important',
      '& img': {
        width: '24px',
        maxWidth: '24px',
        maxHeight: '24px'
      },
      '&::after': {
        marginLeft: '0.5em',
        verticalAlign: '0.2em'
      }
    },
    '& button:focus': {
      backgroundColor: apStatus?.color || colors.lightGray,
      borderColor: apStatus?.color || colors.lightGray,
      color: apStatus?.color ? colors.white : colors.black
    },
    '& .status-icon': {
      marginRight: '0.5rem',
      marginTop: '-0.25rem'
    }
  },
  '& .buttonFromAppoint': {
    '& button': {
      padding: "5px !important"
    }
  }
}))
const ImageDelete = styled(Image)(({ theme }) => ({ opacity: 1, '&:hover': { opacity: 1 } }))

/** INTERFACE */
export interface FormAppointmentProps {
  typeForm?: string
  showForm: boolean
  appointmentId?: number
  branchId?: number
  date?: any
  dataAppointment?: any
  editSpecialInfo: boolean | false
  handleSuccess?: (Date?: any) => void
  onReset?: () => void
  onDelete?: (id: number, obj?: any) => void
  officeTime: {
    branchId: number
    dayOfWeek: string
    officeStatus: "OPEN" | "CLOSE"
    timeIn: string
    timeOut: string
  }[]
  handleDate?: (val: any) => void
  handleBranch?: (val: any) => void
  viewDisplay?: any
  reRender?: (data: any) => void
  setRerender?: (value: boolean) => void
  reRenderValue?: boolean
  patientIdApp?: any
  timeAppointment?: TIME_APPOINTMENT | undefined
}

/** VARIABLE */
const initStateErrorMessage = {
  DATE_STRING_EMPTY: '',
  DATE_ANY_REQUIRED: '',
  PATIENT_ANY_REQUIRED: '',
  PHONE_STRING_EMPTY: '',
  PATIENT_NAME_STRING_EMPTY: '',
  PATIENT_LASTNAME_STRING_EMPTY: '',
  PATIENT_PHONE_STRING_EMPTY: '',

  BRANCH_ANY_REQUIRED: '',
  DOCTOR_ANY_REQUIRED: '',
  TIME_START_ANY_REQUIRED: '',
  DURATION_MINUTE_ANY_REQUIRED: '',
  REMARK_CANCEL_STRING_EMPTY: '',
  REMARK_CANCEL_ANY_REQUIRED: '',
  APPOINTMENT_OPERATIVES_ANY_REQUIRED: '',

  SPACIAL_INFO_REQUIRED: '',
  PRICE_REQUIRED: '',
  PRACTICE_REQUIRED: '',
  REMARK_REQUIRED: ''

}

export function FormAppointment(props: FormAppointmentProps) {
  const { t, i18n } = useTranslation()
  const history = useHistory()
  const dispatch = useDispatch()
  const location = useLocation()
  const permissions = {
    isDelete: isDelete(),
  }

  const isSubmit = useSelector((state: any) => state.modal.isSubmit)
  const formAppointments = useSelector(formAppointment)

  const [loading, setLoading] = useState<boolean>(false)

  const [newPatient, setNewPatient] = useState<boolean>(false)
  const [isEdit, setIsEdit] = useState<boolean>(false)
  const [labelStatus, setLabelStatus] = useState<any>('')
  const [errorMessage, setErrorMessage] = useState({ ...initStateErrorMessage })

  /** LIST DATA */
  const [patients, setPatients] = useState([])
  const [branches, setBranches] = useState([])
  const [dentists, setDentists] = useState<DentistsInterface[]>([])
  const [durationMinutes, setDurationMinutes] = useState([])
  const [operativeTypes, setOperativeTypes] = useState<TreatmentItemsInterface[]>([])

  /** FILED */
  const [date, setDate] = useState<string>(props.date ? moment(`${props.date.year}-${String(props.date.month).padStart(2, '0')}-${String(props.date.date).padStart(2, '0')}`, 'YYYY-MM-DD').format('YYYY-MM-DD') : moment().format('YYYY-MM-DD') || '')
  const [patient, setPatient] = useState<any>(null)
  const [patientId, setPatientId] = useState<number>()
  const [patientFirstname, setPatientFirstname] = useState<string>('')
  const [patientLastname, setPatientLastname] = useState<string>('')
  const [patientPhone, setPatientPhone] = useState<string>('')
  const [phone, setPhone] = useState<string>('')
  const [branchId, setBranchId] = useState<number>(props.branchId || getBranch())
  const [doctorId, setDoctorId] = useState<number>()
  const [timeStart, setTimeStart] = useState<string>()
  const [durationMinute, setDurationMinute] = useState<number>()
  const [operativeType, setOperativeType] = useState<TreatmentItemsInterface[]>([])
  const [price, setPrice] = useState<number>(0)
  const [lab, setLab] = useState<boolean>(false)
  const [xray, setXray] = useState<boolean>(false)
  const [practice, setPractice] = useState<any[]>([])
  const [status, setStatus] = useState<string>('NORMAL')
  const [remark, setRemark] = useState<any[]>([])
  const [remarkCancel, setRemarkCancel] = useState<string>('')
  const [objAppontment, setObjAppontment] = useState<any>({})
  const [specialInfo, setSpecialInfo] = useState<string>('')
  const [officeTimeByDay, setOfficeTimeByDay] = useState<{ officeStatus?: "OPEN" | "CLOSE", timeIn?: string, timeOut?: string }>({});

  const [appRemark, setAppRemark] = useState<string[]>([''])
  const [isEditApp, setIsEditApp] = useState<boolean>(false)

  const showEdit: boolean = (props.appointmentId ? objAppontment?.appointmentId === props.appointmentId : true) || false

  /**config */
  const [showTemplateText, setShowTemplateText] = useState<boolean>(false)
  const [typeText, setTypeText] = useState<string>('')
  const [disabled, setDisabled] = useState<boolean>(props.editSpecialInfo || false)

  const [width] = useWindowSize()

  const [inputSpecialInfo, setInputSpecialInfo] = useState<boolean>(false)
  const [specialInfoClone, setSpecialInfoClone] = useState<string>('')

  const [treatmentItemsPage, setTreatmentItemsPage] = useState<number>(1)
  const [treatmentItemsSearch, setTreatmentItemsSearch] = useState<number>(1)
  const [newTreatmentItems, setNewTreatmentItems] = useState<any>([])
  const [countCurrentTI, setCountCurrentTI] = useState<number[]>([0])
  const [countTreatmentItems, setCountTreatmentItems] = useState<number>(0)
  const [contentMaxTI, setContentMaxTI] = useState<number>(0)
  const treatmentItemsRef = useRef<any>(null)

  /** VARIABLE */
  const statusIcon: any = {
    cancel_app: { src: icons.appointment.cancel_app, color: colors.appointmentStatus.statusCancel, label: t('APPOINTMENT.STATUS.CANCEL_APP'), disabled: false },
    cancel: { src: cancel, color: colors.appointmentStatus.statusCancel, label: t('APPOINTMENT.STATUS.CANCEL'), disabled: false },
    unconnect: { src: notConnect, color: colors.appointmentStatus.statusUnConnect, label: t('APPOINTMENT.STATUS.CAN_NOT_CONTACT'), disabled: false },
    change: { src: changeSchedule, color: colors.appointmentStatus.statusChange, label: t('APPOINTMENT.STATUS.PUT_OFF'), disabled: false },
    enter: { src: enter, color: colors.appointmentStatus.statusEnter, label: t('APPOINTMENT.STATUS.CONFIRM'), disabled: false },
    arrived: { src: userCheckTrue, color: colors.appointmentStatus.statusArrived, label: t('APPOINTMENT.STATUS.ARRIVED'), disabled: false },
    unarrived: { src: userCheckFalse, color: colors.appointmentStatus.statusUnArrived, label: t('APPOINTMENT.STATUS.NOT_YET'), disabled: false },

    payment: { src: icons.appointment.iconPendingPayment, color: colors.appointmentStatus.statusWaitPayment, label: t('APPOINTMENT.STATUS.PAYMENT'), disabled: true },
    pendingPayment: { src: icons.appointment.iconWaitPayment, color: colors.appointmentStatus.statusPayment, label: t('APPOINTMENT.STATUS.PENDING_PAYMENT'), disabled: true },
    pendingService: { src: icons.appointment.iconCounterCheckIn, color: colors.appointmentStatus.statusCounterCheckIn, label: t('APPOINTMENT.STATUS.PENDING_SERVICE'), disabled: true },
    service: { src: icons.appointment.iconService, color: colors.appointmentStatus.statusService, label: t('APPOINTMENT.STATUS.SERVICE'), disabled: true },
    done: { src: ThemeIcon.DoneTreatment, color: colors.appointmentStatus.statusDone, label: t('APPOINTMENT.STATUS.DONE'), disabled: true },
    // pushOff: { src: icons.iconRight, color: colors.appointmentStatus.statusChange, label: t('APPOINTMENT.STATUS.PUT_OFF'), disabled: true },


  }

  const loadData = () => {
    if (!branches.length) ClinicApi.findAllActiveBranches().then(({ data }) => data && setBranches(data))
    if (!dentists.length) DoctorScheduleApi.doctorsOnDay({ branchId, date }).then(({ data }) => data && setDentists(data))
    if (!durationMinutes.length) AppointmentDurationApi.findAll({ status: 'ACTIVE', pageLimit: 80, sortBy: 'durationMinute' }).then(({ data }) => data && setDurationMinutes(data))
  }

  const loadDataTreatmentItems = useCallback(async (search = '', nextPage?: boolean) => {
    const page = nextPage ? (treatmentItemsPage + 1) : 1
    setTreatmentItemsPage(page)
    setTreatmentItemsSearch(search)
    const condition = { search: search, page: page, pageLimit: 30 }
    await ComponentApi.findAllTreatmentItems(condition).then((res) => {
      setCountTreatmentItems(Number(res?.headers['x-total']) || 0)
      if (page === 1) {
        setCountCurrentTI([res?.data?.length || 0])
        setNewTreatmentItems([])
        res?.data && setOperativeTypes(res.data)
      } else {
        setCountCurrentTI([...countCurrentTI, res?.data?.length || 0])
        setNewTreatmentItems([...newTreatmentItems, ...res?.data])
      }
    })
    // await ComponentApi.findAllTreatmentItems(condition).then((res) => {
    //   const data = res?.data
    //   const newData: any = []
    //   if (data) {
    //     data.forEach((item: any) => {
    //       if (item?.operativeTypeId && !item?.operativeId) {
    //         newData.push({ ...item, subOperative: [] })
    //       } else {
    //         const newIndex = newData.findIndex((info: any) => info?.operativeTypeId === item?.operativeTypeId)
    //         if (newIndex !== -1 && newData?.length > 0) {
    //           newData[newIndex] = { ...newData[newIndex], subOperative: [...newData[newIndex]?.subOperative, item] }
    //         } else {
    //           newData[newData?.length] = {
    //             ...item,
    //             operativeId: null,
    //             operativeName: null,
    //             operativeNameEn: null,
    //             subOperative: [{ ...item }]
    //           }
    //         }
    //       }
    //     })
    //     // setOperativeTypes(newData)
    //     setCountTreatmentItems(Number(res?.headers['x-total']) || 0)
    //     if (page === 1) {
    //       setCountCurrentTI([data?.length || 0])
    //       setNewTreatmentItems([])
    //       res?.data && setOperativeTypes(newData)
    //     } else {
    //       setCountCurrentTI([...countCurrentTI, data?.length || 0])
    //       setNewTreatmentItems([...newTreatmentItems, ...newData])
    //     }
    //   }
    // })
  }, [treatmentItemsPage, treatmentItemsSearch, newTreatmentItems])

  useEffect(() => {
    if (treatmentItemsRef && treatmentItemsRef.current) {
      treatmentItemsRef.current.scrollTop = contentMaxTI - 385;
    }
  }, [newTreatmentItems])

  const handleLengthOperative = () => {
    const newCount = countCurrentTI.reduce((a, b) => a + b, 0,)
    return newCount < countTreatmentItems
  }

  useEffect(() => {
    loadDataTreatmentItems()
  }, [])

  useEffect(() => {
    setDisabled(props.editSpecialInfo)
  }, [props.editSpecialInfo])

  const loadPatients = (search = '', patientId = 0) => {
    ClinicApi.findAllPatients({ pageLimit: 50, allBranch: true, condition: 'branchCnNumber', patientId: patientId, appointment: true }).then(({ data }) => {
      if (data) {
        setPatients(data)
        if (patientId) {
          const patientData: any = data.find((pa: any) => pa.patientId === patientId)
          setPatient(patientData)
          setPatientId(patientId || patientData?.patientId)
          setPhone(patientData?.phone || '')
          setSpecialInfo(patientData?.specialInfo || '')
        }
      }
    })
  }
  useEffect(() => {
    setDoctorId(0)
    setBranchId(props.branchId || branchId)
    setDate(props.date ? moment(`${props.date.year}-${String(props.date.month).padStart(2, '0')}-${String(props.date.date).padStart(2, '0')}`, 'YYYY-MM-DD').format('YYYY-MM-DD') : date)
  }, [props.branchId, props.date])
  useEffect(() => {
    loadData()
    const patientState: any = location.state
    if (patientState?.patientId) {
      loadPatients(patientState.firstname, patientState.patientId)
      history.replace({ ...history.location, state: undefined })
    }
    if (!props.showForm)
      history.replace({ ...history.location, state: undefined })
  }, [props.showForm])

  const handleEdit = (ap: AppointmentInterface) => {
    setDate(ap.date)
    setPatient(patients.find((pa: any) => pa.patientId === ap.patientId))
    setPatientId(ap.patientId)
    setPatientFirstname('')
    setPatientLastname('')
    setPatientPhone('')
    setPhone(ap.phone)
    setSpecialInfo(ap.specialInfo)
    setBranchId(ap.branchId)
    setDoctorId(ap.doctorId)
    const time = ap.timeStart.substring(0, ap.timeStart.length - 3)
    setTimeStart(time)
    setDurationMinute(ap.durationMinute)
    const temp: any = ap.appointmentOperatives?.map((apOperativeType: any) => {
      return {
        color: apOperativeType.color,
        operativeId: apOperativeType.operativeId,
        operativeName: apOperativeType.operativeName,
        operativeNameEn: apOperativeType.operativeNameEn,
        operativeTypeId: apOperativeType.operativeTypeId,
        operativeTypeName: apOperativeType.operativeTypeName,
        operativeTypeNameEn: apOperativeType.operativeTypeNameEn,
      }
    })
    setOperativeType(temp)
    setPrice(ap.price || 0)
    setLab(ap.hasLab === 'HAS' ? true : false)
    setXray(ap.hasXray === 'HAS' ? true : false)
    setPractice([ap.practice])
    setStatus(ap.statusAppointment)
    setRemark([ap.remark])
    setRemarkCancel(ap.remarkCancel)
    setAppRemark([ap?.applicationDetail || ""])

    if (ap?.isApplication === "1") {
      setIsEditApp(true)
    }

    if (!ap.branchCnNumber && !ap.cnNumber) {

      setNewPatient(true)
      const newName = ap.patientFullname.split(' ')
      setPatientFirstname(ap?.firstname || newName[0])
      setPatientLastname(ap?.lastname || newName[1])
      setPatientPhone(ap.phone)
    }

    if (ap.statusAppointment === 'CANCEL') setLabelStatus('cancel')
    else if (ap.statusAppointment === 'ARRIVED') setLabelStatus('arrived')
    else if (ap.statusAppointment === 'CAN_NOT_CONTACT') setLabelStatus('unconnect')
    else if (ap.statusAppointment === 'PUT_OFF') setLabelStatus('change')
    else if (ap.statusAppointment === 'CONFIRM') setLabelStatus('enter')
    else if (ap.statusAppointment === 'NOT_YET') setLabelStatus('unarrived')
    else if (ap.statusAppointment === 'PENDING_PAYMENT') setLabelStatus('pendingPayment')
    else if (ap.statusAppointment === 'PAYMENT') setLabelStatus('payment')
    else if (ap.statusAppointment === 'PENDING_SERVICE') setLabelStatus('pendingService')
    else if (ap.statusAppointment === 'SERVICE') setLabelStatus('service')
    else if (ap.statusAppointment === 'DONE') setLabelStatus('done')
    else setLabelStatus(undefined)
    setIsEdit(true)
  }

  useEffect(() => {
    const fetchData = async () => {
      if (props.appointmentId) {
        try {
          const ap: any = await AppointmentApi.findById(props.appointmentId);
          if (ap.status === 200) {
            const data = ap.data;
            handleEdit(data);
            loadPatients(data.patientFullname, data.patientId);
            setObjAppontment(data)
          }
        } catch (error) {
          console.error("Error loading appointment data:", error);
        }
      }
    };

    if (props?.patientIdApp?.patientId) {
      handlePatientIdApp()
    } else {
      fetchData();
    }
  }, [props.appointmentId, props?.patientIdApp]);


  useEffect(() => { DoctorScheduleApi.doctorsOnDay({ branchId, date }).then(({ data }) => data && setDentists(data)) }, [branchId, date])

  /** FUNCTION */
  const clearErrorMessage = () => setErrorMessage(initStateErrorMessage)

  const resetForm = () => {
    clearErrorMessage()
    handleNewPatient(false)
    setDate(moment().format('YYYY-MM-DD'))
    setPatient(null)
    setPatientId(0)
    setPatientFirstname('')
    setPatientLastname('')
    setPatientPhone('')
    setPhone('')
    setBranchId(getBranch())
    setDoctorId(0)
    setTimeStart(undefined)
    setDurationMinute(0)
    setOperativeType([])
    setPrice(0)
    setLab(false)
    setXray(false)
    setPractice([])
    setStatus('NORMAL')
    setRemark([])
    setRemarkCancel('')
    setLabelStatus(undefined)
    setDisabled(false)
    setAppRemark([])
    setIsEditApp(false)
    if (props.onReset) props.onReset()
    setIsEdit(false)
  }

  useEffect(() => {
    clearErrorMessage()
  }, [date, patientId, phone, newPatient, patientFirstname, patientLastname, patientPhone, branchId, doctorId, timeStart, durationMinute, operativeType, remarkCancel])

  const setNum_isDupicate = (data: any) => {
    if (data === undefined) {
      return 0
    }
    if (props.appointmentId === undefined) {
      const check_data = data.filter((item: any) => item.statusAppointment !== "CANCEL" && item.statusAppointment !== "PUT_OFF");
      return check_data.length
    } else {
      const Del_appointmentId = _.reject(data, { appointmentId: props.appointmentId });
      const check_data = Del_appointmentId.filter((item: any) => item.statusAppointment !== "CANCEL" && item.statusAppointment !== "PUT_OFF");
      return check_data.length
    }
  }

  const check_isDupicate = (dataAppointment: any) => {
    if (status === "CANCEL" || dataAppointment === undefined) {
      return 0
    }
    const time = typeof timeStart !== "undefined" ? timeStart.slice(0, 5) : ''
    const data = _.filter(dataAppointment, { doctorId: doctorId })[0]?.groupAppointments[time]
    return setNum_isDupicate(data)
  }

  const handleCheckReq = () => {
    if (!formAppointments?.length || !Object.values(formAppointments).length) {
      return {}
    }
    const newDataCheckReq: any = {}

    formAppointments.forEach((item: any) => {
      if (!item.isDisabled && item.isRequired) {
        if (item.id === 'spacialInfo' && (inputSpecialInfo ? specialInfoClone : specialInfo) === "" ||
          item.id === 'price' && !price ||
          item.id === 'prictice' && valInputTextArea(practice.toString()) === '' ||
          item.id === 'note' && valInputTextArea(remark.toString()) === '') {
          newDataCheckReq[`${item.text}_REQUIRED`] = t(`APPOINTMENT.MESSAGE.${item.text}_REQUIRED`)
        }
      }
    })
    return newDataCheckReq
  }

  const handleSubmit = async () => {
    clearErrorMessage()
    setLoading(true)

    const dataError = handleCheckReq()

    if (!date || (newPatient ? !patientFirstname || !patientLastname || !patientPhone : !patient || !phone) || !branchId || !doctorId || !timeStart || !durationMinute || !operativeType.length || (status === 'CANCEL' && !remarkCancel) || Object.values(dataError).length) {
      const msg: any = {
        DATE_STRING_EMPTY: !date ? t(`APPOINTMENT.MESSAGE.DATE_STRING_EMPTY`) : '',
        DATE_ANY_REQUIRED: !date ? t(`APPOINTMENT.MESSAGE.DATE_ANY_REQUIRED`) : '',
        PATIENT_ANY_REQUIRED: !patient && !newPatient ? t(`APPOINTMENT.MESSAGE.PATIENT_ANY_REQUIRED`) : '',
        PHONE_STRING_EMPTY: !phone && !newPatient ? t(`APPOINTMENT.MESSAGE.PHONE_STRING_EMPTY`) : '',
        PATIENT_NAME_STRING_EMPTY: !patientFirstname && newPatient ? t(`APPOINTMENT.MESSAGE.PATIENT_NAME_STRING_EMPTY`) : '',
        PATIENT_LASTNAME_STRING_EMPTY: !patientLastname && newPatient ? t(`APPOINTMENT.MESSAGE.PATIENT_LASTNAME_STRING_EMPTY`) : '',
        PATIENT_PHONE_STRING_EMPTY: !patientPhone && newPatient ? t(`APPOINTMENT.MESSAGE.PATIENT_PHONE_STRING_EMPTY`) : '',
        BRANCH_ANY_REQUIRED: !branchId ? t(`APPOINTMENT.MESSAGE.BRANCH_ANY_REQUIRED`) : '',
        DOCTOR_ANY_REQUIRED: !doctorId ? t(`APPOINTMENT.MESSAGE.DOCTOR_ANY_REQUIRED`) : '',
        TIME_START_ANY_REQUIRED: !timeStart ? t(`APPOINTMENT.MESSAGE.TIME_START_ANY_REQUIRED`) : '',
        DURATION_MINUTE_ANY_REQUIRED: !durationMinute ? t(`APPOINTMENT.MESSAGE.DURATION_MINUTE_ANY_REQUIRED`) : '',
        REMARK_CANCEL_STRING_EMPTY: status === 'CANCEL' || !remarkCancel ? t(`APPOINTMENT.MESSAGE.REMARK_CANCEL_STRING_EMPTY`) : '',
        REMARK_CANCEL_ANY_REQUIRED: status === 'CANCEL' || !remarkCancel ? t(`APPOINTMENT.MESSAGE.REMARK_CANCEL_ANY_REQUIRED`) : '',
        APPOINTMENT_OPERATIVES_ANY_REQUIRED: !operativeType.length ? t(`APPOINTMENT.MESSAGE.APPOINTMENT_OPERATIVES_ANY_REQUIRED`) : ''
      }
      setErrorMessage({ ...initStateErrorMessage, ...msg, ...dataError })
      setLoading(false)
      return false
    }

    const objSend: any = {
      branchId,
      patientId,
      phone,
      date,
      timeStart,
      durationMinute,
      doctorId,
      practice: practice.toString(),
      price,
      hasLab: lab ? 'HAS' : 'NOT_HAS',
      hasXray: xray ? 'HAS' : 'NOT_HAS',
      statusAppointment: status,
      remark: remark.toString(),
      remarkCancel,
      specialInfo: inputSpecialInfo ? specialInfoClone : specialInfo,
      appointmentOperatives: operativeType.map((op: any) => {
        console.log('edtryfguhij', op.color)
        const send: any = {
          operativeTypeId: op.operativeTypeId,
          color: op.color
        }
        if (op.operativeId) send.operativeId = op.operativeId
        return send
      }),
      applicationDetail: appRemark.toString()
    }

    const validateAppointment: ValidateAppointmentInterface = {
      date: objSend.date,
      timeStart: objSend.timeStart,
      branchId: objSend.branchId,
      doctorId: objSend.doctorId,
      durationMinute: objSend.durationMinute
    }
    if (props?.appointmentId) validateAppointment.appointmentId = props?.appointmentId

    let condition: any = {}
    condition = { ...condition, branchId: branchId }
    condition = { ...condition, date: date }
    condition = { ...condition, doctorId: String(doctorId) }
    let dataAppointment: any = {}
    await AppointmentApi.displayDay(condition).then(({ data }) => {
      if (data) {
        dataAppointment = data
      }
    })
    await AppointmentApi.validateAppointment(validateAppointment)
      .then(({ data }) => {
        const { appointment, officeTime, schedule, breakTime } = data
        setLoading(false)

        if (check_isDupicate(dataAppointment) !== 0 || officeTime?.isOutOfTimeOffice || schedule?.isOutOfTime || breakTime?.isBreakTime) {

          return (
            swalCustom(
              t('VALIDATION.ALERT.CONTINUE'),
              `
              ${check_isDupicate(dataAppointment) !== 0 && (
                `<div class="text-left d-flex align-items-start pt-1" style="max-width: 450px">
                  <img src=${icons.incorrectCircle} class="pt-1 pr-2" />
                  <div>
                      <span class="pr-2" style="font-weight: 500; color: ${colors.textPrimary}">${t('APPOINTMENT.MESSAGE.DUP_APPOINTMENT')}</span>
                      <span style="color: ${colors.textLightGray}">${t('APPOINTMENT.POPUP.WARNING.IS_DAY')} ${toBuddhistYear(moment(objSend.date), 'DD/MM/YYYY')}
                      ${appointment.doctor?.prefix + appointment.doctor?.firstname + appointment.doctor?.lastname} 
                      ${t('APPOINTMENT.POPUP.WARNING.APPOINTMENT')} ${moment(String(objSend.timeStart), 'HH:mm').format('HH.mm')}
                      ${t('APPOINTMENT.POPUP.WARNING.TIME')} ${t('APPOINTMENT.POPUP.WARNING.COUNT_PATIENT')}
                      ${check_isDupicate(dataAppointment)} ${t('APPOINTMENT.POPUP.WARNING.NUM_USER')}</span>
                  </div>
                </div>`
              ) || ``}
              ${schedule?.isOutOfTime && (
                `<div class="text-left d-flex align-items-start pt-1" style="max-width: 450px">
                <img src=${icons.incorrectCircle} class="pt-1 pr-2" />
                <div>
                    <span class="pr-2" style="font-weight: 500; color: ${colors.textPrimary}">${t('APPOINTMENT.MESSAGE.OUT_WORK_APPOINTMENT')}</span>
                    <span style="color: ${colors.textLightGray}">${t('APPOINTMENT.POPUP.WARNING.IS_DAY')}  ${toBuddhistYear(moment(objSend.date), 'DD/MM/YYYY')}
                    ${schedule.doctor?.prefix + schedule.doctor?.firstname + schedule.doctor?.lastname} 
                    ${t('APPOINTMENT.POPUP.WARNING.TIME_WORK')} ${moment(String(schedule?.timeStart || '00:00'), 'HH:mm').format('HH.mm น.')} - ${moment(String(schedule?.timeEnd || '00:00'), 'HH:mm').format('HH.mm น.')} 
                    ${t('APPOINTMENT.POPUP.WARNING.OUT_WORK')} ${moment(String(objSend.timeStart), 'HH:mm').format('HH.mm')}
                    ${t('APPOINTMENT.POPUP.WARNING.TIME')}</span>
                </div>
              </div>`
              ) || ``}
              ${(!schedule?.isOutOfTime && officeTime?.isOutOfTime) && (
                `<div class="text-left d-flex align-items-start pt-1" style="max-width: 450px">
                <img src=${icons.incorrectCircle} class="pt-1 pr-2" />
                <div>
                    <span class="pr-2" style="font-weight: 500; color: ${colors.textPrimary}">${t('APPOINTMENT.MESSAGE.OUT_TIME_APPOINTMENT')}</span>
                    <span style="color: ${colors.textLightGray}">${t('APPOINTMENT.POPUP.WARNING.IS_DAY')} ${toBuddhistYear(moment(objSend.date), 'DD/MM/YYYY')}
                    ${officeTime.doctor?.prefix + officeTime.doctor?.firstname + officeTime.doctor?.lastname} 
                    ${t('APPOINTMENT.POPUP.WARNING.TIME_WORK')} ${moment(String(officeTime?.timeIn || '00:00'), 'HH:mm').format('HH.mm น.')} - ${moment(String(officeTime?.timeOut || '00:00'), 'HH:mm').format('HH.mm น.')} 
                    ${t('APPOINTMENT.POPUP.WARNING.OUT_WORK')} ${moment(String(objSend.timeStart), 'HH:mm').format('HH.mm')}
                    ${t('APPOINTMENT.POPUP.WARNING.TIME')}</span>
                </div>
              </div>`
              ) || ``}

              ${(breakTime?.isBreakTime) && (
                `<div class="text-left d-flex align-items-start pt-1" style="max-width: 450px">
                <img src=${icons.incorrectCircle} class="pt-1 pr-2" />
                <div>
                    <span class="pr-2" style="font-weight: 500; color: ${colors.textPrimary}">${t('APPOINTMENT.MESSAGE.BREAK_APPOINTMENT')}</span>
                    <span style="color: ${colors.textLightGray}">${t('APPOINTMENT.POPUP.WARNING.IS_DAY')} ${toBuddhistYear(moment(objSend.date), 'DD/MM/YYYY')} 
                    ${breakTime.doctor?.prefix + breakTime.doctor?.firstname + breakTime.doctor?.lastname} 
                    ${t('APPOINTMENT.POPUP.WARNING.TIME_BRAKE')} ${moment(String(breakTime.breakTimeStart || '00:00'), 'HH:mm').format('HH.mm น.')} - ${moment(String(breakTime.breakTimeEnd || '00:00'), 'HH:mm').format('HH.mm น.')} 
                    ${t('APPOINTMENT.POPUP.WARNING.OUT_BRAKE')} ${moment(String(objSend.timeStart), 'HH:mm').format('HH.mm')}
                    ${t('APPOINTMENT.POPUP.WARNING.TIME')}</span>
                </div>
              </div>`
              ) || ``}
              `,
              async (res: any) => {
                if (res) onSubmitAppointment(objSend, isEdit)
              },
              'question',
              {
                confirmButton: {
                  confirmButtonText: t('BUTTON.CONTINUE')
                },
                cancelButton: {
                  cancelButtonText: t('BUTTON.CANCEL')
                }
              }
            )
          )
        } else onSubmitAppointment(objSend, isEdit)
      })
  }

  const onSubmitAppointment = (objSend: any, statusEdit: boolean) => {
    if (newPatient) objSend.newPatient = { firstname: patientFirstname, lastname: patientLastname, phone: patientPhone }
    if (!statusEdit) {
      create(objSend)
    } else if (disabled) {
      console.log('kkk', objSend)
      objSend.status = 'ACTIVE'
      update(objSend)
    } else {
      console.log('aaa', objSend)
      objSend.status = 'ACTIVE'
      update(objSend)
    }
  }
  const create = (payload: any) => {
    setLoading(true)
    let objDate: any = {}
    if (props.handleDate) {
      objDate = {
        year: moment(payload.date)?.year(),
        month: (moment(payload.date)?.month()) + 1,
        date: moment(payload.date)?.date(),
        day: props.viewDisplay === 'DATE' ? moment(payload.date).format('ddd') : (String(moment(payload.date)?.format('ddd')) || String(moment()?.format('ddd'))),
        display: props.viewDisplay
      }
    }
    AppointmentApi.create(payload)
      .then((res: any) => {
        if (res.status !== undefined && res.status === 201) {
          if (props?.handleSuccess) props?.handleSuccess(objDate)
          dispatch(resetModal())
          resetForm()
          notiSuccess(t(`APPOINTMENT.MESSAGE.SUCCESS.CREATE`), '', null)
        } else {
          const err = res.response.data
          setErrorMessage({ ...initStateErrorMessage, [err.message]: t(`APPOINTMENT.MESSAGE.${err.message}`) })
        }
      })
      .catch((e) => {
        const err = e.response.data
        notiError(t(`APPOINTMENT.MESSAGE.${err.message}`))

      })
      .finally(() => {
        setLoading(false)
        if (props?.reRender) {
          props.reRender(payload)
        }
      })
  }

  const update = (payload: any) => {
    setLoading(true)
    let objDate: any = {}
    if (props.handleDate) {
      objDate = {
        year: moment(payload.date)?.year(),
        month: (moment(payload.date)?.month()) + 1,
        date: moment(payload.date)?.date(),
        day: props.viewDisplay === 'DATE' ? moment(payload.date).format('ddd') : (String(moment(payload.date)?.format('ddd')) || String(moment()?.format('ddd'))),
        display: props.viewDisplay
      }
    }
    if (payload?.newPatient?.phone && !payload?.phone) {
      payload.phone = payload.newPatient.phone
    }
    AppointmentApi.update(Number(props.appointmentId), payload)
      .then((res: any) => {
        if (res.status !== undefined && res.status === 200) {
          if (props?.handleSuccess) props?.handleSuccess(objDate)
          dispatch(resetModal())
          resetForm()
          notiSuccess(t(`APPOINTMENT.MESSAGE.SUCCESS.UPDATED`), '', null)
        } else {
          const err = res.response?.data
          notiError(t(`APPOINTMENT.MESSAGE.${err.message}`))

        }
      })
      .catch((e) => {
        const err = e.response.data
        notiError(t(`APPOINTMENT.MESSAGE.${err.message}`))

      })
      .finally(() => {
        setLoading(false)
        if (props?.reRender) {
          props.reRender(payload)
        }
      })
  }

  const updateSpecialInfo = (payload: any) => {
    setLoading(true)
    AppointmentApi.updateSpecialInfo(payload)
      .then((res: any) => {
        if (res.status !== undefined && res.status === 200) {
          if (props?.handleSuccess) props?.handleSuccess()
          dispatch(resetModal())
          resetForm()
          notiSuccess(t(`APPOINTMENT.MESSAGE.SUCCESS.UPDATE_SPECIALINFO`), '', null)
        } else {
          const err = res.response?.data
          notiError(t(`APPOINTMENT.MESSAGE.${err.message}`))

        }
      })
      .catch((e) => {
        const err = e.response.data
        notiError(t(`APPOINTMENT.MESSAGE.${err.message}`))
      })
      .finally(() => {
        setLoading(false)
      })
  }
  const handleStatus = (appointmentStatus: any) => {
    setLabelStatus(appointmentStatus)
    if (appointmentStatus === 'cancel') setStatus('CANCEL')
    else if (appointmentStatus === 'arrived') setStatus('ARRIVED')
    else if (appointmentStatus === 'unconnect') setStatus('CAN_NOT_CONTACT')
    else if (appointmentStatus === 'change') setStatus('PUT_OFF')
    else if (appointmentStatus === 'enter') setStatus('CONFIRM')
    else if (appointmentStatus === 'unarrived') setStatus('NOT_YET')
    else if (appointmentStatus === 'pendingPayment') setStatus('PENDING_PAYMENT')
    else if (appointmentStatus === 'payment') setStatus('PAYMENT')
    else if (appointmentStatus === 'pendingService') setStatus('PENDING_SERVICE')
    else if (appointmentStatus === 'service') setStatus('SERVICE')
    else if (appointmentStatus === 'done') setStatus('DONE')
    else if (appointmentStatus === 'pushOff') setStatus('PUT_OFF')
    else setStatus('')
  }

  const renderStatus = (appointmentStatus: any) => {
    if (appointmentStatus === 'cancel') return 'CANCEL'
    else if (appointmentStatus === 'arrived') return 'ARRIVED'
    else if (appointmentStatus === 'unconnect') return 'CAN_NOT_CONTACT'
    else if (appointmentStatus === 'change') return 'PUT_OFF'
    else if (appointmentStatus === 'enter') return 'CONFIRM'
    else if (appointmentStatus === 'unarrived') return 'NOT_YET'
    else if (appointmentStatus === 'pendingPayment') return 'PENDING_PAYMENT'
    else if (appointmentStatus === 'payment') return 'PAYMENT'
    else if (appointmentStatus === 'pendingService') return 'PENDING_SERVICE'
    else if (appointmentStatus === 'service') return 'SERVICE'
    else if (appointmentStatus === 'done') return 'DONE'
    else if (appointmentStatus === 'pushOff') return 'PUT_OFF'
    else return ''
  }

  const onSelectPatient = async (id: number) => {
    const res: any = await RegisterApi.findPatientById(id)
    const patientSelect: any = res.data
    if (patientSelect) {
      setPatient(patientSelect)
      setPatientId(id)
      setPhone(patientSelect?.phone || '')
      setSpecialInfo(patientSelect?.specialInfo || '')
      setPractice([patientSelect?.practice] || [])
      setRemark([patientSelect?.remark] || [])
    } else {
      setPatient(null)
      setPatientId(undefined)
      setPhone('')
      setSpecialInfo('')
      setPractice([])
      setRemark([])
    }

  }

  const handleNewPatient = (stageChange: boolean) => {
    setNewPatient(stageChange)
    setPatient(null)
    setPatientId(0)
    setPhone('')

    setPatientFirstname('')
    setPatientLastname('')
    setPatientPhone('')

    setSpecialInfo('')
  }

  const templateText = (type: string) => {
    setShowTemplateText(true)
    setTypeText(type)
  }

  const editSpecialInfo = (edit: boolean) => {
    setDisabled(edit)
    setInputSpecialInfo(edit)
    setSpecialInfoClone(specialInfo)
  }

  const saveSpecialInfo = async (edit: boolean) => {
    setDisabled(edit)
    setInputSpecialInfo(edit)
    const info = inputSpecialInfo ? specialInfoClone : specialInfo
    if (patientId === undefined) {
      notiError(t('APPOINTMENT.MESSAGE.SELECT_PATIENT'))
      return
    }

    setLoading(true)
    ClinicApi.updatePatientInfo({ specialInfo: info }, patientId).then(() => {
      notiSuccess(t('APPOINTMENT.MESSAGE.SAVE_PATIENT'), '', null, null)
    }).catch(() => {
      notiError('error')
    }).finally(() => {
      setLoading(false)
      setSpecialInfoClone(info)
      setSpecialInfo(info)
      if (props.setRerender) props.setRerender(!props.reRenderValue)
    })

  }

  const valInputTextArea = (val: string) => {
    if (val.charAt(0) === ',' || val.charAt(0) === ' ') {
      val = val.substring(1);
    }
    return val
  }

  const handlePatientIdApp = () => {
    const objDate = {
      year: moment(props.patientIdApp.date)?.year() || moment()?.year(),
      month: (moment(props.patientIdApp.date)?.month()) + 1,
      date: moment(props.patientIdApp.date)?.date() || moment()?.date(),
      day: props.viewDisplay === 'DATE' ? moment(props.patientIdApp.date).format('ddd') : (String(moment(props.patientIdApp.date)?.format('ddd')) || String(moment()?.format('ddd'))),
      branchId: branchId
    }
    props?.handleDate && props.handleDate(objDate)
    setDate(moment(props.patientIdApp.date).format('YYYY-MM-DD'))
    setTimeStart(moment(props.patientIdApp.timeStart, 'HH:mm:ss').format('HH:mm'))
    setDurationMinute(props.patientIdApp.durationMinute)

    // const temp: any = props.patientIdApp?.appointmentOperatives?.map((apOperativeType: any) => {
    //   return {
    //     color: apOperativeType.color,
    //     operativeId: apOperativeType.operativeId,
    //     operativeName: apOperativeType.operativeName,
    //     operativeNameEn: apOperativeType.operativeNameEn,
    //     operativeTypeId: apOperativeType.operativeTypeId,
    //     operativeTypeName: apOperativeType.operativeTypeName,
    //     operativeTypeNameEn: apOperativeType.operativeTypeNameEn,
    //   }
    // })
    // setOperativeType(temp)

    setAppRemark([props.patientIdApp?.applicationDetail] || [])
    setIsEdit(true)
    loadPatients("", props.patientIdApp.patientId)
  }

  const renderAppointmentTitle = useCallback(() => {
    if (props.appointmentId && !disabled) {
      if (props?.patientIdApp?.patientId) {
        return t('APPOINTMENT.CREATE_APP')
      }
      return t('APPOINTMENT.UPDATE')
    } else if (disabled) {
      return t('APPOINTMENT.UPDATE_SPECIALINFO')
    } else {
      return t('APPOINTMENT.CREATE')
    }
  }, [props.appointmentId, disabled, isEdit])

  const [isSelectOperative, setIsSelectOperative] = useState<boolean>(false)
  const handleSubClickOperative = (value: any, index?: number) => {
    setOperativeType([...operativeType, value])
    setIsSelectOperative(false)
  }

  const handleWorkTimeDoctor = useCallback(async () => {
    const condition: SchedulesAllProps = {
      branchId: branchId,
      date: date,
      doctorId: doctorId || 0
    }

    const res = await DoctorScheduleApi.findAll(condition)
    if (res.status === 200 && res?.data) {
      const [newSchedule] = res.data.filter((item: any) => item?.date === date)
      const [newData] = newSchedule?.schedule || []
      if (newData) {
        if (newData?.timeStart === "00:00:00" && newData?.timeEnd === "00:00:00") {
          setOfficeTimeByDay({ ...officeTimeByDay, timeIn: newData?.timeStart, timeOut: "23:55:00" })
        } else {
          setOfficeTimeByDay({ ...officeTimeByDay, timeIn: newData?.timeStart, timeOut: newData?.timeEnd })
        }
      } else {
        setOfficeTimeByDay({})
      }
    }

  }, [branchId, doctorId, dentists, props?.timeAppointment, date])

  useEffect(() => {
    if (props?.timeAppointment === TIME_APPOINTMENT.SCHEDULE && doctorId && _.find(dentists, { doctorId: doctorId })) {
      handleWorkTimeDoctor()
    } else {
      const day = moment(date).locale('en').format('ddd')
      setOfficeTimeByDay(_.find(props.officeTime, { dayOfWeek: day }) || {})
    }
  }, [date, props.officeTime, handleWorkTimeDoctor]);

  const renderForm = (id: string) => {
    if (!formAppointments?.length) {
      return ''
    }
    const permiss = _.find(formAppointments, { id })
    return permiss ? !_.get(permiss, 'isShow') ? 'd-none' : '' : ''
  }

  const renderReqDisForm = (id: string, status?: 'isRequired' | 'isDisabled') => {
    if (!formAppointments?.length) {
      return false
    }
    const keyName = status || 'isRequired'
    const permiss = _.find(formAppointments, { id })
    return permiss ? _.get(permiss, keyName) ? true : false : false
  }

  return (props.showForm && (props.appointmentId && (showEdit && isEdit) || true)) && (
    <>
      {showTemplateText && <PopupMessageAppointMent
        isShow={showTemplateText}
        type={typeText}
        setIsShow={(show: boolean) => {
          setShowTemplateText(show)
        }}
        setText={(text: any) => {
          if (text.length > 0) {
            if (typeText === 'PRATICE') setPractice([...practice, text.toString()])
            else if (typeText === 'APP') setAppRemark([...appRemark, text])
            else setRemark([...remark, text])
          }
        }} />}
      {props.typeForm}
      {props.typeForm !== "slice" ?
        <ModalCustom
          size={'xl'}
          title={renderAppointmentTitle()}
          alignFooter={'end'}
          fullscreen={'lg-down'}
          modalStyle={'modal-form-appointment'}
          component={
            <>
              <Loading show={loading} type="fullLoading" />
              <Row className={`mx-0 pt-2 pt-lg-0`}>
                <Col sm={12} lg={6} xl={6} className="mb-3 px-2">
                  <SearchPatients
                    status="ACTIVE"
                    bodyHeight={43}
                    disabled={newPatient || props.appointmentId ? true : false}
                    callbackPatientId={onSelectPatient}
                    patient={patient}
                    search={objAppontment.patientFullname}
                    patientId={patientId}
                    onClear={() => onSelectPatient(0)}
                    helperText={errorMessage.PATIENT_ANY_REQUIRED || ''} />
                </Col>
                <Col sm={6} lg={3} xl={3} className={`mb-3 px-2 ${!props.appointmentId ? 'd-flex' : ''} ${renderForm('phone')}`}>
                  <InputTextField
                    id='phone'
                    required={true}
                    label={t('APPOINTMENT.FORM.PHONE')}
                    disabled={newPatient || props.appointmentId ? true : false}
                    type="text"
                    value={phone}
                    hookValue={true}
                    helperText={errorMessage.PHONE_STRING_EMPTY}
                    inputProps={{ shrink: true, maxLength: 50 }}
                    onchange={(e) => setPhone(e.target.value.replace(/[^0-9]/g, ''))}
                  />
                  {!props.appointmentId && (
                    <ButtonAddPatient className={`${newPatient ? 'add-disabled' : ''} ml-3 d-sm-none`} disabled={newPatient} onClick={() => handleNewPatient(true)} sx={{ flex: 'none', width: '42px' }}>
                      <Icons.IconUserPlus colors={newPatient ? colors.disabledGray : colors.themeSecondColor} />
                    </ButtonAddPatient>
                  ) || <></>}
                </Col>
                <Col sm={6} lg={3} xl={3} className="mb-3 px-2 d-none d-sm-block">
                  {props.appointmentId && (
                    <CustomBody apStatus={statusIcon[`${labelStatus}`]}>
                      <DropdownButton
                        className={'dropdownColor'}
                        onSelect={handleStatus}
                        as={ButtonGroup}
                        disabled={disabled ? disabled : statusIcon[`${labelStatus}`]?.disabled || disabled}
                        title={
                          <CustomBody className="d-inline">
                            <Image className="status-icon mr-2" style={{ marginTop: '-0.25rem' }} src={statusIcon[`${labelStatus}`]?.src || ''} alt={''} />
                            {statusIcon[`${labelStatus}`]?.label || t('APPOINTMENT.FORM.SELECT_STATUS')}
                          </CustomBody>
                        }>
                        {Object.keys(statusIcon).map((keyName, i) => (
                          <Dropdown.Item key={i} eventKey={keyName} disabled={statusIcon[`${keyName}`].disabled} active={status === renderStatus(keyName)}>
                            {statusIcon[`${keyName}`].label}
                          </Dropdown.Item>
                        ))}
                      </DropdownButton>
                    </CustomBody>
                  ) || (
                      <ButtonAddPatient className={`${newPatient ? 'add-disabled' : ''} d-none d-sm-flex align-items-center`} disabled={newPatient} onClick={() => handleNewPatient(true)}>
                        <Icons.IconUserPlus colors={newPatient ? colors.disabledGray : colors.themeSecondColor} />
                        <span className="ml-2">{t('APPOINTMENT.FORM.ADD_PATIENT')}</span>
                      </ButtonAddPatient>
                    )}
                </Col>
              </Row>
              {!newPatient && (
                <Row className={`mx-0 ${renderForm('spacialInfo')}`}>
                  <Col sm={12} className="w-100 px-2 mb-3 pb-1">
                    <Typography
                      sx={{
                        paddingBottom: '10px',
                        fontWeight: 500,
                        color: colors.themeMainColor,
                        svg: { width: '16px', height: '16px' }
                      }}>
                      <div className='d-flex'>
                        <div>
                          <Icons.IconStar stroke={colors.themeMainColor} />
                          <span className='ml-1 align-middle'>{t('REGISTER.PATIENT_SPACIAL_INFO')}</span>
                        </div>
                        <div className='ml-auto'>
                          {(props.appointmentId && !disabled) && <ButtonCustom onClick={() => editSpecialInfo(true)} textButton={t('APPOINTMENT.UPDATE_SPECIALINFO')} className="h-100" style={{ margin: 0, height: 35 }} />}
                        </div>
                      </div>
                    </Typography>
                    <InputTextarea
                      value={specialInfo}
                      disabled={(props.appointmentId && !disabled) || false}
                      onchange={(e) => {
                        setSpecialInfo(e.target.value)
                      }}
                    />
                  </Col>
                </Row>
              ) || <></>}
              <div className={`overflow-hidden ${newPatient ? 'd-block' : 'd-none'}`}>
                <LabelAddPatient>
                  {t('APPOINTMENT.FORM.ADD_PATIENT')}{' '}
                  <div className="d-inline-block d-lg-none mr-2" style={{ lineHeight: 0 }}>
                    <ImageDelete src={icons.iconDelete} onClick={(e) => handleNewPatient(false)} className="cursor-pointer" />
                  </div>
                </LabelAddPatient>
                <Row className={'mx-0 mt-3 mb-2 ml-4'}>
                  <Col md={6} lg={3} className="mb-3 px-2 pl-xl-3">
                    <InputTextField
                      required
                      label={t('APPOINTMENT.FORM.PATIENT_NAME')}
                      type="text"
                      value={patientFirstname}
                      inputProps={{ shrink: true }}
                      helperText={errorMessage.PATIENT_NAME_STRING_EMPTY}
                      onchange={(e) => setPatientFirstname(e.target.value)}
                    />
                  </Col>
                  <Col md={6} lg={3} className="mb-3 px-2">
                    <InputTextField
                      required
                      label={t('APPOINTMENT.FORM.PATIENT_LASTNAME')}
                      type="text"
                      value={patientLastname}
                      inputProps={{ shrink: true }}
                      helperText={errorMessage.PATIENT_LASTNAME_STRING_EMPTY}
                      onchange={(e) => setPatientLastname(e.target.value)}
                    />
                  </Col>
                  <Col md={6} lg={3} className="mb-3 px-2">
                    <InputTextField
                      required
                      label={t('APPOINTMENT.FORM.PATIENT_PHONE')}
                      type="text"
                      value={patientPhone}
                      inputProps={{ shrink: true, maxLength: 50 }}
                      helperText={errorMessage.PATIENT_PHONE_STRING_EMPTY}
                      onchange={(e) => (
                        setPatientPhone(e.target.value.replace(/[^0-9]/g, ''))
                      )}
                    />
                  </Col>
                  <Col sm={6} lg={3} className="d-none d-lg-flex align-items-center justify-content-right mb-3 pb-1">
                    <ImageDelete src={icons.iconDelete} onClick={(e) => handleNewPatient(false)} className="ml-auto cursor-pointer" />
                  </Col>
                </Row>
              </div>
              {props.appointmentId && (
                <Row className="d-sm-none mb-3 px-2">
                  <Col sm={12} className="w-100">
                    <CustomBody apStatus={statusIcon[`${labelStatus}`]}>
                      <DropdownButton
                        className={'dropdownColor'}
                        disabled={disabled ? disabled : statusIcon[`${labelStatus}`]?.disabled || disabled}
                        onSelect={handleStatus}
                        as={ButtonGroup}
                        title={
                          <CustomBody className="d-inline">
                            <Image className="status-icon mr-2" style={{ marginTop: '-0.25rem' }} src={statusIcon[`${labelStatus}`]?.src || ''} alt={''} />
                            {statusIcon[`${labelStatus}`]?.label || t('APPOINTMENT.FORM.SELECT_STATUS')}
                          </CustomBody>
                        }>
                        {Object.keys(statusIcon).map((keyName, i) => (
                          <Dropdown.Item key={i} eventKey={keyName} disabled={statusIcon[`${keyName}`].disabled} active={status === renderStatus(keyName)}>
                            {statusIcon[`${keyName}`].label}
                          </Dropdown.Item>
                        ))}
                      </DropdownButton>
                    </CustomBody>
                  </Col>
                </Row>
              ) || <></>}
              <Row className={`mx-0`}>
                <Col sm={6} xl={3} className={`mb-3 px-2  ${renderForm('date')}`}>
                  <InputSecondNewDatePicker
                    required
                    key={'date-of-birth'}
                    label={t('APPOINTMENT.FORM.DATE')}
                    value={date}
                    helperText={errorMessage.DATE_STRING_EMPTY || errorMessage.DATE_ANY_REQUIRED}
                    onchange={(e: any) => {
                      setDate(moment(e).format('YYYY-MM-DD'))
                      setTimeStart('')
                      setDurationMinute(0)
                      if (props.handleDate) {
                        const objDate = {
                          year: moment(e)?.year() || moment()?.year(),
                          month: (moment(e)?.month()) + 1,
                          date: moment(e)?.date() || moment()?.date(),
                          day: props.viewDisplay === 'DATE' ? moment(e).format('ddd') : (String(moment(e)?.format('ddd')) || String(moment()?.format('ddd')))
                        }
                        props.handleDate(objDate)
                      }
                    }}
                    disabled={disabled}
                  />
                  {errorMessage.DATE_STRING_EMPTY}
                </Col>
                <Col sm={6} xl={3} className={`mb-3 px-2 ${renderForm('branch')}`}>
                  <BasicSelect
                    required={true}
                    label={t('APPOINTMENT.FORM.BRANCH')}
                    value={branchId}
                    onchange={(event) => setBranchId(event.target.value)}
                    selectId="branch"
                    labelId="branch"
                    options={branches.map((br: any, i: number) => (
                      <MenuItem key={i} value={br.branchId}>
                        {br.branchName}
                      </MenuItem>
                    ))}
                    helperText={errorMessage.BRANCH_ANY_REQUIRED}
                    disabled={disabled}
                  />
                </Col>
                <Col sm={12} md={6} xl={3} className={`mb-3 px-2 ${renderForm('doctor')}`}>
                  <BasicSelect
                    required={true}
                    label={t('APPOINTMENT.FORM.DOCTOR')}
                    value={doctorId || ''}
                    onchange={(event) => setDoctorId(event.target.value)}
                    selectId="dentist"
                    labelId="dentist"
                    options={
                      dentists.length && (
                        dentists.map((dentist: DentistsInterface) => (
                          <MenuItem key={dentist.doctorId} value={dentist.doctorId}>
                            {dentist.doctorFullname}
                          </MenuItem>
                        ))
                      ) || (
                        <MenuItem key={'0'} disabled value={0}>
                          {t('APPOINTMENT.STATUS.NO')}{t('USER_DOCTOR')}{t('APPOINTMENT.BUTTON.ENTER_SHIFT_TODAY')}
                        </MenuItem>
                      )
                    }
                    helperText={errorMessage.DOCTOR_ANY_REQUIRED}
                    disabled={disabled}
                  />
                </Col>
                <Col sm={12} md={6} xl={3} className="px-2">
                  <Row className={`mx-0`}>
                    <Col sm={6} md={6} className={`mb-3 px-0 pr-sm-2 ${renderForm('timeStart')}`}>
                      <InputTimePicker
                        required={true}
                        key={'time'}
                        label={t('APPOINTMENT.FORM.TIME_START')}
                        value={timeStart ? moment(timeStart, 'HH:mm:ss').format('HH:mm') : ''}
                        helperText={errorMessage.TIME_START_ANY_REQUIRED || errorMessage.DATE_STRING_EMPTY || errorMessage.DATE_ANY_REQUIRED}
                        onChange={(e: any) => setTimeStart(e.format('HH:mm'))}
                        disabled={disabled || officeTimeByDay.officeStatus === "CLOSE"}
                        scopeTimeStart={officeTimeByDay?.timeIn || '00:00'}
                        scopeTimeEnd={officeTimeByDay?.timeOut || '00:00'} />
                    </Col>
                    <Col sm={6} md={6} className={`mb-3 px-0 pl-sm-2 ${renderForm('duration')}`}>
                      <BasicSelect
                        required={true}
                        label={t('APPOINTMENT.FORM.PERIOD')}
                        value={durationMinute || ''}
                        onchange={(event) => setDurationMinute(event.target.value)}
                        selectId="durationMinute"
                        labelId="durationMinute"
                        options={durationMinutes.map((duration: any, i: number) => (
                          <MenuItem key={i} value={duration.durationMinute}>
                            {`${duration.duration} ${t(`TIME_DURATION_TYPE.${duration.durationType}`)}`}
                          </MenuItem>
                        ))}
                        helperText={errorMessage.DURATION_MINUTE_ANY_REQUIRED}
                        disabled={disabled || officeTimeByDay.officeStatus === "CLOSE"}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
              <div className={`mb-3 px-2 ${labelStatus === 'cancel' ? '' : 'd-none'}`} style={{ display: isEdit ? '' : 'none' }}>
                <InputTextarea disabled={disabled} required onchange={(e) => setRemarkCancel(e.target.value)} value={remarkCancel} label={t('APPOINTMENT.FORM.REMARK_CANCEL')} helperText={errorMessage.REMARK_CANCEL_ANY_REQUIRED || errorMessage.REMARK_CANCEL_STRING_EMPTY} />
              </div>
              <Row className={`mx-0 ${checkPackage(PACKAGE_ADDONS.ADDON_X_RAY) || checkPackage(PACKAGE_ADDONS.ADDON_LAB) ? 'mb-2' : ''}`}>
                <Col sm={6} lg={9} className={`mb-3 px-2 ${renderForm('treatment')}`}>
                  <AutocompleteSelect
                    multiple
                    required={true}
                    labelId="lb-operative-id"
                    inputLabel={t('REGISTER.SEND_EXAMINATION.TREATMENT_LIST')}
                    noOptionsText={t('REGISTER.FORM.NOT_FOUND')}
                    options={operativeTypes}
                    getOptionLabel={(option: any) => (option.operativeId ? option.operativeName : option.operativeTypeName)}
                    renderOption={(props, option: any) => (
                      <Box
                        key={`${option.operativeId ? `operativeId-${option.operativeId}` : `operativeTypeId-${option.operativeTypeId}`}`}
                        component="li"
                        style={{
                          pointerEvents: _.some(operativeType, (i: any, index: number) => i.operativeTypeId === option.operativeTypeId && i.operativeId === option.operativeId) ? 'none' : '',
                          backgroundColor: _.some(operativeType, (i: any, index: number) => i.operativeTypeId === option.operativeTypeId && i.operativeId === option.operativeId) ? colors.themeSecondColor06 : colors.white,
                          color: _.some(operativeType, (i: any, index: number) => i.operativeTypeId === option.operativeTypeId && i.operativeId === option.operativeId) ? colors.themeSecondColor : null
                        }}
                        {...props}
                      >
                        <span style={{ paddingLeft: option.operativeId ? '1rem' : '' }}>{option.operativeId ? option.operativeName : option.operativeTypeName}</span>
                      </Box>
                    )}
                    renderTags={(tagValue, getTagProps) =>
                      tagValue.map((option: any, index: any) => (
                        <div key={`index-${index}`}>
                          <Chip label={option.operativeName ? option.operativeName : option.operativeTypeName} style={{ fontSize: '1rem', backgroundColor: option.color }} {...getTagProps({ index })} />
                        </div>
                      ))
                    }
                    onchange={(event, val) => setOperativeType(_.map(val, (v) => v))}
                    onInputChange={(event: any, newValue: any) => loadDataTreatmentItems(newValue)}
                    value={operativeType ? operativeType : []}
                    key={'treatment'}
                    height={43}
                    limitTags={3}
                    errMessage={errorMessage.APPOINTMENT_OPERATIVES_ANY_REQUIRED}
                    disabled={disabled}
                  />
                </Col>
                <Col sm={6} lg={3} className={`mb-3 px-2 ${renderForm('price')}`}>
                  <InputTextField
                    label={t('APPOINTMENT.FORM.PRICE')}
                    type="text"
                    value={price}
                    inputProps={{ shrink: true, inputProps: { min: 0, max: 10 } }}
                    onchange={(e) => {
                      const val = e.target.value.replace(/[^0-9]/g, '')
                      if (val < 0) setPrice(0)
                      else if (val > 900000) setPrice(900000)
                      else setPrice(val)
                    }}
                    disabled={disabled}
                  />
                </Col>
              </Row>
              <Row className="mx-0">
                {checkPackage(PACKAGE_ADDONS.ADDON_X_RAY) && (
                  <Col xs={12} xl={'auto'} className={`px-0 ${renderForm('lab')}`}>
                    <Row className="mx-0">
                      <Col xs={12} sm={3} xl={'auto'} className="px-2 pt-sm-2">
                        <span className={`formLabel fw-700`}>{t('APPOINTMENT.FORM.LIST_ORDER_LAB')}</span>
                      </Col>
                      <Col xs={12} sm={9} xl={'auto'} className="mb-2 px-2 pl-4">
                        <FormControl>
                          <RadioGroup row aria-labelledby="demo-row-radio-buttons-group-label" name="row-radio-buttons-group">
                            <FormControlLabel disabled={disabled} value="lab" control={<InputRadio checked={lab === true} className="radio" onClick={(e: any) => setLab(true)} />} label={t('APPOINTMENT.FORM.HAS_LAB')} />
                            <FormControlLabel disabled={disabled} value="nonlab" control={<InputRadio checked={lab === false} onClick={(e: any) => setLab(false)} />} label={t('APPOINTMENT.FORM.NOT_HAS_LAB')} />
                          </RadioGroup>
                        </FormControl>
                      </Col>
                    </Row>
                  </Col>
                )}
                {checkPackage(PACKAGE_ADDONS.ADDON_LAB) && (
                  <Col xs={12} xl={'auto'} className={`px-0 ${renderForm('xray')}`}>
                    <Row className="mx-0">
                      <Col xs={12} sm={3} xl={'auto'} className="px-2 pt-sm-2">
                        <span className={`formLabel fw-700`}>{t('APPOINTMENT.FORM.LIST_ORDER_XRAY')}</span>
                      </Col>
                      <Col xs={12} sm={9} xl={'auto'} className="mb-2 px-2 pl-4">
                        <FormControl>
                          <RadioGroup row aria-labelledby="demo-row-radio-buttons-group-label" name="row-radio-buttons-group">
                            <FormControlLabel disabled={disabled} value="xray" control={<InputRadio checked={xray === true} onClick={(e: any) => setXray(true)} />} label={t('APPOINTMENT.FORM.HAS_XRAY')} />
                            <FormControlLabel disabled={disabled} value="nonxray" control={<InputRadio checked={xray === false} onClick={(e: any) => setXray(false)} />} label={t('APPOINTMENT.FORM.NOT_HAS_XRAY')} />
                          </RadioGroup>
                        </FormControl>
                      </Col>
                    </Row>
                  </Col>
                )}
              </Row>
              <div className={`d-flex mb-3 px-2 ${checkPackage(PACKAGE_ADDONS.ADDON_X_RAY) || checkPackage(PACKAGE_ADDONS.ADDON_LAB) ? 'mt-2' : ''} ${renderForm('prictice')}`}>
                <InputTextarea disabled={disabled} value={practice.toString()} onchange={(event) => setPractice([event.target.value])} label={t('APPOINTMENT.FORM.PRACTICE')} helperText={''} />
                <Box className='input-form-note-wrap position-absolute'></Box>
                <ButtonCustom disabled={disabled} onClick={() => templateText('PRATICE')} textButton={<img src={icons.iconFeatherEdit} alt="" style={{ width: 24 }} />} className="h-100" style={{ margin: 0, height: '89px' }} />
              </div>
              <div className={`px-2`}>
                <hr className="my-lg-4 " />
              </div>
              <div className={`d-flex mb-3 mt-2 px-2 ${renderForm('note')}`}>
                <InputTextarea disabled={disabled} value={remark.toString()} onchange={(event) => setRemark([event.target.value])} label={t('APPOINTMENT.FORM.REMARK')} helperText={''} />
                <Box className='input-form-note-wrap position-absolute'></Box>
                <ButtonCustom disabled={disabled} onClick={() => templateText('REMARK')} textButton={<img src={icons.iconFeatherEdit} alt="" style={{ width: 24 }} />} className="h-100" style={{ margin: 0, height: '89px' }} />
              </div>
            </>
          }
          onSubmit={handleSubmit}
          onReset={() => {
            resetForm()
          }}
          onDelete={(id: number, obj: any) => {
            props.onDelete && props.onDelete(id, obj)
            resetForm()
          }}
          id={props.appointmentId}
          obj={objAppontment}
          disabledSubmit={loading}
          textBtnCancel={t('APPOINTMENT.FORM.CANCEL')}
          textBtnDelete={!disabled ? t('APPOINTMENT.BUTTON.DEL') : ''}
          textBtnConfirm={props.appointmentId && !disabled ? t('APPOINTMENT.FORM.SAVE') : disabled ? t('APPOINTMENT.FORM.SAVE_SPECIALINFO') : t('APPOINTMENT.FORM.ADD')}
        />
        :
        <>
          <ModalSlice className={'appointment-form'}>
            <Loading show={loading} type="fullLoading" />
            <div className='pt-2 pl-3 pr-3 pb-0 form-appointment'>
              <ModalSlice_Title>
                {renderAppointmentTitle()}
                <div style={{ position: "absolute", top: "0", right: "0", padding: "8px 16px", }}>
                  {props.appointmentId && (
                    <CustomBody apStatus={statusIcon[`${labelStatus}`]}>
                      <DropdownButton
                        style={{ height: "35px" }}
                        className={'dropdownColor buttonFromAppoint'}
                        onSelect={handleStatus}
                        as={ButtonGroup}
                        disabled={disabled ? disabled : statusIcon[`${labelStatus}`]?.disabled || disabled}
                        title={
                          <CustomBody className="d-inline" style={{ fontSize: "14px" }}>
                            <Image
                              className="status-icon mr-2"
                              style={{
                                marginTop: '-0.25rem',
                                height: statusIcon[`${labelStatus}`]?.src ? "15px" : "0",
                                width: statusIcon[`${labelStatus}`]?.src ? "15px" : "0"
                              }} src={statusIcon[`${labelStatus}`]?.src || ""}
                              alt={''}
                            />
                            {statusIcon[`${labelStatus}`]?.label || t('APPOINTMENT.FORM.SELECT_STATUS')}
                          </CustomBody>
                        }>
                        {Object.keys(statusIcon).map((keyName, i) => {
                          if (keyName === 'cancel_app') return
                          return (
                            <Dropdown.Item key={i} eventKey={keyName} disabled={statusIcon[`${keyName}`].disabled} active={status === renderStatus(keyName)}>
                              {statusIcon[`${keyName}`].label}
                            </Dropdown.Item>
                          )
                        })}
                      </DropdownButton>
                    </CustomBody>
                  ) || (
                      <ButtonAddPatient
                        className={`${newPatient ? 'add-disabled' : ''} d-none d-sm-flex align-items-center`}
                        disabled={newPatient}
                        onClick={() => handleNewPatient(true)}
                        style={{ width: "30px", height: "30px" }}
                      >
                        <Icons.IconUserPlus colors={newPatient ? colors.disabledGray : colors.themeSecondColor} />
                      </ButtonAddPatient>
                    )}
                </div>

              </ModalSlice_Title>
              <hr style={{ margin: "1rem 0 1rem 0 " }} />
              <div className=''>
                <div className={`mx-0 pt-2 pt-lg-0 p-0`}>
                  <Col sm={12} lg={12} xl={12} className="">
                    <SearchPatients
                      bodyHeight={30}
                      bodyWidth={"28%"}
                      disabled={newPatient || props.appointmentId ? true : false}
                      callbackPatientId={onSelectPatient}
                      patient={patient}
                      search={objAppontment.patientFullname}
                      patientId={patientId}
                      onClear={() => onSelectPatient(0)}
                      status="ACTIVE"
                      helperText={errorMessage.PATIENT_ANY_REQUIRED || ''} />
                  </Col>
                </div>
                <div className={`mt-3 `}>
                  <Col sm={12} lg={12} xl={12} className={` ${!props.appointmentId ? 'd-flex' : ''} ${renderForm('phone')}`}>
                    <InputTextField
                      inputHeight={40}
                      required={true}
                      label={t('APPOINTMENT.FORM.PHONE')}
                      disabled={newPatient || props.appointmentId ? true : false}
                      type="text"
                      value={phone}
                      hookValue={true}
                      helperText={errorMessage.PHONE_STRING_EMPTY}
                      inputProps={{ shrink: true, maxLength: 50 }}
                      onchange={(e) => setPhone(e.target.value)}
                    />
                    {!props.appointmentId && (
                      <ButtonAddPatient className={`${newPatient ? 'add-disabled' : ''} ml-3 d-sm-none`} disabled={newPatient} onClick={() => handleNewPatient(true)} sx={{ flex: 'none', width: '42px' }}>
                        <Icons.IconUserPlus colors={newPatient ? colors.disabledGray : colors.themeSecondColor} />
                      </ButtonAddPatient>
                    ) || <></>}
                  </Col>
                </div>
                <Box className={`${renderForm('spacialInfo')}`}>
                  <div className={`mt-2`}>
                    {!newPatient && (
                      <Col sm={12} className={`w-100 mb-3 pb-1`}>
                        <Typography
                          sx={{
                            paddingBottom: '10px',
                            fontWeight: 500,
                            color: colors.themeMainColor,
                            svg: { width: '16px', height: '16px' }
                          }}>
                          <div className='d-flex'>
                            <div>
                              <Icons.IconStar stroke={colors.themeMainColor} />
                              <span className='ml-1 align-middle'>
                                {t('REGISTER.PATIENT_SPACIAL_INFO')}
                                {renderReqDisForm('spacialInfo') && <span style={{ color: colors.statusInactive }}>*</span>}
                              </span>
                            </div>
                            <div className="ml-auto">
                              {props.appointmentId && !disabled ? (
                                <ButtonCustom
                                  onClick={() => editSpecialInfo(true)}
                                  textButton={t('APPOINTMENT.UPDATE_SPECIALINFO')}
                                  className="h-100"
                                  style={{ margin: 0, height: 35 }}
                                />
                              ) : (
                                <ButtonCustom
                                  onClick={() => saveSpecialInfo(false)}
                                  textButton={t('APPOINTMENT.SAVE_SPECIALINFO')}
                                  className="h-100"
                                  style={{ margin: 0, height: 35 }}
                                />
                              )}
                            </div>

                          </div>
                        </Typography>
                        <InputTextarea
                          value={inputSpecialInfo ? specialInfoClone : specialInfo}
                          disabled={(props.appointmentId && !disabled) || false}
                          onchange={(e) => {
                            clearErrorMessage()
                            if (inputSpecialInfo) {
                              setSpecialInfoClone(e.target.value)
                            } else {
                              setSpecialInfo(e.target.value)
                            }
                          }}
                          helperText={errorMessage?.SPACIAL_INFO_REQUIRED && (inputSpecialInfo ? specialInfoClone : specialInfo) === "" ? errorMessage?.SPACIAL_INFO_REQUIRED : ''}
                        />
                      </Col>
                    ) || <></>}
                  </div>
                  <hr style={{ margin: "1rem 0 0 0 " }} />
                </Box>

                <div className={`overflow-hidden ${newPatient ? 'd-block' : 'd-none'} mt-3`}>
                  <LabelAddPatient style={{ margin: "0 0 1rem 0", paddingRight: "16px", fontSize: "14px" }}>
                    {props.appointmentId ? t('APPOINTMENT.FORM.EDIT_PATIENT') : t('APPOINTMENT.FORM.ADD_PATIENT')}{' '}
                    {!props.appointmentId ?
                      <div className="" style={{ lineHeight: 0 }}>
                        <ImageDelete src={icons.iconDelete} onClick={(e) => handleNewPatient(false)} className="cursor-pointer" />
                      </div> :
                      <></>
                    }
                  </LabelAddPatient>
                  <Row className={``}>
                    <Col xs={12} md={6} lg={6} className="mb-3 pr-sm-2">
                      <InputTextField
                        required
                        label={t('APPOINTMENT.FORM.PATIENT_NAME')}
                        type="text"
                        value={patientFirstname}
                        inputProps={{ shrink: true }}
                        helperText={errorMessage.PATIENT_NAME_STRING_EMPTY}
                        onchange={(e) => setPatientFirstname(e.target.value)}
                      />
                    </Col>
                    <Col xs={12} md={6} lg={6} className="mb-3 pl-sm-2">
                      <InputTextField
                        required
                        label={t('APPOINTMENT.FORM.PATIENT_LASTNAME')}
                        type="text"
                        value={patientLastname}
                        inputProps={{ shrink: true }}
                        helperText={errorMessage.PATIENT_LASTNAME_STRING_EMPTY}
                        onchange={(e) => setPatientLastname(e.target.value)}
                      />
                    </Col>
                  </Row>

                  <Col md={12} lg={12} className="mb-3">
                    <InputTextField
                      required
                      label={t('APPOINTMENT.FORM.PATIENT_PHONE')}
                      type="text"
                      value={patientPhone}
                      inputProps={{ shrink: true, maxLength: 50 }}
                      helperText={errorMessage.PATIENT_PHONE_STRING_EMPTY}
                      onchange={(e) => (
                        setPatientPhone(e.target.value.replace(/[^0-9]/g, ''))
                      )}
                    />
                  </Col>
                  <hr style={{ margin: "1rem 0 0rem 0 " }} />
                </div>

                <Row className={`mt-3`}>
                  <Col xs={12} sm={6} xl={6} className={`mb-3 pr-sm-2 ${renderForm('branch')}`}>
                    <BasicSelect
                      required={true}
                      label={t('APPOINTMENT.FORM.BRANCH')}
                      value={branchId}
                      onchange={(event) => {
                        setTimeStart(undefined)
                        setDoctorId(0)
                        setBranchId(event.target.value)
                      }}
                      selectId="branch"
                      labelId="branch"
                      options={branches.map((br: any, i: number) => (
                        <MenuItem
                          key={i}
                          value={br.branchId}
                          onClick={() => {
                            if (props.handleBranch) {
                              props.handleBranch(br.branchId)
                            }
                          }}>
                          {br.branchName}
                        </MenuItem>
                      ))}
                      helperText={errorMessage.BRANCH_ANY_REQUIRED}
                      disabled={disabled}

                    />
                  </Col>
                  <Col xs={12} sm={6} xl={6} className={`mb-3 pl-sm-2 ${renderForm('date')}`}>
                    <InputSecondNewDatePicker
                      required
                      key={'date-of-birth'}
                      label={t('APPOINTMENT.FORM.DATE')}
                      value={date}
                      helperText={errorMessage.DATE_STRING_EMPTY || errorMessage.DATE_ANY_REQUIRED}
                      onchange={(e: any) => {
                        setDoctorId(0)
                        const dateObject = new Date(e)
                        if (e.length === 10 && dateObject instanceof Date && !isNaN(dateObject.getTime())) {
                          setDate(moment(e).format('YYYY-MM-DD'))
                          setTimeStart('')
                          setDurationMinute(0)
                          if (props.handleDate) {
                            const objDate = {
                              year: moment(e)?.year() || moment()?.year(),
                              month: (moment(e)?.month()) + 1,
                              date: moment(e)?.date() || moment()?.date(),
                              day: props.viewDisplay === 'DATE' ? moment(e).format('ddd') : (String(moment(e)?.format('ddd')) || String(moment()?.format('ddd'))),
                              branchId: branchId
                            }
                            props.handleDate(objDate)
                          }
                        } else {
                          if (props.handleDate) {
                            const objDate = {
                              year: moment(e)?.year() || moment()?.year(),
                              month: (moment(e)?.month()) + 1,
                              date: moment(e)?.date() || moment()?.date(),
                              day: props.viewDisplay === 'DATE' ? moment(e).format('ddd') : (String(moment(e)?.format('ddd')) || String(moment()?.format('ddd'))),
                              branchId: branchId
                            }
                            props.handleDate(objDate)
                          }
                          setDate(e)
                        }

                      }}
                      disabled={disabled}
                      pointerEvent={"auto"}
                      placeholder="--/--/----"
                      inputEdit={true}
                    />
                    {errorMessage.DATE_STRING_EMPTY}
                  </Col>
                  <Col sm={12} md={12} xl={12} className={`mb-3 ${renderForm('doctor')}`}>
                    <AutocompleteSelect
                      required={true}
                      inputLabel={t('APPOINTMENT.FORM.DOCTOR')}
                      noOptionsText={t('REGISTER.FORM.NOT_FOUND')}
                      value={dentists && doctorId ? _.find(dentists, { doctorId: doctorId }) : null}
                      onchange={(event, val) => {
                        setTimeStart(undefined)
                        setDoctorId(val.doctorId)
                      }}
                      labelId="dentist"
                      options={dentists}
                      getOptionLabel={(option: any) => option.doctorFullname}
                      errMessage={errorMessage.DOCTOR_ANY_REQUIRED}
                      disabled={disabled}
                      disableClearable
                    />
                  </Col>
                  <Col sm={12} md={12} xl={12} className={` `}>
                    <Row className={``}>
                      <Col sm={6} md={6} className={`mb-3 pr-sm-2 ${renderForm('timeStart')} `}>
                        <InputTimePicker
                          required={true}
                          key={'time'}
                          label={t('APPOINTMENT.FORM.TIME_START')}
                          value={timeStart && timeStart.length === 5 ? moment(timeStart, 'HH:mm:ss').format('HH:mm') : timeStart}
                          helperText={errorMessage.TIME_START_ANY_REQUIRED || errorMessage.DATE_STRING_EMPTY || errorMessage.DATE_ANY_REQUIRED}
                          onChange={(e: any) => {
                            if (e?.target?.value) {
                              setTimeStart(e.target.value)
                            } else {
                              if (!e?.target) {
                                setTimeStart(
                                  e.format('HH') === '00'
                                    ? `${officeTimeByDay?.timeIn?.slice(0, 2)}:${e.format('mm')}`
                                    : (e.format('HH') === `${officeTimeByDay?.timeOut?.slice(0, 2)}` ? moment(officeTimeByDay?.timeOut, 'HH:mm:ss').format('HH:mm')
                                      : e.format('HH:mm'))
                                );
                              } else {
                                setTimeStart('')
                              }
                            }
                            clearErrorMessage()
                          }}
                          inputText={true}
                          disabled={disabled || officeTimeByDay.officeStatus === "CLOSE"}
                          scopeTimeStart={officeTimeByDay?.timeIn || '00:00'}
                          scopeTimeEnd={officeTimeByDay?.timeOut || '00:00'}
                        />
                      </Col>
                      <Col sm={6} md={6} className={`mb-3 pl-sm-2 ${renderForm('duration')}`}>
                        <BasicSelect
                          required={true}
                          label={t('APPOINTMENT.FORM.DURA_MINUTE')}
                          value={durationMinute || ''}
                          onchange={(event) => setDurationMinute(event.target.value)}
                          selectId="durationMinute"
                          labelId="durationMinute"
                          options={durationMinutes.map((duration: any, i: number) => (
                            <MenuItem key={i} value={duration.durationMinute}>
                              {`${duration.duration} ${t(`TIME_DURATION_TYPE.${duration.durationType}`)}`}
                            </MenuItem>
                          ))}
                          helperText={errorMessage.DURATION_MINUTE_ANY_REQUIRED}
                          disabled={disabled || officeTimeByDay.officeStatus === "CLOSE"}
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <div className={`mb-3 ${labelStatus === 'cancel' ? '' : 'd-none'}`} style={{ display: isEdit ? '' : 'none' }}>
                  <InputTextarea disabled={disabled} required onchange={(e) => setRemarkCancel(e.target.value)} value={remarkCancel} label={t('APPOINTMENT.FORM.REMARK_CANCEL')} helperText={errorMessage.REMARK_CANCEL_ANY_REQUIRED || errorMessage.REMARK_CANCEL_STRING_EMPTY} />
                </div>
                <Row className={` ${checkPackage(PACKAGE_ADDONS.ADDON_X_RAY) || checkPackage(PACKAGE_ADDONS.ADDON_LAB) ? 'mb-2' : ''}`}>
                  <Col sm={12} lg={12} className={`mb-3 ${renderForm('treatment')}`}>
                    <Box sx={{
                      '.MuiInputBase-input': {
                        height: `10px !important`,
                      },
                      '.MuiAutocomplete-option': {
                        '&:hover, &:hover.Mui-focused': {
                          backgroundColor: `${colors.themeSecondColor06} !important`,
                          color: colors.themeSecondColor
                        },
                        '&[aria-selected="true"], &.Mui-focused': {
                          backgroundColor: `${colors.transparent} !important`,
                        }
                      }
                    }}>
                      <AutocompleteSelect
                        open={isSelectOperative}
                        onOpen={(event, value) => setIsSelectOperative(true)}
                        onClose={(event, value) => setIsSelectOperative(false)}
                        multiple
                        required={true}
                        labelId="lb-operative-id"
                        inputLabel={t('REGISTER.SEND_EXAMINATION.TREATMENT_LIST')}
                        noOptionsText={t('REGISTER.FORM.NOT_FOUND')}
                        options={[...operativeTypes, ...newTreatmentItems]}
                        getOptionLabel={(option: any) => (option.operativeId ? option.operativeName : option.operativeTypeName)}
                        // getOptionLabel={(option: any) => (option?.subOperative[0]?.operativeName || option.operativeTypeName)}
                        renderOption={(props, option: any) => (
                          <Box
                            key={`${option.operativeId ? `operativeId-${option.operativeId}` : `operativeTypeId-${option.operativeTypeId}`}`}
                            component="li"
                            style={{
                              pointerEvents: _.some(operativeType, (i: any, index: number) => i.operativeTypeId === option.operativeTypeId && i.operativeId === option.operativeId) ? 'none' : '',
                              backgroundColor: _.some(operativeType, (i: any, index: number) => i.operativeTypeId === option.operativeTypeId && i.operativeId === option.operativeId) ? colors.themeSecondColor06 : colors.white,
                              color: _.some(operativeType, (i: any, index: number) => i.operativeTypeId === option.operativeTypeId && i.operativeId === option.operativeId) ? colors.themeSecondColor : null
                            }}
                            {...props}
                          >
                            <span style={{ paddingLeft: option.operativeId ? '1rem' : '' }}>{option.operativeId ? option.operativeName : option.operativeTypeName}</span>
                            {/* <CollapseOperatice
                              data={operativeType}
                              option={option}
                              seletor={props}
                              handleSubClick={handleSubClickOperative}
                            /> */}
                          </Box>
                        )}
                        renderTags={(tagValue, getTagProps) =>
                          tagValue.map((option: any, index: any) => {
                            const labelChip = option.operativeName ? option.operativeName : option.operativeTypeName
                            return (
                              <Tooltip title={`${labelChip}`} placement="left" key={`index-${index}`}>
                                <div style={{ overflow: "hidden" }} >
                                  <Chip label={labelChip} style={{ fontSize: '1rem', backgroundColor: option.color }} {...getTagProps({ index })} />
                                </div>
                              </Tooltip>
                            )
                          })
                        }
                        onchange={(event, val) => {
                          setOperativeType(_.map(val, (v) => v))
                        }}
                        onInputChange={(event: any, newValue: any) => loadDataTreatmentItems(newValue)}
                        value={operativeType ? operativeType : []}
                        key={'treatment'}
                        height={43}
                        limitTags={3}
                        errMessage={errorMessage.APPOINTMENT_OPERATIVES_ANY_REQUIRED}
                        params={{
                          PaperComponent: undefined,
                          ListboxProps: {
                            ref: treatmentItemsRef,
                            onScroll: (event: any) => {
                              const elementScrollCurrent = (event?.target?.scrollTop + event?.target?.offsetHeight) || 0
                              const elementScrollMax = event?.target?.scrollHeight || 0
                              if ((elementScrollCurrent && elementScrollMax) &&
                                ((elementScrollCurrent + 0.5) >= elementScrollMax) &&
                                handleLengthOperative()) {
                                setContentMaxTI(elementScrollMax)
                                loadDataTreatmentItems(treatmentItemsSearch, true)
                              }
                            }
                          },
                        }}
                      />
                    </Box>
                  </Col>
                  <Col sm={6} lg={6} className={`${renderForm('price')}`}>
                    <InputTextField
                      label={t('APPOINTMENT.FORM.PRICE')}
                      type="text"
                      value={price}
                      inputProps={{ shrink: true, inputProps: { min: 0, max: 10 } }}
                      onchange={(e) => {
                        clearErrorMessage()
                        const val = e.target.value.replace(/[^0-9]/g, '')
                        if (val < 0) setPrice(0)
                        else if (val > 900000) setPrice(900000)
                        else setPrice(val)
                      }}
                      disabled={disabled}
                      required={renderReqDisForm('price')}
                      helperText={errorMessage?.PRICE_REQUIRED && !price ? errorMessage?.PRICE_REQUIRED : ''}
                    />
                  </Col>
                </Row>
                <hr style={{ margin: "1rem 0 1rem 0 " }} />
                <Row className="mx-0 pl-3">
                  {checkPackage(PACKAGE_ADDONS.ADDON_X_RAY) && (
                    <Col xs={12} xl={'auto'} className={`px-0 ${renderForm('lab')}`}>
                      <Row className="" style={{ alignItems: "center" }}>
                        <Col xs={12} sm={5} xl={'auto'} className="" style={{ fontSize: "14px", padding: "0 0 0 12px" }}>
                          <span className={`formLabel fw-700`}>
                            {t('APPOINTMENT.FORM.LIST_ORDER_LAB')}
                            {renderReqDisForm('lab') && <span style={{ color: colors.statusInactive }}>*</span>}
                          </span>
                        </Col>
                        <Col xs={12} sm={7} xl={'auto'} className="mb-0  pl-1" >
                          <FormControl>
                            <RadioGroup row aria-labelledby="demo-row-radio-buttons-group-label" name="row-radio-buttons-group">
                              <FormControlLabel style={{ fontSize: "14px" }} disabled={disabled} value="lab" control={<InputRadio checked={lab === true} className="radio" onClick={(e: any) => setLab(true)} />} label={t('APPOINTMENT.FORM.HAS_LAB')} />
                              <FormControlLabel style={{ fontSize: "14px" }} disabled={disabled} value="nonlab" control={<InputRadio checked={lab === false} onClick={(e: any) => setLab(false)} />} label={t('APPOINTMENT.FORM.NOT_HAS_LAB')} />
                            </RadioGroup>
                          </FormControl>
                        </Col>
                      </Row>
                    </Col>
                  )}
                  {checkPackage(PACKAGE_ADDONS.ADDON_LAB) && (
                    <Col xs={12} xl={'auto'} className={`px-0 ${renderForm('xray')}`}>
                      <Row className="" style={{ alignItems: "center" }}>
                        <Col xs={12} sm={5} xl={'auto'} className="" style={{ fontSize: "14px", padding: "0 0 0 12px" }}>
                          <span className={`formLabel fw-700`}>
                            {t('APPOINTMENT.FORM.LIST_ORDER_XRAY')}
                            {renderReqDisForm('xray') && <span style={{ color: colors.statusInactive }}>*</span>}
                          </span>
                        </Col>
                        <Col xs={12} sm={7} xl={'auto'} className="mb-0  pl-1" >
                          <FormControl>
                            <RadioGroup row aria-labelledby="demo-row-radio-buttons-group-label" name="row-radio-buttons-group">
                              <FormControlLabel style={{ fontSize: "14px" }} disabled={disabled} value="xray" control={<InputRadio checked={xray === true} onClick={(e: any) => setXray(true)} />} label={t('APPOINTMENT.FORM.HAS_XRAY')} />
                              <FormControlLabel style={{ fontSize: "14px" }} disabled={disabled} value="nonxray" control={<InputRadio checked={xray === false} onClick={(e: any) => setXray(false)} />} label={t('APPOINTMENT.FORM.NOT_HAS_XRAY')} />
                            </RadioGroup>
                          </FormControl>
                        </Col>
                      </Row>
                    </Col>
                  )}
                </Row>
                {(renderForm('xray') && renderForm('lab')) !== 'd-none' && <hr style={{ margin: "1rem 0 1rem 0 " }} />}

                <div className={`d-flex mb-3 ${checkPackage(PACKAGE_ADDONS.ADDON_X_RAY) || checkPackage(PACKAGE_ADDONS.ADDON_LAB) ? 'mt-2' : ''} ${renderForm('prictice')}`}>
                  <InputTextarea
                    disabled={disabled}
                    value={valInputTextArea(practice.toString())}
                    onchange={(event) => {
                      clearErrorMessage()
                      setPractice([event.target.value])
                    }}
                    label={t('APPOINTMENT.FORM.PRACTICE')}
                    required={renderReqDisForm('prictice')}
                    helperText={errorMessage?.PRACTICE_REQUIRED}
                  />
                  <Box className='input-form-note-wrap position-absolute'></Box>
                  <ButtonCustom disabled={disabled} onClick={() => templateText('PRATICE')} textButton={<img src={icons.iconFeatherEdit} alt="" style={{ width: 24 }} />} className="h-100" style={{ margin: 0, height: '89px' }} />
                </div>
                <div className={`d-flex mt-2  ${renderForm('note')}`} style={{ marginBottom: props.appointmentId && !disabled && (props?.patientIdApp?.patientId || isEditApp) ? "0px" : "76px" }}>
                  <InputTextarea
                    disabled={disabled}
                    value={valInputTextArea(remark.toString())}
                    onchange={(event) => {
                      clearErrorMessage()
                      setRemark([event.target.value])
                    }}
                    label={t('APPOINTMENT.FORM.REMARK')}
                    required={renderReqDisForm('note')}
                    helperText={errorMessage?.REMARK_REQUIRED}
                  />
                  <Box className='input-form-note-wrap position-absolute'></Box>
                  <ButtonCustom disabled={disabled} onClick={() => templateText('REMARK')} textButton={<img src={icons.iconFeatherEdit} alt="" style={{ width: 24 }} />} className="h-100" style={{ margin: 0, height: '89px' }} />
                </div>
                {props.appointmentId && !disabled && (props?.patientIdApp?.patientId || isEditApp) &&
                  <div className={`d-flex mt-2 `} style={{ marginBottom: "25px" }}>
                    <InputTextarea disabled={disabled} value={valInputTextArea(appRemark.toString())} onchange={(event) => setAppRemark([event.target.value])} label={t('APPOINTMENT.FORM.APP_REMARK')} helperText={''} />
                    <Box className='input-form-note-wrap position-absolute'></Box>
                    <ButtonCustom disabled={disabled} onClick={() => templateText('APP')} textButton={<img src={icons.iconFeatherEdit} alt="" style={{ width: 24 }} />} className="h-100" style={{ margin: 0, height: '89px' }} />
                  </div>
                }
              </div>


            </div>
            <ModalSlice_Footer style={{ width: "100%" }}>
              <div className={`mb-2 d-flex justify-content-end ${width > 575 && 'w-100'} px-3`}>
                <BtnCancel
                  fullWidth
                  onClick={() => { resetForm() }}
                >
                  {t('APPOINTMENT.BUTTON.CANCEL')}
                </BtnCancel>
                {props.appointmentId && !disabled &&
                  <BtnDelete
                    disabled={permissions.isDelete.disabled}
                    fullWidth
                    onClick={() => {
                      props.onDelete && props.onDelete(props.appointmentId || 0, objAppontment)
                      resetForm()
                    }}>
                    {!disabled ? t('APPOINTMENT.BUTTON.DEL') : ''}
                  </BtnDelete>
                }
                <BtnConfirm
                  fullWidth
                  disabled={isSubmit || (loading || false)}
                  onClick={() => { handleSubmit() }}>
                  {props.appointmentId && !disabled ? props?.patientIdApp?.patientId && t('APPOINTMENT.FORM.ADD') || t('APPOINTMENT.FORM.SAVE') : disabled ? t('APPOINTMENT.FORM.SAVE_SPECIALINFO') : t('APPOINTMENT.FORM.ADD')}
                </BtnConfirm>
              </div>
            </ModalSlice_Footer>

          </ModalSlice>
        </>}
    </>
  ) || (
      <></>
    )
}

type CollapseOperaticeProps = {
  option: any
  seletor: any
  data?: any
  handleClick?: (data: any, index?: number) => void
  handleSubClick?: (data: any, index?: number) => void
}

const CollapseOperatice = (props: CollapseOperaticeProps) => {
  const { option, seletor } = props

  const [isOpen, setIsOpen] = useState<boolean>(false)

  return (
    <>
      <Box
        {...seletor}
        key={option.operativeTypeId}
        sx={{
          justifyContent: 'space-between !important',
        }}
        onClick={(event: any) => {
          if (_.some(props?.data, (i: any, index: number) => i.operativeTypeId === option.operativeTypeId && i.operativeId === option.operativeId)) {
            return setIsOpen(!isOpen)
          }
          return seletor.onClick(event)
        }}
      >
        <Box
          sx={{
            pointerEvents: _.some(props?.data, (i: any, index: number) => i.operativeTypeId === option.operativeTypeId && i.operativeId === option.operativeId) ? 'none' : '',
            color: _.some(props?.data, (i: any, index: number) => i.operativeTypeId === option.operativeTypeId && i.operativeId === option.operativeId) ? colors.themeSecondColor : null,
          }}
        >
          {option?.operativeTypeName}
        </Box>
        <Box
          onClick={(event) => {
            event.stopPropagation()
            setIsOpen(!isOpen)
          }}
          sx={{
            transform: `rotate(${isOpen ? 90 : 270}deg)`,
            display: option?.subOperative && option?.subOperative?.length > 0 ? 'block' : 'none'
          }}
        >
          <UseStyles.CustomIconArrow sx={{ width: '18px', height: '18px' }} >
            <Box className={'icon-arrow'}>
              <IconArrow width='5.6px' height='9.8px' />
            </Box>
          </UseStyles.CustomIconArrow>
        </Box>
      </Box>
      <Collapse in={isOpen}>
        {option?.subOperative.map((item: any, index: number) => {
          return (
            <Box
              key={index}
              {...seletor}
              sx={{
                marginLeft: '1rem',
                pointerEvents: _.some(props?.data, (i: any, index: number) => i.operativeTypeId === item.operativeTypeId && i.operativeId === item.operativeId) ? 'none' : '',
                color: _.some(props?.data, (i: any, index: number) => i.operativeTypeId === item.operativeTypeId && i.operativeId === item.operativeId) ? colors.themeSecondColor : null,
              }}
              onClick={(event: any) => {
                if (props?.handleSubClick) {
                  return props.handleSubClick(item, index)
                }
                return seletor.onClick()
              }}
            >
              {item.operativeName}
            </Box>
          )
        })}
      </Collapse>
    </>

  )
}
